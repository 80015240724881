import { defineStore } from "pinia";    
import axios from '@/helper/axios'

export const useRoleStore = defineStore('role', {
    state: () => {
        return {
            roles: [],
            loading: false,
            error: null
        }
    },
    getters: {
        getRolesForm:(state) => {
            return state.roles.map(val => {
                const newVal = {
                    title: val.name,
                    value: val.id
                }
                return newVal
            })
        }
    },
    actions: {
        async fetchRole(){
            this.roles = []
            this.loading = true
            try{
                this.roles = (await axios.get('roles')).data.data
                this.loading = false
            }catch(error){
                this.loading = false
            }
        }
    }
})
