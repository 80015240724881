<template>
  <transition name="toast">
    <div
      v-show="toast.show"
      id="toast-danger"
      class="toast-container z-50"
      :class="[toast.status ? 'bg-green-500' : 'bg-red-500']"
      role="alert"
    >
      <div>
        <!-- success -->
        <CheckIcon v-if="toast.status" class="w-5 h-5" />
        <!-- failed -->
        <ExclamationIcon v-else class="w-5 h-5" />
      </div>
      <h3 class="ml-3 text-sm font-normal">{{ toast.message }}</h3>
      <button
        @click="toast.show = false"
        type="button"
        class="toast-button-close"
        data-dismiss-target="#toast-danger"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <XIcon class="w-5 h-5" />
      </button>
    </div>
  </transition>
</template>
<script setup>
import { CheckIcon, ExclamationIcon, XIcon } from "@heroicons/vue/solid";
import { useToastStore } from "@/store/toast";

const toast = useToastStore();
</script>
<style scoped>
.toast-container {
  @apply flex items-center w-96 p-4 m-auto text-white  rounded-lg shadow absolute top-14 inset-x-0;
}

.toast-button-close {
  @apply ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:text-gray-600 inline-flex h-8 w-8;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease;
}

.toast-enter-from,
.toast-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
</style>
