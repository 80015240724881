export function storeLocalStorage(key, object) {
  try {
    const stringifiedObject = JSON.stringify(object); // Convert object to string
    localStorage.setItem(key, stringifiedObject);
    console.log(`Object stored in localStorage with key: ${key}`);
  } catch (error) {
    console.error("Error storing object:", error); // Handle storage errors
  }
}

export function getLocalStorage(key) {
  try {
    const stringifiedObject = localStorage.getItem(key);
    if (stringifiedObject) {
      return JSON.parse(stringifiedObject); // Parse string back to object
    } else {
      return null; // Return null if object doesn't exist
    }
  } catch (error) {
    return null;
  }
}
