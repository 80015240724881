export default Object.freeze({
  T01: `images`,
  T02: `work_in_progress`,
  T03: `material_inspections`,
  T04: `vendor_assessments`,
  T05: `work_result_inspections`,
  T06: `preventive_billing`,
  T07: `maintenance_inspection`,
  T08: `bapp_request`,
});
