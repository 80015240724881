<template>
  <AppCard :shadow="false">
    <template #title> Pengajuan Pekerjaan </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Proyek</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loadingProjects"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :headerList="headerList"
      :bodyList="dataList"
      :action="true"
      :overflowX="true"
      :overflowY="true"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEditModal(data)"
          @erase-button="toggleEraseModal(data.id)"
        >
          <!-- <button
            class="bg-red-500 rounded-xl focus:ring-red-200 hover:bg-red-400 focus:ring-4 focus:outline-none"
          >
            <AppIcon name="ChatAlt2" classTag="stroke-white p-1" />
          </button> -->
          <button
            @click="openMenu(data)"
            class="bg-blue-500 rounded-xl focus:ring-blue-200 hover:bg-blue-400 focus:ring-4 focus:outline-none"
          >
            <AppIcon name="DotsVertical" classTag="stroke-white p-1" />
          </button>
        </TableActionDataMaster>
      </template>
    </AppTable>
    <AppModal :isOpen="menu" :form="false">
      <template #title>
        <div class="text-center border-b-2">menu</div>
      </template>
      <ProyekMenu :status="status" @to-another-page="toAnotherPage" />
      <AppButton class="w-full flex justify-center mt-4" @click="openMenu">
        Close
      </AppButton>
    </AppModal>
    <TableModal
      title="Pengajuan Pekerjaan"
      :add="addModal"
      @add-submit="addProject"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateProject"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteProject"
      @erase-close="toggleEraseModal"
    >
      <div class="grid grid-cols-2 gap-3">
        <AppInput
          title="Nama Proyek"
          v-model="formInput.nama"
          :isRequired="true"
        />
        <AppSelect
          title="Kontraktor"
          v-model="formInput.id_kontraktor"
          :isRequired="true"
          :options="kontraktorOptions"
        />
        <AppSelect
          title="Kategori"
          v-model="formInput.id_kategori"
          :isRequired="true"
          :options="kategoriOptions"
        />
        <AppSelect
          title="Cost Code"
          v-model="formInput.id_detail_cost_code"
          :isRequired="true"
          :options="costcodeOptions"
        />
        <AppInput
          title="Nomor Kontrak"
          v-model="formInput.nomor_kontrak"
          :isReequired="true"
        />
        <AppInput
          title="Mulai"
          v-model="formInput.mulai"
          :isRequired="true"
          type="date"
        />
        <AppInput
          title="Selesai"
          v-model="formInput.selesai"
          :isRequired="true"
          type="date"
        />
        <AppCurrencyInput
          title="Nilai Kontrak"
          v-model="formInput.nilai_kontrak"
          :isRequired="true"
          :options="{
            currency: 'IDR',
            currencyDisplay: 'hidden',
          }"
        />
      </div>
    </TableModal>
  </AppCard>
</template>
<script setup>
import { onMounted, ref } from "vue";
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppTable from "@/components/AppTable.vue";
import AppButton from "@/components/AppButton.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import AppIcon from "@/components/AppIcon.vue";
import AppModal from "@/components/AppModal.vue";
import TableModal from "@/components/TableModal.vue";
import useModal from "@/composable/useModal";
import { useProjectStore } from "@/store/project";
import { useKontraktorStore } from "@/store/kontraktor";
import { useCostCode } from "@/store/costCode";
import { useKategoriStore } from "@/store/kategori";
import AppInput from "@/components/AppInput.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppCurrencyInput from "@/components/AppCurrencyInput.vue";
import ProyekMenu from "../components/ProyekMenu.vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";

const router = useRouter();
const store = useProjectStore();
const kontraktor = useKontraktorStore();
const costcode = useCostCode();
const kategori = useKategoriStore();
const auth = useAuthStore();

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const dataProyek = ref();
const menu = ref(false);
const status = ref();
function openMenu(data) {
  menu.value = !menu.value;
  if (data) {
    dataProyek.value = data;
    status.value = data.status;
  }
}

const role = ref();

function toAnotherPage(routeName) {
  if (routeName == "schedule") {
    // routeName = role.value === "Kontraktor" ? "schedule_upload" : "schedule";
    routeName = "schedule_upload";
  }
  router.push({
    name: routeName,
    params: {
      id: dataProyek.value.id,
      kontraktor: dataProyek.value.kontraktor_name,
      proyek: dataProyek.value.nama,
      nilai_kontrak: dataProyek.value.nilai_kontrak,
    },
  });
}

const headerList = [
  {
    classTag: "text-left px-3.5 sticky left-0 ",
    title: "Kontraktor",
    id: "kontraktor_name",
  },
  {
    classTag:
      "text-left px-3 border-r-2 whitespace-normal min-w-[20rem] sticky left-[8.25rem]",
    title: "Nama Proyek",
    id: "nama",
  },
  {
    classTag: "text-center ",
    title: "Kategori",
    id: "kategori_name",
  },
  {
    classTag: "text-center",
    title: "Nomor Kontrak",
    id: "nomor_kontrak",
  },
  {
    classTag: "text-center ",
    title: "Mulai",
    id: "mulai",
  },
  {
    classTag: "text-center ",
    title: "Selesai",
    id: "selesai",
  },
  {
    classTag: "text-center ",
    title: "Nilai Kontrak",
    id: "nilai_kontrak",
    depan: "Rp. ",
    currency: true,
  },

];

const dataList = ref();

onMounted(() => {
  store.fetchProjects();
  kontraktor.fetchKontraktors();
  costcode.fetchCostCodes();
  kategori.fetchKategori();
  role.value = auth.user.role.name;
});

store.$subscribe((mutation, state) => {
  if (!state.loadingProjects) {
    dataList.value = store.getProjects;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const kontraktorOptions = ref({});

kontraktor.$subscribe((mutation, state) => {
  if (!state.loading) {
    kontraktorOptions.value = kontraktor.getKontraktorsForm;
  }
});

const costcodeOptions = ref({});

costcode.$subscribe((mutation, state) => {
  if (!state.loading) {
    costcodeOptions.value = costcode.getCostcodeForm;
  }
});

const kategoriOptions = ref({});

kategori.$subscribe((mutation, state) => {
  if (!state.loading) {
    kategoriOptions.value = kategori.getKategoriForm;
  }
});

const addProject = () => {
  formInput.value.id_cost_code = formInput.value.id_detail_cost_code;
  store.addProject(formInput.value);
};
const updateProject = () => store.updateProject(formInput.value);
const deleteProject = (id) => store.deleteProject(id);
</script>
