<template>
  <div class="bg-[#F4F7FC] w-full px-9 pt-4 pb-2 flex justify-between">
    <div class="inline-flex space-x-4">
      <AppPopover class="m-auto">
        <template #button>
          <svg
            class="w-6 h-6"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z"
              fill="black"
            />
            <path
              d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z"
              fill="black"
            />
            <path
              d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z"
              fill="black"
            />
          </svg>
        </template>
        <div class="overflow-x-auto max-h-72">
          <ul>
            <li
              v-for="(item, index) in menuList"
              :key="index"
              class="hover:bg-gray-300 py-2 px-5 text-center cursor-pointer"
              @click="$emit('setActive', item.id)"
            >
              <a
                class="font-poppins transition-allo"
                :class="{ 'text-blue-500': active == item.id }"
                v-html="item.title"
              >
              </a>
            </li>
          </ul>
        </div>
      </AppPopover>
      <div
        v-if="direction === 'asc'"
        class="group cursor-pointer relative"
        @click="setDirection('desc')"
      >
        <AppIcon name="SortAscending" classTag="h-9 w-9 mt-2" />
        <div
          class="opacity-0 w-14 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -translate-x-4 mb-2 px-3 pointer-events-none"
        >
          asc
        </div>
      </div>
      <div
        v-else
        @click="setDirection('asc')"
        class="group cursor-pointer relative"
      >
        <AppIcon name="SortDescending" classTag="h-9 w-9 mt-2" />
        <div
          class="opacity-0 w-14 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -translate-x-4 mb-2 px-3 pointer-events-none"
        >
          desc
        </div>
      </div>
      <AppIcon />
      <div class="relative" v-if="withSearch">
        <div
          class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
        >
          <AppIcon
            name="Search"
            classTag="h-5 w-5 fill-black/25"
            :solid="true"
          ></AppIcon>
        </div>
        <input
          type="text"
          id="search"
          class="pl-10 form-input rounded-md w-64 bg-[#DAE1EC] border-transparent focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:bg-white"
          placeholder="Search"
          @keyup.enter="$emit('search', $event.target.value)"
        />
      </div>
      <slot name="title"></slot>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import AppPopover from "./AppPopover.vue";
import AppIcon from "./AppIcon.vue";
import { ref, defineEmits } from "vue";
const emit = defineEmits(["direction", "setActive"]);
defineProps({
  active: {
    type: String,
  },
  withSearch: {
    type: Boolean,
    default() {
      return true;
    },
  },
  menuList: {
    type: Array,
    default() {
      return [
        {
          title: "teti",
          id: "tati",
        },
        {
          title: "tets",
          id: "tato",
        },
        {
          title: "tea",
          id: "tate",
        },
      ];
    },
  },
});
const direction = ref("asc");

function setDirection(dir) {
  direction.value = dir;
  emit("direction", direction.value);
}

</script>
