<template>
  <AppModal :is-open="dashboardStore.listModal" @close-modal="close">
    <template #title>
      <AppModalCloseButton :title="'List Proyek'" @close="close" />
    </template>
    <div>
      <div class="flex flex-row gap-1">
        <AppSelect
          class="w-40"
          v-model="kategori"
          :options="kategoriStore.getKategoriList"
        />
        <AppInputSearch class="flex-1" @search="setSearch" />
      </div>
      <div
        class="w-full relative h-[38rem] overflow-auto flex flex-col gap-2 py-3 pr-2"
      >
        <AppCardListProject
          :proyek-list="dashboardStore.getListProject"
          @selected-project="selectedProject"
        />
      </div>
    </div>
  </AppModal>
</template>
<script setup>
import AppInputSearch from "./AppInputSearch.vue";
import AppModal from "./AppModal.vue";
import AppModalCloseButton from "./AppModalCloseButton.vue";
import AppSelect from "./AppSelect.vue";
import AppCardListProject from "./AppCardListProject.vue";
import { useDashboardStore } from "@/store/dashboard";
import { ref } from "vue";
import { useKategoriStore } from "@/store/kategori";

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const dashboardStore = useDashboardStore();
const kategoriStore = useKategoriStore();

const kategori = ref(dashboardStore.kategori_id);

function setSearch(value) {
  dashboardStore.fetchListProject(kategori.value, value);
}

function selectedProject(id) {
  dashboardStore.fetchProjectById(id);
}

function close(value) {
  dashboardStore.listModal = value;
}
</script>
