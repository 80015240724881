import { ref } from "vue";


export default (datasc) => {

    const headData = ref([])
    const planData = ref([])
    const actualData = ref([])

    datasc.headers.forEach((val) => {
        headData.value.push({
            'title': val.name,
            'id': val.id
        })
    });
    
    datasc.scope.forEach((val) => {
        let { data, ...newVal } = val
    
        let plan = data.map((v) => {
            let b = {}
            b[v.project_header_id] = v.plan_value
            return b
        })
        let actual = data.map((v) => {
            let b = {}
            b[v.project_header_id] = v.actual_value
            return b
        })
        
        let planD = JSON.parse(JSON.stringify(newVal))
        plan.forEach(v=>{
            Object.assign(planD, v)
        })
        planData.value.push(planD)

        let actualD = JSON.parse(JSON.stringify(newVal))
        actual.forEach(v=>{
            Object.assign(actualD, v)
        })
        actualData.value.push(actualD)

    })

    return{
        headData,
        planData,
        actualData
    }
};
