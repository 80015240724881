<template>
  <div
    class="bg-[#2148C0] bg-no-repeat bg-cover h-screen w-screen flex justify-center items-center"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg.png') + ')',
    }"
  >
    <div class="-mt-24">
      <!-- logo manset -->
      <!-- <img
        src="@/assets/images/logo-manset.png"
        class="w-60 m-auto"
        alt="logo-pkt"
      /> -->
      <!-- logo aplikasi -->
      <div class="mt-8">
        <img
          src="@/assets/images/logo-aplikasi.png"
          class="w-80 m-auto"
          alt="logo-pkt"
        />
      </div>
      <div class="mt-8">
        <form class="space-y-4" @submit.prevent="login">
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <AppIcon name="User" classTag="text-white h-6 w-6" />
            </div>
            <input
              v-model="username"
              type="text"
              id="username"
              class="auth-input"
              placeholder="USERNAME"
            />
          </div>
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <AppIcon name="Key" classTag="h-6 w-6 text-white" />
            </div>
            <input
              v-model="password"
              type="password"
              id="password"
              class="auth-input"
              placeholder="PASSWORD"
            />
          </div>
          <!-- login button -->
          <button type="submit" class="auth-button flex gap-5 justify-center">
            <AppSpinKit :chase="true" v-if="auth.loading" />
            <h4 v-if="auth.loading">PROSESS....</h4>
            <h4 v-if="!auth.loading">LOGIN</h4>
          </button>
        </form>
        <button
          @click="register()"
          class="auth-button-register flex gap-5 mt-5 justify-center"
        >
          <h4>REGISTER</h4>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import AppIcon from "@/components/AppIcon.vue";
import { ref } from "vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import { useAuthStore } from "@/store/auth";

const auth = useAuthStore();

const username = ref();
const password = ref();

const login = () => {
  auth.login(username.value, password.value);
};

function register() {
  window.open(
    "https://bit.ly/RegistrasiAdministrasiProject",
    "_blank",
    "noreferrer"
  );
}
</script>
<style scoped>
.auth-input {
  @apply border font-montserrat border-gray-300 pl-16 bg-transparent  text-white placeholder-gray-200 rounded-md focus:ring-white focus:border-white block w-full p-2.5;
}

.auth-button {
  @apply text-[#2148C0] bg-white hover:bg-slate-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-md shadow-md text-sm w-full px-5 py-2.5 mr-2 mb-2;
}
.auth-button-register {
  @apply text-white border-2 hover:bg-slate-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-md shadow-md text-sm w-full px-5 py-2.5 mr-2 mb-2;
}
</style>
