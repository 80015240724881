<template>
  <canvas id="chart-kurva" />
</template>
<script setup>
import { Chart, registerables } from "chart.js";
import { onMounted } from "vue";
Chart.register(...registerables);

const props = defineProps({
  dataSchedule: {
    type: Array,
    default() {
      return [];
    },
  },
  dataRealisasi: {
    type: Array,
    default() {
      return [];
    },
  },
  labels: {
    type: Array,
    default() {
      return [...Array(100).keys()];
    },
  },
});

onMounted(() => {
  const ctx = document.getElementById("chart-kurva");
  new Chart(ctx, {
    type: "line",
    options: {
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      maintainAspectRatio: false,
      stacked: false,
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          max: 100,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          max: 100,
          beginAtZero: false,
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    },
    data: {
      labels: props.labels,
      datasets: [
        {
          label: "Realisasi",
          data: props.dataRealisasi,
          backgroundColor: "#0DE262",
          borderColor: "#0DE262",
          yAxisID: "y1",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
        },
        {
          label: "Shedule",
          data: props.dataSchedule,
          backgroundColor: "#ea981d",
          borderColor: "#ea981d",
          yAxisID: "y",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
        },
      ],
    },
  });
});
</script>
