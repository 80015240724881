import { defineStore } from "pinia";
import axios from '@/helper/axios'
import { useToastStore } from "./toast";

export const useScopeStore = defineStore('scope', {
    state: () => {
        return {
            scopes: [],
            loading: false,
            loadingData: false, 
            loadingWeekly: false, 
            error: null,
            idScope: null
        }
    },
    getters: {
        getScopes:(state) => {
            return state.scopes
        }
    },
    actions: {
        async fetchScopes(){
            this.scopes = []
            this.loading = true
            try {
                this.scopes = (await axios.get('proyek/' + this.idScope+'/scope')).data.data
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        async addScope(data){
            const toast = useToastStore()
            this.loadingData = true
            try{
                const response = (await axios.post('proyek/'+ this.idScope+'/scope', data)).data
                this.loadingData = false 
                if (response.success) {
                    toast.showToastShort(true, response.message)
                    this.fetchScopes()
                } else {
                    toast.showToastShort(false, response.message)
                }
            }catch(error){
                this.loadingData = false
            }
        },
        async updateScope(data){
            const toast = useToastStore()
            this.loadingData = true
            try{
                const response = (await axios.put('proyek/'+ this.idScope+'/scope/'+ data.id, data)).data
                this.loadingData = false
                if (response.success) {
                    toast.showToastShort(true, response.message)
                    this.fetchScopes()
                } else {
                    toast.showToastShort(false, response.message)
                }
            }catch(error){
                this.loadingData = false
            } 
        },
        async deleteScope(id){
            const toast = useToastStore()
            this.loadingData = true
            try{
                const response = (await axios.delete('proyek/'+ this.idScope+'/scope/'+ id )).data
                this.loadingData = false
                if (response.success) {
                    toast.showToastShort(true, response.message)
                    this.fetchScopes()
                } else {
                    toast.showToastShort(false, response.message)
                }
            }catch(error){
                this.loadingData = false
            } 
        },
        async updateWeekly(id){
            this.loadingWeekly = true
            const toast = useToastStore()
            try {
                const response = (await axios.post('proyek/'+ id +'/scope/value')).data.data
                if (response.status) {
                    toast.showToastShort(true, 'Berhasil mengisi data mingguan')
                }else{
                    toast.showToastShort(false, 'Gagal mengisi data mingguan')
                }
                this.loadingWeekly = false
            } catch (error) {
                this.loadingWeekly = false
            }
        }
    }
})
 