<template>
  <AppCard :shadow="false">
    <template #title>Data Master > Mingguan</template>
    <AppTableHeader
        @set-active="setFilterActive"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
        :withFalse="false"
    >
      <template #title>
        <div
            v-if="store.loadingProjectScope"
            class="flex justify-center items-center"
        >
          <AppSpinKit :chase="true"/>
        </div>
        <ProyekInfoCard v-else :kontraktor="kontraktor" :proyek="proyek"/>
      </template>
    </AppTableHeader>
    <AppTable
        :key="dataList"
        :loading="store.loadingProjectScope"
        :currentSort="filterActive"
        :currentSortDir="filterDirection"
        :headerList="headerList"
        :bodyList="dataList"
        :overflowX="true"
        :overflowY="true"
        :action="true"
    >
      <template #action="{ data }">
        <AppButton @click="toggleAdd(data)" class="px-1 py-1 my-3 rounded-xl">
          <AppIcon name="DotsHorizontal"/>
        </AppButton>
      </template>
    </AppTable>
    <AppModal :isOpen="addModal.open">
      <template #title>
        <AppModalCloseButton
            title="Tambah Pekerjaan Mingguan"
            @close="toggleAdd()"
        />
      </template>

      <form @submit.prevent="updateWeekly">
        <AppInput v-model="formInput.scope_id" type="hidden"/>
        <AppSelect
            title="Minggu Ke -"
            v-model="formInput.project_header_id"
            :options="headOptions"
        />
        <AppInput title="Volume" v-model="formInput.volume"/>
        <AppCurrencyInput
            :isReadOnly="true"
            :externalValue="true"
            :options="{
            currency: 'IDR',
            currencyDisplay: 'hidden',
          }"
            title="Harga Pekerjaan"
            v-model="harga_pekerjaan"
        />
        <AppInput title="Bobot" v-model="formInput.value" :isReadonly="true"/>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Ubah</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import {computed, onMounted, ref} from "vue";
import AppTable from "@/components/AppTable.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppModal from "@/components/AppModal.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import dataConvert from "@/helper/dataConvert";
import ProyekInfoCard from "@/components/ProyekInfoCard.vue";
import {useProjectStore} from "@/store/project";
import {useRoute} from "vue-router";
import AppSpinKit from "@/components/AppSpinKit.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppInput from "@/components/AppInput.vue";
import AppCurrencyInput from "@/components/AppCurrencyInput.vue";

const {filterActive, filterDirection, setFilterActive, setFilterDirection} =
    useFilter();
const {formInput, addModal, toggleAddModal} = useModal();
const store = useProjectStore();
const route = useRoute();

const headerList = ref([]);

const nilai_kontrak = ref(0);
const nilai_satuan = ref(0);
const weekly_data = ref({});

// turn to bobot
formInput.value.value = computed(() => {
  if (formInput.value.volume) {
    return (
        (100 * formInput.value.harga_pekerjaan) /
        nilai_kontrak.value
    ).toFixed(2);
  } else {
    return 0;
  }
});

formInput.value.harga_pekerjaan = computed(() => {
  if (formInput.value.volume) {
    return formInput.value.volume * nilai_satuan.value;
  } else {
    return 0;
  }
});
const harga_pekerjaan = computed(() => formInput.value.harga_pekerjaan);

const dataList = ref();

const id = ref(route.params.id);
const kontraktor = ref("");
const proyek = ref("");

onMounted(() => {
  store.fetchProjectWithScope(id.value);
});

const headOptions = ref();

store.$subscribe((mutation, state) => {
  if (!state.loadingProjectScope) {
    nilai_kontrak.value = state.projectScope.nilai_kontrak;
    headerList.value = [
      {
        classTag:
            "min-w-[20rem] sticky left-0 border-r-2 whitespace-normal text-left pl-14 px-1",
        title: "Uraian",
        id: "uraian",
      },
      {
        classTag: "text-center",
        title: "Satuan",
        id: "satuan_name",
      },
      {
        classTag: "text-center whitespace-normal w-20",
        title: "Perkiraan Kuantitas",
        id: "perkiraan_kuantitas",
      },
      {
        classTag: "text-center",
        title: "Harga Satuan (RP)",
        id: "harga_satuan",
        depan: "Rp.",
        currency: true,
      },
      {
        classTag: "text-center",
        title: "Harga Pekerjaan( RP)",
        id: "harga_pekerjaan",
        depan: "Rp.",
        currency: true,
      },
      {
        classTag: "text-center ",
        title: "Bobot Pekerjaan % ",
        id: "bobot_pekerjaan",
        belakang: "%",
      },
    ];

    const {headData, actualData} = dataConvert(state.projectScope);
    headData.value.forEach((v) => {
      v["classTag"] = "text-center";
      v["belakang"] = "%";
      headerList.value.push(v);
    });

    dataList.value = actualData.value;
    kontraktor.value = state.projectScope.kontraktor_name;
    proyek.value = state.projectScope.nama;

    headOptions.value = headData.value.map((val) => {
      const newVal = {
        value: val.id,
        title: val.title.replace("<br />", "|"),
      };
      return newVal;
    });
  }
  if (!state.loadingWeekly) {
    addModal.open = false;
  }
});

const toggleAdd = (data) => {
  if (data) {
    formInput.value.scope_id = data.id;
    weekly_data.value = data;
    nilai_satuan.value = data.harga_satuan;
  }
  toggleAddModal();
  if (!addModal.open) {
    formInput.value.volume = 0;
    weekly_data.value = {};
    nilai_satuan.value = 0;
  }
};

const updateWeekly = () => {
  store.updateWeekly(route.params.id, formInput.value);
};
</script>
<style scoped>
.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}
</style>
