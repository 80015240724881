<template>
  <div class="flex flex-col h-full gap-5">
    <AppCard class="h-3/5 overflow-hidden">
      <template #title> Pengajuan Pekerjaan > Pengajuan Schedule</template>
      <div class="bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex justify-between">
        <ProyekInfoCard :kontraktor="store.projectScope.kontraktor_name" :proyek="store.projectScope.nama"/>
        <div class="my-auto">
          <span v-bind:class="getClassBadge(store.projectScope.status).classTag">
            {{ getClassBadge(store.projectScope.status).description }}
          </span>
        </div>
      </div>
      <div v-if="store.loading" class="flex justify-center my-24 w-full">
        <AppSpinKit :grid="true"/>
      </div>
      <AppTable
          :key="store.dataList"
          :loading="store.loading"
          :withFoot="false"
          :headerList="store.headerList"
          :overflowX="true"
          :overflowY="true"
          :bodyList="store.dataList"
          v-else
      />
    </AppCard>
    <AppCard class="flex h-72 overflow-hidden">
      <template #title> File Pendukung</template>
      <div class="flex-grow px-6 py-4 overflow-x-auto flex gap-8">
        <div
            v-for="(item, index) in store.projectScope.files"
            :key="index"
            class="h-full items-center justify-center px-2 flex"
        >
          <div class="flex-grow w-32 relative">
            <div
                v-if="item.image"
                class="relative h-32 bg-slate-100 w-32 object-contain flex justify-center items-center rounded-2xl shadow-lg"
            >
              <div
                  class="absolute pt-1"
                  v-if="!item.upload && store.loading"
              >
                <AppSpinKit :chase="true"/>
              </div>
              <div class="absolute pt-1" v-else-if="item.upload">
                <AppIcon
                    name="Check"
                    class="bg-green-500/90 h-6 w-6 rounded-full"
                />
              </div>
              <img
                  :src="item.image"
                  class="h-32 bg-slate-100 w-32 object-contain rounded-2xl shadow-lg"
              />
              <button
                  @click="eraseFile(item)"
                  class="absolute top-0 right-0 rounded-full w-4 h-4"
              >
                <AppIcon name="XCircle" class-tag="w-6 h-6 text-red-500"/>
              </button>
            </div>
            <div
                v-else
                class="h-32 bg-slate-100 w-32 object-contain flex justify-center items-center rounded-2xl shadow-lg relative"
            >
              <div class="relative">
                <div
                    class="absolute pt-1"
                    v-if="!item.upload && store.loading"
                >
                  <AppSpinKit :chase="true"/>
                </div>
                <div class="absolute pt-1" v-else-if="item.upload">
                  <AppIcon
                      name="Check"
                      class="bg-green-500/90 h-6 w-6 rounded-full"
                  />
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-16 w-16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <a :href="item.url" target="_blank" class="absolute inset-0"></a>
              <button
                  @click="eraseFile(item)"
                  class="absolute top-0 right-0 rounded-full w-6 h-6"
              >
                <AppIcon name="XCircle" class-tag="w-8 h-8 text-red-500"/>
              </button>
            </div>
            <h4 class="text-center truncate mt-1">{{ item.name }}</h4>
          </div>
        </div>
      </div>
    </AppCard>
  </div>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTable from "@/components/AppTable.vue";
import ProyekInfoCard from "@/components/ProyekInfoCard.vue";
import {useProjectStore} from "@/store/project";
import AppIcon from "@/components/AppIcon.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";

const store = useProjectStore();

const eraseFile = async (item) => {
  await store.deleteFile(store.projectScope.id, item);
};

const getClassBadge = (status) => {
  const statusClass = {
    "JF01": {
      classTag: "bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300",
      description: "Job In Progress"
    },
    "JF00": {
      classTag: "bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300",
      description: "Job Failed"
    },
    "JF99": {
      classTag: "bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300",
      description: "Job Success"
    },
  }

  if (statusClass[status] == null) {
    return {
      classTag: "bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300",
      description: "Unknown",
    }
  }

  return statusClass[status];
}
</script>
