<template>
  <AppCard
    title="Rangkuman Proyek"
    v-if="
      store.getProject &&
      Object.keys(store.getProject).length &&
      !material.loading
    "
  >
    <template #title> Rangkuman Proyek </template>
    <div class="flex w-full py-2">
      <div class="flex-1">
        <h4 class="text-center mt-2 text-sm font-poppins font-semibold">
          Material
        </h4>
        <h4 class="text-center mt-2 text-sm font-poppins font-semibold">
          Total Material: {{ totalMaterial }}
        </h4>
        <div class="flex justify-center mt-6">
          <AppIcon name="Folder" classTag="w-24 h-24" :solid="true" />
        </div>
      </div>
      <ProyekRangkumanChart
        title="Progress Pekerjaan"
        detail="proyek"
        class="flex-1 mx-5"
        id="2"
        backTitle="Minggu"
        totalTitle="durasi pekerjaan"
        :total="deliveryTotal"
        :dataList="deliveryList"
      />
      <!-- Proses Penagihan -->
      <div class="mx-5 pb-2 flex flex-col">
        <h4 class="text-center mt-2 text-sm font-poppins font-semibold">
          Progress Penagihan
        </h4>
        <div class="flex-grow items-center flex">
          <InfoWithProgress :dataList="prosesPenagihan"></InfoWithProgress>
        </div>
      </div>
    </div>
  </AppCard>
</template>
<script setup>
import AppCard from "./AppCard.vue";
import ProyekRangkumanChart from "./ProyekRangkumanChart.vue";
import InfoWithProgress from "./InfoWithProgress.vue";
import { useDashboardStore } from "@/store/dashboard";
import { ref } from "vue";
import AppIcon from "./AppIcon.vue";
import { useMaterialStore } from "@/store/material";

const prosesPenagihan = ref([
  {
    title: "Nilai Kontrak",
    nilai: "",
    progress: 0,
    icon: "CurrencyDollar",
    color: "#FBB142",
  },
  {
    title: "Realisasi",
    nilai: "231321",
    progress: 0,
    icon: "CheckCircle",
    color: "#ED5B75",
  },
]);

const material = useMaterialStore();
const store = useDashboardStore();
store.$subscribe((mutations, state) => {
  if (state.project && Object.keys(state.project).length) {
    prosesPenagihan.value[0].nilai =
      store.getProsesPekerjaan.nilaiKontrak.nilai;
    prosesPenagihan.value[0].progress =
      store.getProsesPekerjaan.nilaiKontrak.progress;
    prosesPenagihan.value[1].nilai =
      store.getProsesPekerjaan.nilaiRelsiasi.nilai;
    prosesPenagihan.value[1].progress =
      store.getProsesPekerjaan.nilaiRelsiasi.progress;
  }
});

const totalMaterial = ref(0);

material.$subscribe((mutation, state) => {
  if (!state.loading && Object.keys(state.materials).length) {
    totalMaterial.value = state.materials.length;
  }
});

const deliveryTotal = ref(0);
const deliveryList = ref([
  {
    title: "Ahead",
    percent: 20,
    color: "#FBB142",
  },
  {
    title: "Normal",
    percent: 20,
    color: "#0DE262",
  },
  {
    title: "Delay",
    percent: 20,
    color: "#CFCFCF",
  },
]);

store.$subscribe((mutation, state) => {
  if (state.project && Object.keys(state.project).length) {
    const { schedule, delivery } = store.getProjectDetail;
    deliveryList.value[0].percent = delivery.ahead;
    deliveryList.value[1].percent = delivery.normal;
    deliveryList.value[2].percent = delivery.delay;
    deliveryTotal.value = schedule.length;
  }
});
</script>
