<template>
  <AppCard :shadow="false" class="">
    <template #title>
      Laporan > Detail Laporan > Photo Dokumentasi
    </template>
    <div v-if="store.loading" class="flex w-full justify-center my-5">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex z-10 border-b-2">
      <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
      <AppHoverTitle :title="store.detail.project_name"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
      <h3 class="title-card">
        {{ formatTanggal(store.detail.date) }}
      </h3>
    </div>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
    >
      <div class="flex gap-x-4">
        <button
            class="bg-[#65B741] py-2 px-5 text-white rounded-xl shadow-sm focus:ring-[#cdf3c0] hover:bg-[#C1F2B0] focus:ring-4 focus:outline-none inline-flex uppercase font-poppins font-semibold space-x-3"
            @click="openSubmitReportImageDocumentationModal"
        >
          <AppIcon name="ChevronRight" classTag="m-auto h-6 w-6"/>
          <h5>Submit</h5>
        </button>
        <AppButton @click="openAddImageDocumentation">
          <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"/>
          <h5>Tambah</h5>
        </AppButton>
      </div>
    </AppTableHeader>
    <div v-if="store.loading" class="flex w-full justify-center my-20">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="h-[24rem]">
      <AppTable
          :key="store.detail.image_details"
          :loading="store.loading"
          :headerList="headerList"
          :bodyList="store.detail.image_details"
          :currentSort="filterActive"
          :currentSortDir="filterDirection"
          :search="filterSearch"
          :action="true"
          :showPageSize="false"
      >
        <template #action="{ data }">
          <TableActionDataMaster
              @edit-button="openEditImageDocumentationModal(data)"
              @erase-button="toggleEraseModal(data.order_number)"
          />
        </template>
      </AppTable>
    </div>
    <TableModal
        title="Photo Dokumentasi"
        :add="addModal"
        :edit="editModal"
        :erase="eraseModal"
        @add-submit="addImageDocumentation"
        @edit-submit="editImageDocumentation"
        @add-close="toggleAddModal"
        @edit-close="toggleEditModal"
        @erase-submit="deleteImageDocumentation"
        @erase-close="toggleEraseModal"
    >
      <div class="gap-3">
        <AppInput v-model="formInput.order_number" v-show="false"/>
        <ImageSelect
            title="Image"
            v-model="formInput.image"
            :isRequired="true"
            :options="store.images"
        />
        <AppInput
            title="Lokasi"
            v-model="formInput.location"
            :isRequired="true"
        />
        <AppInput
            title="Kondisi"
            v-model="formInput.condition"
            :isRequired="true"
        />
        <AppInput title="Keterangan" v-model="formInput.description"/>
      </div>
    </TableModal>
    <!-- submit modal -->
    <AppModal :isOpen="submitModal">
      <template #title>
        <AppModalCloseButton
            title="Submit Laporan Dokumentasi"
            @close="submitModal = false"
        />
      </template>
      <form @submit.prevent="submitReportImageDocumentation">
        <div class="gap-3">
          <AppInput title="Form" v-model="submitForm.form"/>
          <AppInput title="Nama Pekerjaan" v-model="submitForm.work_title"/>
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>

<script setup>

import AppCard from "@/components/AppCard.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import {useReportStore} from "@/store/report";
import {formatTanggal} from "@/helper/tanggal";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppButton from "@/components/AppButton.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppTable from "@/components/AppTable.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import TableModal from "@/components/TableModal.vue";
import AppInput from "@/components/AppInput.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import ImageSelect from "@/components/ImageSelect.vue";
import {ref} from "vue";
import {useRouter} from "vue-router";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import {useToastStore} from "@/store/toast";

const {
  filterActive,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
  filterDirection
} = useFilter();

const {
  formInput,
  addModal,
  editModal,
  eraseModal,
  toggleAddModal,
  toggleEditModal,
  toggleEraseModal,
} = useModal();

const store = useReportStore();
const router = useRouter();
const submitModal = ref(false);
const submitForm = ref({
  form: "",
  work_title: ""
});

const headerList = [
  {
    classTag: " w-20  pr-10 ",
    title: "Nomor",
    id: "order_number",
  },
  {
    classTag: " text-center",
    title: "Gambar",
    id: "image_url",
    isImage: true,
  },
  {
    classTag: "text-center",
    title: "Lokasi",
    id: "location",
  },
  {
    classTag: "text-center",
    title: "Kondisi",
    id: "condition",
  },
  {
    classTag: "text-center",
    title: "Keterangan",
    id: "description",
  }
];

const openAddImageDocumentation = () => {
  if (store.detail.image_details >= 6) {
    const toast = useToastStore();
    toast.showToastShort(false, "Hanya bisa menambahkan maksimal 6 Gambar");
    return;
  }
   store.fetchImagByIdProject();
  store.$patch({
    detail: {
      ...store.detail,
      image_details: store.detail.image_details
          .sort((firstItem, secondItem) => firstItem.order_number - secondItem.order_number)
          .map((item, index) => ({
            ...item,
            order_number: index + 1,
          })),
    }
  });
  formInput.value.order_number = store.detail.image_details.length + 1;
  addModal.open = true;
}

const addImageDocumentation = () => {
  const image_details = store.detail.image_details;
  const data = {
    ...formInput.value,
    image_id: formInput.value.image.id,
    image_url: formInput.value.image.file_url,
  }
  image_details.push(data);
  store.$patch({
    detail: {
      ...store.detail,
      image_details: image_details,
    }
  });
  formInput.value = {};
  addModal.open = false;
}

const deleteImageDocumentation = (order_number) => {
  const image_details = store.detail.image_details.filter(item => item.order_number !== order_number);
  store.$patch({
    detail: {
      ...store.detail,
      image_details: image_details.map((item, index) => ({
        ...item,
        order_number: index + 1,
      }))
    }
  });
  formInput.value = {};
  eraseModal.open = false;
}

const editImageDocumentation = () => {
  const data = {
    ...formInput.value,
    image_id: formInput.value.image.id,
    image_url: formInput.value.image.file_url,
  }
  store.$patch({
    detail: {
      ...store.detail,
      image_details: store.detail.image_details.map(item => {
        if (item.order_number === formInput.value.order_number) {
          return data;
        }
        return item;
      }),
    }
  });
  formInput.value = {};
  editModal.open = false;
}

const openEditImageDocumentationModal = (data) => {
  store.fetchImagByIdProject(data.image_id);
  formInput.value = {
    ...data,
    image: store.images.filter(item => item.image_id === data.image_id)[0],
  };
  editModal.open = true;
}

async function submitReportImageDocumentation() {
  const data = {
    ...submitForm.value,
    images: store.detail.image_details.map((item) => ({
      image_id: item.image_id,
      order_number: item.order_number,
      location: item.location,
      condition: item.condition,
      description: item.description,
    })),
  }
  await store.submitReport(store.detail.id, store.detail.report_type, data);
  router.back();
}

const openSubmitReportImageDocumentationModal = () => {
  submitForm.value.form = store.detail.form;
  submitForm.value.work_title = store.detail.image_details[0]?.work_title;
  submitModal.value = true;
}
</script>


<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

</style>