import { defineStore } from "pinia";

export const useMainStore = defineStore("main", {
  state: () => {
    return {
      year: new Date().getFullYear().toString(),
    };
  },
  getters: {
    getYear: (state) => {
      return state.year;
    },
  },
  actions: {
    updateYear(year) {
      this.year = year;
    },
  },
});
