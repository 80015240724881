<template>
  <div class="flex flex-col font-poppins font-semibold">
    <h4 class="text-center mt-2 text-sm">{{ title }}</h4>
    <h4 class="text-center text-sm">{{ totalTitle }} : {{ total }} {{ backTitle }}</h4>
    <div class="flex justify-center items-center flex-1">
      <div class="h-28 w-28 m-auto">
        <canvas :id="'chart-isu' + id" />
      </div>
      <div class="flex flex-col mt-1">
        <div class="flex-1 flex" v-for="(item, index) in dataList" :key="index">
          <div
            class="h-3 w-3 mt-1 rounded-[0.280rem]"
            :class="['bg-[' + item.color + ']']"
          />
          <div class="flex-1 ml-2">
            <p class="text-[12px] text-left text-[#393D54]">{{ item.title }}</p>
            <p class="text-[13px] text-left">
              {{ item.percent }}% of total {{ detail }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Chart, registerables } from "chart.js";
import { onMounted } from "vue";

const props = defineProps({
  id: {
    type: String,
    default() {
      return "1";
    },
  },
  title: {
    type: String,
    default() {
      return "Isu";
    },
  },
  backTitle: {
    type: String,
    default() {
      return "";
    },
  },
  detail: {
    type: String,
    default: () => "tua",
  },
  
  totalTitle: {
    type: String,
    default: () => "Total Data",
  },
  total: {
    type: Number,
    default() {
      return 15;
    },
  },
  dataList: {
    type: Array,
    default() {
      return [
        {
          title: "Open",
          percent: 20,
          color: "#FBB142",
        },
        {
          title: "Closed",
          percent: 20,
          color: "#0DE262",
        },
      ];
    },
  },
});

Chart.register(...registerables);

onMounted(() => {
  const ctx = document.getElementById("chart-isu" + props.id);
  new Chart(ctx, {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: props.dataList.map((item) => item.percent),
          backgroundColor: props.dataList.map((item) => item.color),
          borderColor: props.dataList.map((item) => item.color),
        },
      ],
    },
  });
});
</script>
