import App from "./App.vue";
import router from "./router";
import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import { plugin, defaultConfig } from "@formkit/vue";
import "./assets/index.css";
import "animate.css";

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

const app = createApp(App);

app.use(pinia);
app.use(plugin, defaultConfig);
app.use(router);

app.mount("#app");
