<template>
  <div class="mb-4">
    <label class="mb-2 block font-medium text-black">
      {{ title }}
    </label>
    <div class="mt-1 flex rounded-md shadow-sm">
      <span
        class="items-center flex bg-gray-100 pl-2 pr-2 text-lg font-semibold"
        >Rp.
      </span>
      <input
        class="base-input"
        ref="inputRef"
        :required="isRequired"
        :readonly="isReadOnly"
      />
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    title: String,
    modelValue: [Number, String],
    options: Object,
    isReadOnly: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isRequired: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    externalValue: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options);

    if (props.externalValue) {
      watch(
        () => props.modelValue,
        (value) => {
          setValue(value);
        }
      );

      watch(
        () => props.options,
        (options) => {
          setOptions(options);
        }
      );
    }

    return { inputRef };
  },
};
</script>
<style scoped>
.base-input {
  @apply block font-semibold w-full text-lg p-2 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white;
}
</style>
