<template>
  <div class="flex h-screen antialiased overflow-y-hidden">
    <div class="flex flex-row h-full w-full">
      <!-- side navigation -->
      <!-- <div class="side-bar hidden lg:block"> -->
      <div class="side-bar lg:block">
        <TheSideBar :navList="navList" />
      </div>
      <div class="flex flex-col h-screen w-full overflow-x-hidden">
        <TheHeader />
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            leave-active-class="animate__animated animate__fadeOut"
            enter-active-class="animate__animated animate__fadeIn"
          >
            <component :is="Component" :key="keyComponent" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script setup>
import TheSideBar from "@/components/TheSideBar.vue";
import TheHeader from "./TheHeader.vue";
import { useMainStore } from "@/store/main";
import { ref } from "vue";

const navList = [
  {
    icon: "Template",
    title: "Dashboard",
    routeName: "dashboard",
    routePath: "/",
  },
  {
    icon: "Users",
    title: "Pengajuan",
    routeName: "pengajuan",
    routePath: "/pengajuan",
    children: [
      {
        title: "Proyek",
        routeName: "proyek",
        routePath: "/proyek",
      },
      {
        title: "Laporan",
        routeName: "laporan",
        routePath: "/laporan",
      },
    ],
  },
  {
    icon: "ChartBar",
    title: "Data Master",
    routeName: "datamaster",
    routePath: "/datamaster",
    children: [
      {
        title: "Cost Code",
        routeName: "cost_code",
        routePath: "/data-master/cost-code",
      },
      {
        title: "Kategori Proyek",
        routeName: "kategori",
        routePath: "/data-master/kategori",
      },
      {
        title: "Kontraktor",
        routeName: "kontraktor",
        routePath: "/data-master/kontraktor",
      },
      {
        title: "User",
        routeName: "user",
        routePath: "/data-master/user",
      },
    ],
  },
];

const keyComponent = ref(0);

const main = useMainStore();
main.$subscribe(() => {
  keyComponent.value++;
});
</script>
<style scoped>
.container-app {
  @apply h-full w-full bg-black;
}

.side-bar {
  @apply w-72 h-screen md:flex-shrink-0 lg:flex-shrink-0 bg-gradient-to-b from-[#212D96] to-[#0016E0] rounded-tr-4xl rounded-br-4xl;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
