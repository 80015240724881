<template>
  <AppCard :shadow="false" class="">
    <template #title>
      Laporan > Detail Laporan > Inspeksi Pelaksanaan Pekerjaan
    </template>
    <div v-if="store.loading" class="flex w-full justify-center my-5">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex z-10 border-b-2">
      <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
      <AppHoverTitle :title="store.detail.project_name"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
      <h3 class="title-card">
        {{ formatTanggal(store.detail.date) }}
      </h3>
    </div>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
    >
      <div class="flex gap-x-4">
        <button
            class="bg-[#65B741] py-2 px-5 text-white rounded-xl shadow-sm focus:ring-[#cdf3c0] hover:bg-[#C1F2B0] focus:ring-4 focus:outline-none inline-flex uppercase font-poppins font-semibold space-x-3"
            @click="submitWIPModal"
        >
          <AppIcon name="ChevronRight" classTag="m-auto h-6 w-6"/>
          <h5>Submit</h5>
        </button>
        <AppButton @click="openAddWorkInProgress">
          <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"/>
          <h5>Tambah</h5>
        </AppButton>
      </div>
    </AppTableHeader>
    <div v-if="store.loading" class="flex w-full justify-center my-20">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="h-[24rem]">
      <AppTable
          :key="store.detail.wip_details"
          :loading="store.loading"
          :headerList="headerList"
          :bodyList="store.detail.wip_details"
          :currentSort="filterActive"
          :currentSortDir="filterDirection"
          :search="filterSearch"
          :action="true"
          :showPageSize="false"
      >
        <template #action="{ data }">
          <TableActionDataMaster
              @edit-button="toggleEditModal(data)"
              @erase-button="toggleEraseModal(data.order_number)"
          />
        </template>
      </AppTable>
    </div>
    <TableModal
        title="Inspeksi Pelaksanaan Pekerjaan"
        :add="addModal"
        :edit="editModal"
        :erase="eraseModal"
        @add-submit="addWorkInProgress"
        @edit-submit="editWorkInProgress"
        @add-close="toggleAddModal"
        @edit-close="toggleEditModal"
        @erase-submit="deleteWorkInProgress"
        @erase-close="toggleEraseModal"
    >
      <div class="gap-3">
        <AppInput v-model="formInput.order_number" v-show="false"/>
        <AppInput
            title="Pekerjaan Yang Diperiksa"
            v-model="formInput.work_title"
            :isRequired="true"
        />
        <AppInput
            title="Uraian Spesifikasi"
            v-model="formInput.work_description"
            :isRequired="true"
        />
        <AppInput
            title="Diajukan"
            v-model="formInput.submitted_at"
            :isRequired="true"
        />
        <AppInput title="Keterangan" v-model="formInput.description"/>
      </div>
    </TableModal>
    <AppModal :isOpen="submitModal">
      <template #title>
        <AppModalCloseButton
            title="Submit Inspeksi Pelaksanaan Pekerjaan"
            @close="submitModal = false"
        />
      </template>
      <form @submit.prevent="submitWorkInProgress">
        <div class="gap-3">
          <AppInput title="Form" v-model="submitForm.form"/>
          <AppInput title="Nomor" v-model="submitForm.number"/>
          <AppInput title="Halaman" v-model="submitForm.page"/>
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTable from "@/components/AppTable.vue";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import {useReportStore} from "@/store/report";
import {formatTanggal} from "@/helper/tanggal";
import {ref} from "vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import useFilter from "@/composable/useFilter";
import TableModal from "@/components/TableModal.vue";
import useModal from "@/composable/useModal";
import AppInput from "@/components/AppInput.vue";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import {useRouter} from "vue-router";
import AppSpinKit from "@/components/AppSpinKit.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  editModal,
  eraseModal,
  toggleAddModal,
  toggleEditModal,
  toggleEraseModal,
} = useModal();

const store = useReportStore();
const router = useRouter();

const headerList = [
  {
    classTag: " w-20  pr-10 ",
    title: "Nomor",
    id: "order_number",
  },
  {
    classTag: " text-center",
    title: "Pekerjaan Yang Diperiksa",
    id: "work_title",
  },
  {
    classTag: "text-center",
    title: "Uraian Spesifikasi",
    id: "work_description",
  },
  {
    classTag: "text-center",
    title: "Keterangan",
    id: "description",
  },
];

const submitModal = ref(false);
const submitForm = ref({
  form: "",
  number: "",
  page: "",
});

async function addWorkInProgress() {
  const wip_details = store.detail.wip_details;
  wip_details.push(formInput.value);
  store.$patch({
    detail: {
      ...store.detail,
      wip_details: wip_details,
    }
  });
  formInput.value = {};
  addModal.open = false;
}

async function editWorkInProgress() {
  store.$patch({
    detail: {
      ...store.detail,
      wip_details: store.detail.wip_details.map(item => {
        if (item.order_number === formInput.value.order_number) {
          return formInput.value;
        }
        return item;
      }),
    }
  });
  formInput.value = {};
  editModal.open = false;
}

async function deleteWorkInProgress(order_number) {
  const wip_details = store.detail.wip_details.filter(item => item.order_number !== order_number);
  store.$patch({
    detail: {
      ...store.detail,
      wip_details: wip_details.map((item, index) => ({
        ...item,
        order_number: index + 1,
      }))
    }
  });
  formInput.value = {};
  eraseModal.open = false;
}

async function submitWorkInProgress() {
  const data = {
    ...submitForm.value,
    details: store.detail.wip_details.map((item) => ({
      order_number: item.order_number,
      work_title: item.work_title,
      work_description: item.work_description,
      submitted_at: item.submitted_at,
      description: item.description,
    })),
  }
  await store.submitReport(store.detail.id, store.detail.report_type, data);
  router.back();
}

const openAddWorkInProgress = () => {
  store.$patch({
    detail: {
      ...store.detail,
      wip_details: store.detail.wip_details.map((item, index) => ({
        ...item,
        order_number: index + 1,
      })),
    }
  });
  formInput.value.order_number = store.detail.wip_details.length + 1;
  addModal.open = true;
}

const submitWIPModal = () => {
  submitForm.value.form = store.detail.form;
  submitForm.value.number = store.detail.number;
  submitForm.value.page = store.detail.page;
  submitModal.value = true;
}
</script>
<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

.edit-button {
  @apply bg-yellow-500 rounded-xl focus:ring-yellow-200 hover:bg-yellow-400 focus:ring-4 focus:outline-none;
}
</style>
