import axios from "@/helper/axios";
import _ from "lodash";
import Moment from "moment";
import { defineStore } from "pinia";
import { extendMoment } from "moment-range";
import { useCostCode } from "./costCode";
import { useKategoriStore } from "./kategori";
import { useMainStore } from "./main";
import { useMaterialStore } from "./material";
import { formatNilai } from "@/helper/rupiah";
import { formatTanggal } from "@/helper/tanggal";
import moment from "moment";

const getDelivery = (realisasi, schedule) => {
  const delivery = {
    ahead: 0,
    normal: 0,
    delay: 0,
    notFinish: 0,
  };

  realisasi.forEach((v, i) => {
    if (v > schedule[i]) {
      delivery.ahead++;
    } else if (v == schedule[i]) {
      delivery.normal++;
    } else if (v < schedule[i] && v != 0) {
      delivery.delay++;
    } else {
      delivery.notFinish++;
    }
  });

  delivery.ahead = getPercent(delivery.ahead, schedule.length);
  delivery.normal = getPercent(delivery.normal, schedule.length);
  delivery.delay = getPercent(delivery.delay, schedule.length);
  delivery.notFinish = getPercent(delivery.notFinish, schedule.length);

  return delivery;
};

const getDeviasi = (realisasi, schedule, project) => {
  return realisasi.map((v, i) => {
    const devi = Math.abs(v.total - schedule[i].total);
    const id_header = project.headers[i].id;
    return { devi, id_header };
  });
};

const getListWithHeader = (list, project) => {
  var total = 0;
  return list.map((v, i) => {
    total += v;
    const id_header = project.headers[i].id;
    return { total, id_header };
  });
};

const getPercent = (value, sum) => {
  return ((value / sum) * 100).toFixed(2);
};

const convertCostcode = (costcode, projects) => {
  const convertCostcode = {
    title: costcode.title,
    rkhp: costcode.rkhp,
    nilai_kontrak: 0,
  };

  projects.forEach((project) => {
    convertCostcode.nilai_kontrak += project.nilai_kontrak;
  });

  return convertCostcode;
};

const convertKategori = (kategori, projects) => {
  const convertKategori = {
    id: kategori.id,
    title: kategori.nama,
    nilai_kontrak: 0,
    kontrak: 0,
  };

  projects.forEach((project) => {
    convertKategori.nilai_kontrak += project.nilai_kontrak;
    convertKategori.kontrak++;
  });

  return convertKategori;
};

export const useDashboardStore = defineStore("dashboard", {
  state: () => {
    return {
      kategori_id: 0,
      firstFetch: false,
      listModal: false,
      projects: [],
      images: [],
      project: {},
      id: "",
      listProject: [],
      loadingProjects: false,
      loadingProject: false,
      loadingListProject: false,
      loadingImage: false,
      error: null,
    };
  },
  getters: {
    getProjectDetail: (state) => {
      const project = state.project;
      var headers = [];
      var listSchedule = [];
      var listRealisasi = [];
      const getValue = (v, i) => {
        return v.data.filter((f) => {
          return f.project_header_id == i.id;
        })[0];
      };

      project.headers.forEach((i) => {
        headers.push(i.name.split("<br />").join("|"));
        listSchedule.push(
          _.sum(
            project.scope.map((v) => {
              return parseFloat(getValue(v, i).plan_value);
            })
          )
        );
        listRealisasi.push(
          _.sum(
            project.scope.map((v) => {
              return parseFloat(getValue(v, i).actual_value);
            })
          )
        );
      });

      const schedule = getListWithHeader(listSchedule, project);
      const realisasi = getListWithHeader(listRealisasi, project);
      const totalRealisasi = realisasi.slice(-1)[0].total;
      const deviasi = getDeviasi(realisasi, schedule, project);
      const delivery = getDelivery(listRealisasi, listSchedule);
      return {
        headers,
        schedule,
        realisasi,
        deviasi,
        totalRealisasi,
        delivery,
      };
    },
    getProjectProgress: (state) => {
      const moment = extendMoment(Moment);
      const data = state.project.headers.filter((v) => moment(new Date()).isBetween(v.start_date, v.end_date));
      if (data && data.length) {
        return data[0];
      }

      return state.project.headers[state.project.headers.length - 1];
    },
    getProjectByKategori: (state) => {
      return (id_kategori) => {
        const mainStore = useMainStore();
        return state.projects.filter((project) => {
          const yearStart = project.mulai.split("-")[0];
          const yearEnd = project.selesai.split("-")[0];
          return (
            id_kategori == project.id_kategori &&
            (yearStart == mainStore.getYear || yearEnd == mainStore.getYear)
          );
        });
      };
    },
    getCostCode: (state) => {
      const costcodeStore = useCostCode();
      const costCodes = costcodeStore.getCostcodeByTahun;
      return costCodes.map((costCode) => {
        return convertCostcode(
          costCode,
          state.projects.filter((project) => {
            return project.id_detail_cost_code === costCode.id;
          })
        );
      });
    },
    getKategori: (state) => {
      const mainStore = useMainStore();
      const kategoriStore = useKategoriStore();
      const kategori = kategoriStore.getKategori;
      return kategori.map((value) => {
        return convertKategori(
          value,
          state.projects.filter((project) => {
            const yearStart = project.mulai.split("-")[0];
            const yearEnd = project.selesai.split("-")[0];
            return (
              project.id_kategori === value.id &&
              (yearStart == mainStore.getYear || yearEnd == mainStore.getYear)
            );
          })
        );
      });
    },
    getImages: (state) => {
      return state.images.map((image) => {
        return `${process.env.VUE_APP_API_URL}/storage/${image.file_url}`;
      });
    },
    getListProject: (state) => {
      return state.listProject;
    },
    getProject: (state) => {
      if (state.project && Object.keys(state.project).length) {
        const project = state.project;

        project.mulai_format = formatTanggal(project.mulai);
        project.selesai_format = formatTanggal(project.selesai);
        project.nilai_kontrak_format = formatNilai(project.nilai_kontrak);

        return project;
      } else {
        return {};
      }
    },
    getProjectKurvaS: (state) => {
      moment.locale();
      const project = state.project;

      if (state.id != project.id) {
        state.id = project.id;
      }

      project.mulai_moment = moment(new Date(project.mulai));
      project.selesai_moment = moment(new Date(project.selesai));

      project.hari =
        project.selesai_moment.diff(moment(), "days") > 1
          ? project.selesai_moment.diff(moment(), "days")
          : 0;

      project.contract = project.selesai_moment.diff(
        project.mulai_moment,
        "days"
      );

      return project;
    },
    getProsesPekerjaan(state) {
      const materialStore = useMaterialStore();
      materialStore.fetchMaterials(state.project.id);
      const nilaiKontrak = {
        nilai: formatNilai(state.project.nilai_kontrak),
        progress: 100,
      };
      const nilaiRelsiasi = {
        nilai: formatNilai(
          (
            state.project.nilai_kontrak *
            (this.getProjectDetail?.totalRealisasi / 100)
          ).toFixed(0)
        ),
        progress: this.getProjectDetail?.totalRealisasi,
      };
      return {
        nilaiKontrak,
        nilaiRelsiasi,
      };
    },
  },
  actions: {
    async fetchProjects() {
      this.projects = [];
      this.loadingProjects = true;
      this.loadingProject = true;
      try {
        this.projects = (await axios.get("proyek/all")).data.data;
        this.project = (
          await axios.get("proyek/" + this.projects[0].id + "?with_scope=true")
        ).data.data;
        this.loadingProjects = false;
        this.loadingProject = false;
      } catch ({ response }) {
        this.loadingProjects = false;
        this.loadingProject = false;
      }
    },
    async fetchProjectsByKategori(id_kategori) {
      this.projects = [];
      this.loadingProjects = true;
      this.loadingProject = true;
      try {
        this.projects = (await axios.get("proyek/all")).data.data;
        this.project = (
          await axios.get(
            "proyek/" +
              this.getProjectByKategori(id_kategori)[0].id +
              "?with_scope=true"
          )
        ).data.data;
        this.fetchImageById(this.getProjectByKategori(id_kategori)[0].id);
        this.loadingProjects = false;
        this.loadingProject = false;
      } catch ({ response }) {
        this.loadingProjects = false;
        this.loadingProject = false;
      }
    },
    async fetchProjectById(id) {
      this.loadingProject = true;
      try {
        this.fetchImageById(id);
        this.project = (
          await axios.get("proyek/" + id + "?with_scope=true")
        ).data.data;
        this.loadingProject = false;
        this.listModal = false;
      } catch ({ response }) {
        this.loadingProject = false;
      }
    },
    async fetchImageById(id) {
      this.loadingImage = true;
      this.images = [];
      try {
        this.images = (
          await axios.get(`v2/projects/${id}/dailies/images?per_page=10&page=1`)
        ).data.data;
        this.loadingImage = false;
      } catch ({ response }) {
        this.loadingImage = false;
      }
    },
    async fetchListProject(kategori_id = "", nama = "") {
      this.loadingListProject = true;
      try {
        this.listProject = (
          await axios.get(
            `proyek?for=dashboard&category=${kategori_id}&nama=${nama}`
          )
        ).data.data;
        this.loadingListProject = false;
      } catch ({ response }) {
        this.loadingListProject = false;
      }
    },
    openListModal(kategori_id) {
      this.listModal = true;
      this.kategori_id = kategori_id;
      if (this.listModal) {
        this.fetchListProject(kategori_id, "");
      }
    },
    setKategoriId(id) {
      this.kategori_id = id;
    },
  },
});
