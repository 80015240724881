import axios from "@/helper/axios";
import { defineStore } from "pinia";
import { useToastStore } from "./toast";
import {getAllUsers, getUsersByCategory} from "@/services/master";

export const useKategoriStore = defineStore("kategori", {
  state: () => {
    return {
      kategori: [],
      kategoriDetail: {},
      loading: false,
      loadingData: false,
      error: null,
      category_users: [],
      userKategori: {
        avp: [],
        vp: [],
        pengawas: [],
      },
      users: [],
    };
  },
  getters: {
    getKategori: (state) => {
      return state.kategori.map((val, index) => {
        val["nomor"] = index + 1;
        return val;
      });
    },
    getKategoriForm: (state) => {
      return state.kategori.map((val) => {
        const newVal = {
          title: val.nama,
          value: val.id,
        };
        return newVal;
      });
    },
    getKategoriList: (state) => {
      var kategori = [{ title: "Semua", value: 0 }];
      return kategori.concat(
        state.kategori.map((val) => {
          const newVal = {
            title: val.nama,
            value: val.id,
          };
          return newVal;
        })
      );
    },
    getUserKategori: (state) => {
      const avp = state.userKategori.avp
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          const newVal = {
            value: val.id,
            title: val.name,
          };
          return newVal;
        });
      const vp = state.userKategori.vp
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          const newVal = {
            value: val.id,
            title: val.name,
          };
          return newVal;
        });
      const pengawas = state.userKategori.pengawas
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          const newVal = {
            value: val.id,
            title: val.name,
          };
          return newVal;
        });

      return {
        avp,
        vp,
        pengawas,
      };
    },
    getUserKategoriList: (state) => {
      const avp = state.userKategori.avp
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          val.position = "AVP";
          return val;
        });
      const vp = state.userKategori.vp
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          val.position = "VP";
          return val;
        });
      const pengawas = state.userKategori.pengawas
        ?.filter((v, index, self) => {
          return index === self.findIndex((t) => t.id === v.id);
        })
        ?.map((val) => {
          val.position = "Pengawas";
          return val;
        });

      const combine = [...avp, ...vp, ...pengawas]?.map((v, i) => {
        v.nomor = i + 1;
        return v;
      });
      return combine;
    },
  },
  actions: {
    async fetchKategori() {
      this.kategori = [];
      this.loading = true;
      try {
        this.kategori = (await axios.get("kategori")).data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchUsers() {
      this.loading = !this.loading;
      const toast = useToastStore();
      try {
        const response = await getAllUsers();
        const existing_ids = this.category_users.map(v => v.id);
        this.users = response.data.filter(v => !existing_ids.includes(v.id)).map(v => ({...v, title: `${v.username} - ${v.role.name}`, value: v.id}));
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
    async fetchKategoriUser(idKategori, status = "get") {
      try {
        if (status === "update") {
          this.loadingData = true;
        } else {
          this.loading = true;
        }

        const fetchList = [
          axios.get(`v2/categories/${idKategori}/users?role=avp`),
          axios.get(`v2/categories/${idKategori}/users?role=vp`),
          axios.get(`v2/categories/${idKategori}/users?role=pengawas`),
        ];

        const response = await Promise.all(fetchList);

        this.userKategori.avp = response[0].data.data;

        this.userKategori.vp = response[1].data.data;

        this.userKategori.pengawas = response[2].data.data;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
        this.loadingData = false;
      }
    },
    async getUsersByCategory(id) {
      this.loading = !this.loading;
      try {
        const response = await getUsersByCategory(id);
        this.category_users = response.data.map((v, index) => ({...v, nomor: index + 1, role_name: v.role.name }));
      } catch (error) {
        const toast = useToastStore();
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
    async addKategori(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post("kategori", data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchKategori();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async addUserKategori(id, data) {
      this.loading = !this.loading;
      const toast = useToastStore();
      try {
        const response = (await axios.post(`v2/categories/${id}/users`, data)).data;
        const category_users = this.category_users;
        category_users.push({
          ...response.data,
          nomor: category_users.length + 1,
          role_name: response.data.role.name,
        });
        this.category_users = category_users;

        toast.showToastShort(response.success, response.message);
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
    async updateKategori(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post("kategori/" + data.id, data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchKategori();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteKategori(id) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.delete("kategori/" + id)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchKategori();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteUserKategori(id, idUser) {
      const toast = useToastStore();
      this.loading = !this.loading;
      try {
        const response = (await axios.delete(`v2/categories/${id}/users/${idUser}`)).data;
        this.category_users = this.category_users.filter(user => user.id !== idUser);

        toast.showToastShort(response.success, response.message);
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
  },
});
