<template>
  <div class="flex flex-col py-14 h-screen justify-between">
    <div>
      <img
        src="@/assets/images/logo-aplikasi.png"
        class="w-[11rem] m-auto"
        alt="logo-pkt"
      />
      <nav class="nav-container">
        <div v-for="(navigation, index) in props.navList" :key="index">
          <div v-if="access.getAccess(navigation.routeName)" class="mb-6">
            <TheSideBarItem
              v-if="!navigation.children"
              :routePath="navigation.routePath"
              :routeName="navigation.routeName"
              :icon="navigation.icon"
              :title="navigation.title"
            />

            <TheSideBarItemWithChild
              v-else
              :icon="navigation.icon"
              :routeName="navigation.routeName"
              :title="navigation.title"
              :children="navigation.children"
            />
          </div>
        </div>
      </nav>
    </div>
    <div class="flex justify-center">
      <div class="nav-logout cursor-pointer" @click="logout()">
        <AppIcon name="Logout" class="h-9 w-9 fill-white/70" :solid="true" />
        <p class="nav-child-title">Logout</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/store/auth";
import AppIcon from "./AppIcon.vue";
import TheSideBarItem from "./TheSideBarItem.vue";
import TheSideBarItemWithChild from "./TheSideBarItemWithChild.vue";
import { useAccessStore } from "@/store/access";

const props = defineProps({
  navList: Array,
});

const auth = useAuthStore();
const access = useAccessStore();

function logout() {
  auth.logout();
}
</script>
<style scoped>
.logo-text-header {
  @apply text-6xl font-bold font-baloo text-center text-white;
}

.logo-text-desc {
  @apply text-sm font-bold font-baloo text-center text-white -mt-3;
}

.nav-container {
  @apply mt-9 flex flex-col ml-8 flex-auto;
}

.nav-logout {
  @apply flex gap-2 hover:bg-gray-100/30 rounded-lg w-32 justify-center px-2;
}
.nav-child-title {
  @apply font-poppins font-semibold text-sm m-auto ml-2 mr-0 text-white flex-shrink;
}
</style>
