import axios from "@/helper/axios";
import {defineStore} from "pinia";
import {useToastStore} from "./toast";
import {addVendorService, getVendorsService, updateVendorService} from "@/services/master";

export const useKontraktorStore = defineStore('kontraktor', {
    state: () => {
        return {
            kontraktors: [],
            loading: false,
            loadingData: false,
            error: false
        }
    },
    getters: {
        getKontraktorsForm: (state) => {
            return state.kontraktors.map(val => {
                const newVal = {
                    value: val.id,
                    title: val.nama
                }
                return newVal
            })
        }
    },
    actions: {
        async fetchKontraktors() {
            this.loading = !this.loading;
            try {
                const {data: vendors} = await getVendorsService();
                vendors.map((vendor, index) => {
                    vendor['nomor'] = index + 1;
                    vendor['email'] = vendor.user ? vendor.user.email : '-';
                    return vendor;
                });
                this.updateVendors(vendors);
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = !this.loading;
            }
        },
        async addKontraktor(data) {
            const toast = useToastStore()
            this.loading = !this.loading;
            try {
                const response = await addVendorService(data);
                toast.showToastShort(response.success, response.message)
            } catch (error) {
                toast.showToastShort(false, error.message)
            } finally {
                this.loading = !this.loading;
                this.fetchKontraktors();
            }
        },
        async updateKontraktor(data) {
            const toast = useToastStore()
            this.loading = !this.loading;
            try {
                const response = await updateVendorService(data);
                toast.showToastShort(response.success, response.message)
            } catch (error) {
                toast.showToastShort(false, error.message)
            } finally {
                this.loading = !this.loading;
                this.fetchKontraktors();
            }
        },
        async deleteKontraktor(id) {
            const toast = useToastStore()
            this.loading = !this.loading;
            try {
                const response = (await axios.delete('kontraktor/' + id)).data
                toast.showToastShort(response.success, response.message)
            } catch (error) {
                toast.showToastShort(false, error.message)
            } finally {
                this.loading = !this.loading;
                this.fetchKontraktors();
            }
        },
        updateVendors(payload) {
            this.kontraktors = payload;
        }
    }
})