import { ref } from 'vue'

export default () => {
    const filterActive = ref("");
    const filterDirection = ref("asc");
    const filterSearch = ref("");

    function setFilterActive(id) {
    filterActive.value = id;
    }

    function setFilterDirection(dir) {
    filterDirection.value = dir;
    }

    function setFilterSearch(param) {
    filterSearch.value = param;
    }

    return{
    filterActive, filterDirection, filterSearch,
        setFilterActive, setFilterDirection, setFilterSearch
    }
}