import { reactive, ref } from "vue";

export default () => {
  const formInput = ref({});

  const addModal = reactive({
    open: false,
  });

  const toggleAddModal = () => {
    addModal.open = !addModal.open;
  };

  const editModal = reactive({
    open: false,
  });

  const toggleEditModal = (data) => {
    editModal.open = !editModal.open;
    if (editModal.open) {
      formInput.value = { ...data };
    } else {
      Object.keys(formInput.value).forEach((key) => {
        if (typeof formInput.value[key] != "number") {
          formInput.value[key] = "";
        } else {
          formInput.value[key] = 0;
        }
      });
    }
  };

  const eraseModal = reactive({
    open: false,
    id: null,
  });

  const toggleEraseModal = (id) => {
    eraseModal.open = !eraseModal.open;
    if (id) eraseModal.id = id;
  };

  return {
    formInput,
    addModal,
    toggleAddModal,
    editModal,
    toggleEditModal,
    eraseModal,
    toggleEraseModal,
  };
};
