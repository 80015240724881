<template>
  <div class="flex flex-col flex-grow gap-8 pb-5">
    <AppCard class="h-3/5 overflow-hidden">
      <template #title> Pengajuan Pekerjaan > Harian</template>
      <AppTableHeader
          @set-active="setFilterActive"
          @direction="setFilterDirection"
          :active="filterActive"
          :menuList="store.headerList"
          :withSearch="false"
      >
        <template #title>
          <div
              v-if="project.loading"
              class="flex justify-center items-center"
          >
            <AppSpinKit :chase="true"/>
          </div>
          <ProyekInfoCard v-else :kontraktor="project.project.kontraktor_name" :proyek="project.project.nama"/>
        </template>
        <div class="space-x-3 flex">
          <div class="inline-flex" v-if="store.view === 'master'">
            <button
                @click="backToListProject"
                class="bg-yellow-300 hover:bg-yellow-400 text-yellow-800 font-bold py-2 px-4 rounded-l inline-flex items-center"
            >
              <AppIcon name="ArrowLeft" class="w-4 h-4 mr-2"/>
              <span>Kembali</span>
            </button>
            <button
                @click="openAddMasterModal"
                class="bg-blue-300 hover:bg-blue-400 text-blue-800 font-bold py-2 px-4 rounded-l inline-flex items-center"
            >
              <AppIcon name="PlusCircle" class="w-4 h-4 mr-2"/>
              <span>Buat Tanggal</span>
            </button>
          </div>
          <div v-else class="inline-flex">
            <button
                @click="backToMaster"
                class="bg-yellow-300 hover:bg-yellow-400 text-yellow-800 font-bold py-2 px-4 rounded-l inline-flex items-center"
            >
              <AppIcon name="ArrowLeft" class="w-4 h-4 mr-2"/>
              <span>Kembali</span>
            </button>
            <button
                @click="toggleAddModal"
                class="bg-blue-300 hover:bg-blue-400 text-blue-800 font-bold py-2 px-4 rounded-r inline-flex items-center"
            >
              <AppIcon name="PlusCircle" class="w-4 h-4 mr-2"/>
              <span>Tambah Pekerjaan</span>
            </button>
          </div>
        </div>
      </AppTableHeader>
      <AppTable
          :key="store.data"
          :loading="store.loading || project.loading"
          :withFoot="false"
          :action="true"
          :overflowY="true"
          :headerList="store.headerList"
          :currentSort="filterActive"
          :currentSortDir="filterDirection"
          :bodyList="store.data"
      >
        <template #action="{ data }" v-if="store.view === 'master'">
          <div class="inline-flex">
            <button
                @click="viewDailyDetail(data)"
                class="bg-blue-300 hover:bg-blue-400 text-blue-800 font-bold py-2 px-4 rounded-l inline-flex items-center"
            >
              <AppIcon name="Eye" class="w-4 h-4 mr-2"/>
              <span>Detail</span>
            </button>
          </div>
        </template>
        <template #action="{ data }" v-else>
          <TableActionDataMaster
              @edit-button="toggleEditModal(data)"
              @erase-button="toggleEraseModal(data.id)"
          />
        </template>
      </AppTable>
    </AppCard>
    <AppCard class="flex-grow" v-if="store.view === 'detail'">
      <template #title> Gambar Pekerjaan</template>
      <div class="flex justify-end mt-2 mr-3">
        <AppButton @click="uploadImage"> Upload Gambar</AppButton>
      </div>
      <div class="flex-grow ml-8 flex gap-3">
        <div
            v-for="(item, index) in store.files"
            :key="index"
            class="h-full items-center justify-center px-2 flex"
        >
          <div class="flex-grow w-32 relative">
            <div
                class="relative h-32 bg-slate-100 w-32 object-contain flex justify-center items-center rounded-2xl shadow-lg"
            >
              <button
                  @click="removeImage(index, item)"
                  class="absolute top-0 right-0 rounded-full w-4 h-4"
              >
                <AppIcon name="XCircle" class-tag="w-6 h-6 text-red-500"/>
              </button>
              <div
                  class="absolute pt-1"
                  v-if="!item.upload && store.loadingGambar"
              >
                <AppSpinKit :chase="true"/>
              </div>
              <div class="absolute pt-1" v-else-if="item.upload">
                <AppIcon
                    name="Check"
                    class="bg-green-500/90 h-6 w-6 rounded-full"
                />
              </div>

              <img
                  :src="item.image"
                  class="h-32 bg-slate-100 w-32 object-contain rounded-2xl shadow-lg"
              />
              <a
                  :href="item.image"
                  target="_blank"
                  class="absolute inset-0"
                  v-if="item.upload"
              >
              </a>
            </div>
            <h4 class="text-center truncate mt-1">{{ item.title }}</h4>
          </div>
        </div>
        <div class="flex items-center">
          <label
              class="relative cursor-pointer bg-[#DAE1EC] px-4 py-2 rounded-lg font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
          >
            <span>Pilih Gambar</span>
            <input
                type="file"
                accept="image/*"
                @change="chooseImage"
                class="sr-only"
                multiple
            />
          </label>
        </div>
      </div>
    </AppCard>
    <TableModal
        title="Pekerjaan Harian"
        :add="addModal"
        @add-submit="addDaily"
        @add-close="toggleAddModal"
        :edit="editModal"
        @edit-submit="updateDaily"
        @edit-close="toggleEditModal"
        :erase="eraseModal"
        @erase-submit="eraseDaily"
        @erase-close="toggleEraseModal"
    >
      <AppSelect
          title="Scope"
          v-model="formInput.scope_id"
          :options="project.project.list_scopes"
      />
      <AppInput
          title="Man Power"
          v-model="formInput.man_power"
          type="number"
          :isRequired="true"
      />
      <AppSelect
          title="Kondisi Cuaca"
          v-model="formInput.weather_condition"
          :options="weatherOption"
      />
      <AppInput
          title="Kendala"
          v-model="formInput.constraint"
          :isRequired="true"
      />
      <AppInput title="Catatan" v-model="formInput.note" :isRequired="true" />
    </TableModal>
    <!-- add master date modal -->
    <AppModal :isOpen="isAddMasterModalOpen">
      <template #title>
        <AppModalCloseButton
            title="Tambah Master Tanggal"
            @close="isAddMasterModalOpen = false"
        />
      </template>
      <form @submit.prevent="createMasterDaily">
        <div class="gap-3">
          <AppInput
              title="Tanggal"
              type="date"
              v-model="addMasterModalForm.date"
          />
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Tambah</button>
        </div>
      </form>
    </AppModal>
  </div>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppTable from "@/components/AppTable.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import ProyekInfoCard from "../components/ProyekInfoCard.vue";
import useFilter from "@/composable/useFilter";
import moment from "moment";
import {ref} from "vue";
import {useDaily} from "@/store/daily";
import {useProjectStore} from "@/store/project";
import {useRouter} from "vue-router";
import TableModal from "@/components/TableModal.vue";
import useModal from "@/composable/useModal";
import AppSelect from "@/components/AppSelect.vue";
import AppInput from "@/components/AppInput.vue";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import {useToastStore} from "@/store/toast";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";

const {setFilterActive, setFilterDirection, filterActive, filterDirection} =
    useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const store = useDaily();
const project = useProjectStore();
const toast = useToastStore();
const router = useRouter();

const isAddMasterModalOpen = ref(false);
const addMasterModalForm = ref({
  date: moment().format("YYYY-MM-DD"),
});

const weatherOption = [
  {
    title: "Cerah",
    value: "cerah",
  },
  {
    title: "Gerimis",
    value: "gerimis",
  },
  {
    title: "Hujan",
    value: "hujan",
  },
];

const createMasterDaily = () => {
  if (
      moment(addMasterModalForm.value.date).isBefore(project.project.mulai) ||
      moment(addMasterModalForm.value.date).isAfter(project.project.selesai)
  ) {
    toast.showToastShort(false, "Tanggal tidak masuk dalam jangkauan project");
  } else {
    const data = {
      date: addMasterModalForm.value.date,
      description: "buat tanggal",
    };
    store.addMasterDaily(project.project.id, data).then(() => {
      addMasterModalForm.value.date = "";
      isAddMasterModalOpen.value = false;
      store.fetchMaterDailies(project.project.id)
    })
  }
};

const addDaily = () => {
  const data = {
    scope_id: formInput.value.scope_id,
    uraian: project.project.list_scopes.filter(v => v.value == formInput.value.scope_id)[0].title,
    date: moment(store.master.date).format("YYYY-MM-DD"),
    man_power: formInput.value.man_power,
    weather_condition: formInput.value.weather_condition,
    constraint: formInput.value.constraint,
    note: formInput.value.note,
  }

  store.addDaily(project.project.id, store.master.id, data).then(() => {
    addModal.open = false;
    store.fetchDaily(project.project.id, store.master.id);
  });
};

const updateDaily = () => {
  const data = {
    scope_id: formInput.value.scope_id,
    uraian: project.project.list_scopes.filter(v => v.value == formInput.value.scope_id)[0].title,
    date: moment(store.master.date).format("YYYY-MM-DD"),
    man_power: formInput.value.man_power,
    weather_condition: formInput.value.weather_condition,
    constraint: formInput.value.constraint,
    note: formInput.value.note,
  }

  store.updateDaily(project.project.id, store.master.id, formInput.value.id, data).then(() => {
    editModal.open = false;
    store.fetchDaily(project.project.id, store.master.id);
  });
};

const eraseDaily = (id) => {
  store.eraseDaily(project.project.id, store.master.id, id).then(() => {
    eraseModal.open = false;
    store.fetchDaily(project.project.id, store.master.id);
  });
};

const chooseImage = (data) => {
  const file = data.target.files[0];
  const preview = URL.createObjectURL(data.target.files[0]);
  const files = store.files;
  files.push( {
    index: store.files.length,
    type: "image",
    image: preview,
    file: file,
    title: file.name,
    upload: false,
  });

  store.$patch({
    files: files
  });
};

const removeImage = async (index, item) => {
  const files = store.files;
  files.splice(index, 1);
  store.$patch({
    files: files
  });

  if (item.upload) {
    await store.removeImage(project.project.id, store.master.id, item.erase_id);
  }
};

const uploadImage = () => {
  store.uploadImage(project.project.id, store.master.id, store.files);
};

const viewDailyDetail = (data) => {
  store.fetchDaily(data.project_id, data.id);
}

const openAddMasterModal = () => {
  isAddMasterModalOpen.value = true;
}

const backToMaster = () => {
  store.$patch({
    view: 'master',
    data: store.dailies,
    headerList: store.headerMaster,
    master: {},
    daily: {},
    files: []
  });
}

const backToListProject = () => {
  router.push({ path: "/proyek" });
}
</script>
<style scoped>
.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}
</style>
