<template>
  <TheToast />

  <router-view v-slot="{ Component }">
    <transition
      mode="out-in"
      leave-active-class="animate__animated animate__fadeOut"
      enter-active-class="animate__animated animate__fadeIn"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script setup>
import TheToast from "@/components/TheToast.vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./store/auth";

const auth = useAuthStore();
const router = useRouter();

router.beforeEach((to) => {
  if (to.name === "dashboard_admin" && auth.user.role.name === "Admin") {
    return { name: "cost_code" };
  } else if (
    to.name === "dashboard_admin" &&
    auth.user.role.name !== "Superadmin" &&
    auth.user.role.name !== "Manajemen"
  ) {
    return { path: "/dashboard-detail/all", params: { title: "List Projek" } };
  }
});
</script>
