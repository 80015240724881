<template>
  <div>
    <AppDisclosure>
      <template #button>
        <div class="container-item">
          <AppIcon
            :name="props.icon"
            :solid="true"
            :class="[
              $route.name == props.routeName ? 'fill-white' : 'fill-white/70',
            ]"
          />
          <p
            class="title-item"
            :class="[
              $route.name == props.routeName ? 'text-white' : 'text-white/70',
            ]"
          >
            {{ props.title }}
          </p>
        </div>
      </template>
      <!-- child -->
      <template #panel>
        <ul class="translate-x-14 font-poppins list-disc">
          <li
            v-for="(item, index) in props.children?.filter((v) =>
              access.getAccess(props.routeName, v.routeName)
            )"
            :key="index"
            :class="[
              $route.name == item.routeName ? 'text-white' : 'text-white/70',
            ]"
          >
            <router-link :to="item.routePath">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </template>
    </AppDisclosure>
  </div>
</template>
<script setup>
import { useAccessStore } from "@/store/access";
import AppDisclosure from "./AppDisclosure.vue";
import AppIcon from "./AppIcon.vue";

const access = useAccessStore();

const props = defineProps({
  icon: {
    required: true,
    type: String,
  },
  title: {
    required: true,
    type: String,
  },
  routeName: {
    required: true,
    type: String,
  },
  children: {
    required: true,
    type: Array,
  },
});
</script>
<style scoped>
.container-item {
  @apply flex gap-2 hover:bg-gray-100/30 rounded-lg w-56;
}
.title-item {
  @apply font-poppins font-semibold text-sm m-auto ml-2 mr-0 text-white flex-shrink;
}
</style>
