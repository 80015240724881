import axios from "axios";
import reportType from "@/enum/reportType";

export const getReportsService = async () => {
    const response = await axios.get(`v2/reports?per_page=100`);
    return response.data.data;
};

export const getReportDetailService = async (id) => {
    const response = await axios.get(`v2/reports/${id}`);
    return response.data;
}

export const getReportSubDetailService = async (id, type) => {
    const response = await axios.get(`v2/reports/${id}/${type}`);
    return response.data;
}

export const deleteReportService = async (id) => {
    const response = await axios.delete(`v2/reports/${id}`);
    return response.data;
}

export const addNewReportService = async (report) => {
    const response = await axios.post(`v2/reports`, report);
    return response.data;
}

export const updateSubDetailReportService = async (id, type, data) => {
    const response = await axios.put(`v2/reports/${id}/${reportType[type]}`, data);
    return response.data;
}

export const submitSubDetailReportService = async (id, type) => {
    const response = await axios.put(`v2/reports/${id}/${reportType[type]}/submit`);
    return response.data;
}

export const fetchImagesByIdProjectService = async (id) => {
    const response = await axios.get(`v2/projects/${id}/dailies/images/all`);
    return response.data.data;
}

export const fetchProjectsByIdProjectService = async (id) => {
    const response = await axios.get(`proyek/${id}`);
    return response.data;
}

export const addNewMasterDailyService = async (id, data) => {
    const response = await axios.post(`proyek/${id}/dailies/`, data);
    return response.data;
}