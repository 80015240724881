<template>
  <AppCard :shadow="false" class="">
    <template #title>
      Laporan > Detail Laporan > Inspeksi Masa Pemeliharaan
    </template>
    <div v-if="store.loading" class="flex w-full justify-center my-5">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex z-10 border-b-2">
      <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
      <AppHoverTitle :title="store.detail.project_name"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
      <h3 class="title-card">
        {{ formatTanggal(store.detail.date) }}
      </h3>
    </div>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
    >
      <div class="flex gap-x-4">
        <button
            @click="submitMaintenanceInspectionModal"
            class="bg-[#65B741] py-2 px-5 text-white rounded-xl shadow-sm focus:ring-[#cdf3c0] hover:bg-[#C1F2B0] focus:ring-4 focus:outline-none inline-flex uppercase font-poppins font-semibold space-x-3"
        >
          <AppIcon name="ChevronRight" classTag="m-auto h-6 w-6"/>
          <h5>Submit</h5>
        </button>
      </div>
    </AppTableHeader>
    <div v-if="store.loading" class="flex w-full justify-center my-20">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="h-[24rem]">
      <AppTable
          :key="store.detail.maintenance_details"
          :loading="store.loading"
          :headerList="headerList"
          :bodyList="store.detail.maintenance_details"
          :currentSort="filterActive"
          :currentSortDir="filterDirection"
          :search="filterSearch"
          :action="true"
          :showPageSize="false"
      >
        <template #action="{ data }">
          <button
              @click="toggleEditModal(data)"
              class="bg-yellow-500 rounded-xl focus:ring-yellow-200 hover:bg-yellow-400 focus:ring-4 focus:outline-none"
          >
            <AppIcon name="Pencil" classTag="stroke-white p-1" />
          </button>
        </template>
      </AppTable>
    </div>
    <TableModal
        title="Inspeksi Masa Pemeliharaan"
        :edit="editModal"
        @edit-submit="editImageDocumentation"
        @edit-close="toggleEditModal"
    >
      <div class="gap-3">
        <AppInput
            title="Hasil Inspeksi"
            v-model="formInput.scope_inspection_result"
            :isRequired="true"
        />
        <AppInput title="Keterangan" v-model="formInput.description"/>
      </div>
    </TableModal>
    <AppModal :isOpen="submitModal">
      <template #title>
        <AppModalCloseButton
            title="Submit Inspeksi Masa Pemeliharaan"
            @close="submitModal = false"
        />
      </template>
      <form @submit.prevent="submitMaintenanceInspectionReport">
        <div class="gap-3">
          <AppInput title="Form" v-model="submitForm.form"/>
          <AppInput title="Nomor" v-model="submitForm.number"/>
          <AppInput title="Halaman" v-model="submitForm.page"/>
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>

<script setup>

import AppCard from "@/components/AppCard.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import {useReportStore} from "@/store/report";
import {formatTanggal} from "@/helper/tanggal";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppTable from "@/components/AppTable.vue";
import TableModal from "@/components/TableModal.vue";
import AppInput from "@/components/AppInput.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import {ref} from "vue";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import {useRouter} from "vue-router";

const {
  filterActive,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
  filterDirection
} = useFilter();

const {
  formInput,
  editModal,
  toggleEditModal,
} = useModal();

const store = useReportStore();
const router = useRouter();

const headerList = [
  {
    classTag: " w-20  pr-10 ",
    title: "Nomor",
    id: "order_number",
  },
  {
    classTag: " text-center",
    title: "Judul",
    id: "scope_title",
  },
  {
    classTag: "text-center",
    title: "Hasil Inspeksi",
    id: "scope_inspection_result",
  },
  {
    classTag: "text-center",
    title: "Keterangan",
    id: "description",
  }
];

const submitModal = ref(false);
const submitForm = ref({
  form: "",
  number: "",
  page: "",
});

const editImageDocumentation = () => {
  store.$patch({
    detail: {
      ...store.detail,
      maintenance_details: store.detail.maintenance_details.map(item => {
        if (item.id === formInput.value.id) {
          return {
            ...item,
            scope_inspection_result: formInput.value.scope_inspection_result,
            description: formInput.value.description,
          };
        }
        return item;
      }),
    }
  });
  formInput.value = {};
  editModal.open = false;
}

const submitMaintenanceInspectionModal = () => {
  submitForm.value.form = store.detail.form;
  submitForm.value.number = store.detail.number;
  submitForm.value.page = store.detail.page;
  submitModal.value = true;
}

async function submitMaintenanceInspectionReport() {
  const data = {
    ...submitForm.value,
    details: store.detail.maintenance_details.map((item) => ({
      detail_id: item.id,
      scope_inspection_result: item.scope_inspection_result === "" ? '-' : item.scope_inspection_result,
      is_accepted: true,
      description: item.description,
    })),
  }
  await store.submitReport(store.detail.id, store.detail.report_type, data);
  router.back();
}

store.$subscribe((mutation, state) => {
  if (!state.loading && state.isSubmitSuccess) {
    router.back();
  } else {
    submitForm.value = {
      form: "",
      number: "",
      page: "",
    }
    submitModal.value = false;
  }
})
</script>


<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

</style>