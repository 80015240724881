<template>
  <div class="bg-white overflow-auto flex-grow">
    <!-- jika tidak loading project lagi munculkan -->
    <div
      v-if="!store.loadingProjects"
      class="mx-auto px-3 sm:px-6 overflow-auto space-y-7 pt-1"
    >
      <!-- menunjukan semua costcode -->
      <!-- cost code  -->
      <div
        class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 md:gap-x-32 lg:gap-x-0 lg:grid-cols-2"
        :class="[costcodes.length > 2 ? 'xl:grid-cols-3' : 'xl:grid-cols-2']"
      >
        <div
          v-for="costcode in costcodes"
          :key="costcode.id"
          class="lg:m-auto mx-10 sm:mx-1"
        >
          <AppCard class="lg:w-[23rem] pb-9">
            <template #title> {{ costcode.title }} </template>
            <InfoWithProgress
              class="pt-2 md:px-4"
              :dataList="costcode.dataList"
            />
          </AppCard>
        </div>
      </div>

      <!-- kategori -->
      <!-- memunculkan list kategori -->
      <div
        class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 md:gap-x-32 lg:gap-x-0 lg:grid-cols-4"
      >
        <div v-for="(item, index) in kategori" :key="index" class="m-auto">
          <KategoriCard
            :index="index"
            :id_kategori="item.id"
            :title="item.title"
            :nilai="item.nilai_kontrak"
            :kontrak="item.kontrak"
          />
        </div>
      </div>
    </div>
    <!-- jika loading project tunjukan -->
    <div v-else class="w-full h-full flex justify-center items-center">
      <AppSpinKit :grid="true" />
    </div>
    <div class="hidden bg-[#FBB142] bg-[#4FDFB1] bg-[#ED5B75]"></div>
  </div>
</template>
<script setup>
import KategoriCard from "@/components/KategoriCard.vue";
import AppCard from "@/components/AppCard.vue";
import InfoWithProgress from "@/components/InfoWithProgress.vue";
import { useDashboardStore } from "@/store/dashboard";
import { onMounted, ref } from "vue";
import { useCostCode } from "@/store/costCode";
import AppSpinKit from "@/components/AppSpinKit.vue";
import { useKategoriStore } from "@/store/kategori";

const store = useDashboardStore();
const costcodeStore = useCostCode();
const kategoriStore = useKategoriStore();

const costcodes = ref([]);
const kategori = ref([]);

onMounted(() => {
  store.fetchProjects();
  costcodeStore.fetchCostCodes();
  kategoriStore.fetchKategori();
});

const convert = (value, currency) => {
  if (currency) {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    return value;
  }
};

function convertDataList(convertedCostcodes) {
  return convertedCostcodes.map((convertCostcode) => {
    return {
      title: convertCostcode.title,
      dataList: [
        {
          title: "Anggaran RKAP",
          nilai: "Rp. " + convert(convertCostcode.rkhp, true),
          progress: 100,
          icon: "Stop",
          color: "#FBB142",
        },
        {
          title: "Nilai Kontrak",
          nilai: "Rp. " + convert(convertCostcode.nilai_kontrak, true),
          progress: (
            (convertCostcode.nilai_kontrak / convertCostcode.rkhp) *
            100
          ).toFixed(2),
          icon: "Clock",
          color: "#4FDFB1",
        },
        {
          title: "Realisasi",
          nilai: "Rp. 0",
          progress: 0,
          icon: "CurrencyDollar",
          color: "#ED5B75",
        },
      ],
    };
  });
}

store.$subscribe((mutation, state) => {
  if (!state.loadingProjects) {
    state.id = "";
    costcodes.value = convertDataList(store.getCostCode);
    kategori.value = store.getKategori;
  }
});
</script>
<!-- untuk masuk ke dashboard detail route nya terletak pada component kategori info -->
