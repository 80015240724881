<template>
  <div
    class="w-1.5 bg-[#F6F6FA] rounded-full relative"
    :class="[height]"
    :aria-valuenow="progressBar"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div
      class="w-1.5 rounded-full absolute bottom-0 group cursor-pointer text-center"
      :class="['bg-[' + color + ']']"
      :style="{ height: progressBar + '%', transition: 'height 1.5s' }"
    >
      <div
        class="opacity-0 w-14 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -translate-x-6 mb-2 px-3 pointer-events-none"
      >
        {{ progress }}%
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from '@vue/reactivity';

const props = defineProps({
  progress: {
    type: Number,
  },
  height: {
    type: String,
  },
  color: {
    type: String,
  },
});

const progressBar = computed(() => {
  if (props.progress > 100) {
    return 100;
  } else {
    return props.progress;
  }
});
</script>
