<template>
  <div
    class="bg-[#3E4EDD] flex mb-4 w-56 rounded-4xl shadow-md relative overflow-hidden"
  >
    <div class="absolute">
      <AppIcon :name="icon[index]" class="h-16 w-16 stroke-white fill-white" />
    </div>
    <KategoriInfo
      class="flex-1"
      :id_kategori="id_kategori"
      :title="title"
      :nilai="nilai"
      :kontrak="kontrak"
      :index="index"
    />
  </div>
</template>
<script setup>
import KategoriInfo from "./KategoriInfo.vue";
import AppIcon from "./AppIcon.vue";

const icon = [
    "Home",
    "Filter",
    "LightningBolt",
    "TrendingUp",];

defineProps({
  index: Number,
  id_kategori: {
    type: [Number, String],
    default: 0,
  },
  title: {
    type: String,
    default: "kosong",
  },
  nilai: {
    type: Number,
    default: 0,
  },
  kontrak: {
    type: Number,
    default: 0,
  },
});
</script>
