import { defineStore } from 'pinia'

export const useToastStore =    defineStore('toast', {
    state: () => {
        return {
            show: false,
            message: '',
            status: true
        }
    },
    actions: {
        showToastShort(status, message){
           this.show = true
           this.status = status
           this.message = message
           setTimeout(() => {
                this.show = false
           }, 6000);
        }
    }
}) 