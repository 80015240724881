<template>
  <AppCard :shadow="false">
    <template #title> Data Master > Data Kategori > User </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="openAddUserToCategory">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah User</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="store.category_users"
      :loading="store.loading"
      :headerList="headerList"
      :bodyList="store.category_users"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :action="true"
    >
      <template #action="{ data }">
        <button
          @click="toggleEraseModal(data)"
          class="bg-red-500 rounded-xl focus:ring-red-200 hover:bg-red-400 focus:ring-4 focus:outline-none"
        >
          <AppIcon name="Trash" classTag="stroke-white p-1" />
        </button>
      </template>
    </AppTable>
    <TableModal
      title="Kategori User"
      :add="addModal"
      @add-submit="addUserKategori"
      @add-close="toggleAddModal"
      :erase="eraseModal"
      @erase-submit="deleteUserKategori"
      @erase-close="toggleEraseModal"
    >
      <AppSelect
        title="User"
        v-model="formInput.user_id"
        :options="store.users"
        :isRequired="true"
      />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import { ref } from "vue";
import { useKategoriStore } from "@/store/kategori";
import { useRoute } from "vue-router";
import TableModal from "@/components/TableModal.vue";
import AppSelect from "@/components/AppSelect.vue";

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const { formInput, addModal, toggleAddModal, eraseModal, toggleEraseModal } =
  useModal();

const headerList = [
  {
    classTag: "text-center w-20",
    title: "Nomor",
    id: "nomor",
  },
  {
    classTag: "text-left",
    title: "Nama",
    id: "name",
  },
  {
    classTag: "text-left",
    title: "Role",
    id: "role_name",
  },
];

const store = useKategoriStore();
const route = useRoute();

const id = ref(route.params.id);

async function addUserKategori() {
  await store.addUserKategori(id.value, formInput.value);

  addModal.open = false;
  formInput.value = {};
}

async function deleteUserKategori(data) {
  await store.deleteUserKategori(id.value, data.id);

  eraseModal.open = false;
}

const openAddUserToCategory = async () => {
  await store.fetchUsers();
  addModal.open = true;
}
</script>
