import { defineStore } from "pinia";   
import axios from '@/helper/axios'

export const useSatuanStore = defineStore('satuan', {
    state: () => {
        return {
            satuan: [],
            loading: false,
            loadingData: false
        }
    },
    getters: {
        getSatuanForm: (state) => {
            return state.satuan.map(val => {
                const newVal = {
                    title: val.nama,
                    value: val.id
                }
                return newVal
            })
        }
    },
    actions: {
        async fetchSatuan(){
            this.satuan = []
            this.loading = true
            try{
                this.satuan = (await axios.get('satuan')).data.data
                this.loading = false
            }catch(error){
                this.loading = false
            }
        }
    }
})