<template>
  <AppCard :shadow="false">
    <template #title> Data Master > Data Kategori </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Kategori</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loading"
      :headerList="headerList"
      :bodyList="dataList"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :action="true"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEditModal(data)"
          @erase-button="toggleEraseModal(data.id)"
        >
          <button
            @click="openUser(data)"
            class="bg-blue-700 rounded-xl focus:ring-blue-200 hover:bg-blue-400 focus:ring-4 focus:outline-none"
          >
            <AppIcon name="ArrowRight" classTag="stroke-white p-1" />
          </button>
        </TableActionDataMaster>
      </template>
    </AppTable>
    <TableModal
      title="Kategori"
      :add="addModal"
      @add-submit="addKategori"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateKategori"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteKategori"
      @erase-close="toggleEraseModal"
    >
      <AppInput
        title="Nama Kategori"
        v-model="formInput.nama"
        :isRequired="true"
      />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import AppInput from "@/components/AppInput.vue";
import TableModal from "@/components/TableModal.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import { onMounted, ref } from "vue";
import { useKategoriStore } from "@/store/kategori";
import { useRouter } from "vue-router";

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const headerList = [
  {
    classTag: "w-72 text-left pl-20",
    title: "nomor",
    id: "nomor",
  },
  {
    classTag: "text-left",
    title: "Kategori Proyek",
    id: "nama",
  },
];

const store = useKategoriStore();
const router = useRouter();

const dataList = ref([]);

onMounted(() => {
  store.fetchKategori();
});

store.$subscribe((mutation, state) => {
  if (!state.loading) {
    dataList.value = store.getKategori;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const addKategori = () => {
  store.addKategori(formInput.value);
};
const updateKategori = () => store.updateKategori(formInput.value);
const deleteKategori = (id) => store.deleteKategori(id);

function openUser(data) {
  router.push({
    name: "kategori_user",
    params: {
      id: data.id,
    },
  });
}
</script>
