import { defineStore } from "pinia";
import axios from "@/helper/axios";
import { useToastStore } from "./toast";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      users: [],
      loading: false,
      loadingData: false,
      error: null,
      modalTitle: "",
      modalIsClose: false,
    };
  },
  getters: {
    getUsers: (state) => {
      return state.users.data.map((val, index) => {
        val["nomor"] = index + 1;
        val["role_name"] = val.role?.name;
        return val;
      });
    },
    getUsersForm: (state) => {
      return state.users.data.map((val) => {
        const newVal = {
          title: val.username,
          value: val.id,
        };
        return newVal;
      });
    },
    getUsersKategoriForm: (state) => {
      return state.users.data?.map((val) => {
        const newVal = {
          title: `${val.username} - ${val.role.name}`,
          value: val.id,
        };
        return newVal;
      });
    },
  },
  actions: {
    async fetchUsers() {
      this.users = [];
      this.loading = true;
      try {
        this.users = (await axios.get("users?page=1&per_page=40")).data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async addUser(data) {
      const toast = useToastStore();
      this.modalTitle = "ADD_USER";
      this.loadingData = true;
      try {
        const response = (await axios.post("users", data)).data;
        this.loadingData = false;
        if (!response.errors) {
          if (response.status) {
            toast.showToastShort(true, response.message);
            this.fetchUsers();
          } else {
            toast.showToastShort(false, response.message);
          }
          this.modalIsClose = true;
        } else {
          toast.showToastShort(
            false,
            response.errors.username
              ? "username telah di ambil"
              : "email tidak sesuai"
          );
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async updateUser(data) {
      const toast = useToastStore();
      this.loadingData = true;
      this.modalTitle = "EDIT_USER";
      try {
        const response = (await axios.put("users/" + data.id, data)).data;
        this.loadingData = false;
        if (!response.errors) {
          if (response.status) {
            toast.showToastShort(true, response.message);
            this.fetchUsers();
          } else {
            toast.showToastShort(false, response.message);
          }
          this.modalIsClose = true;
        } else {
          toast.showToastShort(
            false,
            response.errors.username
              ? "username telah di ambil"
              : "email tidak sesuai"
          );
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteUser(id) {
      const toast = useToastStore();
      this.modalTitle = "DELETE_USER";
      this.loadingData = true;
      try {
        const response = (await axios.delete("users/" + id)).data;
        this.loadingData = false;
        if (!response.errors) {
          if (response.status) {
            toast.showToastShort(true, response.message);
            this.fetchUsers();
          } else {
            toast.showToastShort(false, response.message);
          }
          this.modalIsClose = true;
        } else {
          toast.showToastShort(
            false,
            response.errors.username
              ? "username telah di ambil"
              : "email tidak sesuai"
          );
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
  },
});
