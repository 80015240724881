import axios from "axios";
import { useToastStore } from "@/store/toast";
import { getCookie, setCookie } from "./cookies";
import router from "@/router";
import { useAuthStore } from "@/store/auth";

axios.interceptors.request.use((request) => {
  request.baseURL = `${process.env.VUE_APP_API_URL}/api/`;
  if (getCookie("token")) {
    request.headers.common["Authorization"] = "Bearer " + getCookie("token");
  }
  return request;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function ({ response }) {
    const toast = useToastStore();
    const auth = useAuthStore();
    var message = "";
    switch (response.status) {
      case 401:
        message = "Sesi Habis";
        setCookie("token", "");
        auth.$reset();
        router.push({ name: "login" });
        break;
      case 405:
        message = "terlalu lama menghubungi server";
        break;
      case 422:
        case 400:
        return response;
      default:
        message = "error mohon hubungi admin";
        break;
    }
    toast.showToastShort(false, message);
  }
);

export default axios;
