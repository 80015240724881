import axios from "@/helper/axios";
import { defineStore } from "pinia";
import { useMainStore } from "./main";
import { useToastStore } from "./toast";
import {fetchProjectsByIdProjectService} from "@/services";
import dataConvert from "@/helper/dataConvert";

export const useProjectStore = defineStore("project", {
  state: () => {
    return {
      projects: [],
      project: {},
      projectScope: {},
      loadingProjectScope: false,
      loadingProjects: false,
      loadingData: false,
      loadingExcel: false,
      loadingStatus: false,
      loadingWeekly: false,
      loadingFiles: false,
      loadingEraseFile: false,
      indexUploadFiles: {},
      error: null,
      headerList: [
        {
          classTag:
              "min-w-[20rem] whitespace-normal sticky left-0 border-r-2 text-left pl-14 px-1",
          title: "Uraian",
          id: "uraian",
        },
        {
          classTag: "text-center",
          title: "Satuan",
          id: "satuan_name",
        },
        {
          classTag: "text-center whitespace-normal w-20",
          title: "Perkiraan Kuantitas",
          id: "perkiraan_kuantitas",
        },
        {
          classTag: "text-center",
          title: "Harga Satuan (RP)",
          id: "harga_satuan",
          depan: "Rp.",
          currency: true,
        },
        {
          classTag: "text-center",
          title: "Harga Pekerjaan( RP)",
          id: "harga_pekerjaan",
          depan: "Rp.",
          currency: true,
        },
        {
          classTag: "text-center ",
          title: "Bobot Pekerjaan % ",
          id: "bobot_pekerjaan",
          belakang: "%",
        },
      ],
      dataList: [],
    };
  },
  getters: {
    getProjects: (state) => {
      const mainStore = useMainStore();
      return state.projects
        .filter((project) => {
          const yearStart = project.mulai.split("-")[0];
          const yearEnd = project.selesai.split("-")[0];
          return yearStart === mainStore.getYear || yearEnd === mainStore.getYear;
        })
        .map((val) => {
          val["nilai_realisasi"] = 0;
          val["realisasi"] = 0;
          val["id_cost_code"] = val.id_detail_cost_code;
          return val;
        });
    },
    getProjectsForm: (state) => {
      return state.projects?.map((val) => {
        const newVal = {
          title: val.nama,
          value: val.id,
        };
        return newVal;
      });
    },
  },
  actions: {
    async fetchProjects() {
      this.projects = [];
      this.loadingProjects = true;
      try {
        this.projects = (await axios.get("proyek/all")).data.data;
        this.loadingProjects = false;
      } catch (error) {
        this.loadingProjects = false;
      }
    },
    async fetchProjectById(id) {
      this.loading = true;
      const toast = useToastStore();
      try {
        const response = await fetchProjectsByIdProjectService(id);
        this.project = {
          ...response.data,
          list_scopes: response.data.scope.map((val) => ({title: val.uraian, value: val.id})),
        }
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = false;
      }
    },
    async fetchProjectWithScope(id) {
      this.loading = !this.loading;
      const toast = useToastStore();
      try {
        const response = (
          await axios.get("proyek/" + id + "?with_scope=true")
        ).data.data;
        response.files = response.files.map((val) => ({
          url: `${process.env.VUE_APP_API_URL}/storage/${val.file_url}`,
          name: val.original_name,
          id: val.id,
        }));
        this.projectScope = response;
        const {headData, planData} = dataConvert(response);
        headData.value.forEach((v) => {
          v["classTag"] = "text-center";
          v["belakang"] = "%";
          this.headerList.push(v);
        });
        this.dataList = planData.value;
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
    async addProject(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post("proyek", data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchProjects();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async updateProject(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.put("proyek/" + data.id, data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchProjects();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteProject(id) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.delete("proyek/" + id)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchProjects();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async exportProject(id, title) {
      const toast = useToastStore();
      try {
        const response = (
          await axios.get("proyek/" + id + "/export", { responseType: "blob" })
        ).data;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title + " template.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        toast.showToastShort(false, error.message);
      }
    },
    async uploadExcel(id, excel, callBackSuccess) {
      this.loadingExcel = !this.loadingExcel;
      const toast = useToastStore();
      let success = false;
      try {
        let formData = new FormData();
        formData.append("project_id", id);
        formData.append("file", excel);
        const response = (await axios.post("proyek/" + id + "/import", formData)).data;
        toast.showToastShort(response.status, response.message);
        success = response.status;
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loadingExcel = !this.loadingExcel;
        if (success) callBackSuccess()
      }
    },
    uploadFiles(id, files) {
      files
        .filter((val) => !val.upload)
        .forEach(async (file) => {
          this.loadingFiles = true;
          try {
            let formData = new FormData();
            formData.append("file_code", Date.now());
            formData.append("file", file.file);
            const respon = await axios.post(
              "proyek/" + id + "/uploads",
              formData
            );
            this.loadingFiles = false;
            this.indexUploadFiles[file.index] = respon.data.data.id;
          } catch (error) {
            this.loadingFiles = false;
          }
        });
      this.indexUploadFiles = [];
    },
    async deleteFile(id, file) {
      this.loading = true;
      const toast = useToastStore();
      try {
        const response = (await axios.delete(`proyek/${id}/files/${file.id}`)).data;
        toast.showToastShort(response.status, response.message);
        if (response.status) {
          this.projectScope.files = this.projectScope.files.filter((item) => item.id !== file.id);
        }
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = false;
      }
    },
    async updateWeekly(id, data) {
      this.loadingWeekly = true;
      const toast = useToastStore();
      try {
        const response = (
          await axios.post("proyek/" + id + "/scope/value", data)
        ).data;
        if (response.status) {
          toast.showToastShort(true, "Berhasil mengisi data mingguan");
          this.fetchProjectWithScope(id);
        } else {
          toast.showToastShort(false, "Gagal mengisi data mingguan");
        }
        this.loadingWeekly = false;
      } catch (error) {
        this.loadingWeekly = false;
      }
    },
  },
});
