<template>
  <AppCard :shadow="false" class="">
    <template #title> Data Master > Data Cost Code </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Cost Code</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loading"
      :headerList="headerList"
      :bodyList="dataList"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :action="true"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEditModal(data)"
          @erase-button="toggleEraseModal(data.id)"
        />
      </template>
    </AppTable>
    <TableModal
      title="Cost Code"
      :add="addModal"
      @add-submit="addCostcode"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateCostcode"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteCostCode"
      @erase-close="toggleEraseModal"
    >
      <AppInput
        title="Nama Cost Code"
        v-model="formInput.title"
        :isRequired="true"
      />
      <AppSelect
        title="Tahun"
        v-model="formInput.tahun"
        :isRequired="true"
        :options="[
          {
            title: 'Tahun 2021',
            value: '2021',
          },
          {
            title: 'Tahun 2022',
            value: '2022',
          },
          {
            title: 'Tahun 2023',
            value: '2023',
          },
          {
            title: 'Tahun 2024',
            value: '2024',
          },
        ]"
      >
      </AppSelect>
      <AppCurrencyInput
        title="Nilai RKAP"
        v-model="formInput.rkhp"
        :isRequired="true"
        :options="{
          currency: 'IDR',
          currencyDisplay: 'hidden',
        }"
      />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTable from "@/components/AppTable.vue";
import AppInput from "@/components/AppInput.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppButton from "@/components/AppButton.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppCurrencyInput from "@/components/AppCurrencyInput.vue";
import TableModal from "@/components/TableModal.vue";
import TableActionDataMaster from "../components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import { onMounted, ref } from "vue";
import { useCostCode } from "@/store/costCode";

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const store = useCostCode();

const headerList = [
  {
    classTag: "w-72 text-left pl-20 ",
    title: "nomor",
    id: "nomor",
  },
  {
    classTag: "text-left",
    title: "cost code",
    id: "title",
  },
  {
    classTag: "text-left",
    title: "nilai rkap",
    id: "rkhp",
    depan: "Rp. ",
    currency: true,
  },
];

const dataList = ref([]);

onMounted(() => {
  store.fetchCostCodes();
});

store.$subscribe((mutation, state) => {
  if (!state.loading) {
    dataList.value = store.getCostcodeByTahun;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

function addCostcode() {
  formInput.value.nama = formInput.value.title;
  store.addCostcode(formInput.value);
}

function updateCostcode() {
  store.updateCostcode(formInput.value);
}

function deleteCostCode(id) {
  store.deleteCostcode(id);
}
</script>
