<template>
  <AppCard :shadow="false">
    <template #title> Data Master > Data Kontraktor</template>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
    >
      <AppButton @click="createNewVendor">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Kontraktor</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
        :key="store.kontraktors"
        :loading="store.loading"
        :headerList="headerList"
        :currentSort="filterActive"
        :currentSortDir="filterDirection"
        :search="filterSearch"
        :action="true"
        :bodyList="store.kontraktors"
    >
      <template #action="{ data }">
        <TableActionDataMaster
            @edit-button="updateVendor(data)"
            @erase-button="toggleEraseModal(data?.id)"
        >
          <button
              @click="openUsersModal(data?.user)"
              class="bg-blue-500 rounded-xl focus:ring-blue-200 hover:bg-blue-400 focus:ring-4 focus:outline-none"
          >
            <AppIcon name="UserGroup" classTag="stroke-white p-1"/>
          </button>
        </TableActionDataMaster>
      </template
      >
      "
    </AppTable>
    <AppModal :isOpen="usersModal" classTag="max-w-xl">
      <template #title> List User Kontraktor</template>
      <AppTable
          :withFoot="false"
          :headerList="userHeader"
          :bodyList="userList"
      />
      <AppButton class="w-full flex justify-center" @click="openUsersModal">
        Close
      </AppButton>
    </AppModal>
    <TableModal
        title="Kontraktor"
        :add="addModal"
        @add-submit="addKontraktor"
        @add-close="toggleAddModal"
        :edit="editModal"
        @edit-submit="updateKontraktor"
        @edit-close="toggleEditModal"
        :erase="eraseModal"
        @erase-submit="delteKontraktor"
        @erase-close="toggleEraseModal"
    >
      <AppInput
          title="Nama Kontraktor"
          v-model="formInput.name"
          :isRequired="true"
      />
      <AppInput
          title="Alamat"
          v-model="formInput.address"
          :isRequired="true"
      />
      <AppSelect
          title="User"
          v-model="formInput.user_id"
          :options="userOptions"
      />
      <AppSelect
        title="PIC Kontraktor"
        v-model="formInput.pic_id"
        :options="userOptions"
      />
      <AppInput
        title="Nama PIC"
        v-model="formInput.pic_name"
        :isRequired="true"
      />
      <AppInput
        title="Jabatan PIC"
        v-model="formInput.pic_position"
        :isRequired="true"
      />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import AppModal from "@/components/AppModal.vue";
import AppInput from "@/components/AppInput.vue";
import AppSelect from "@/components/AppSelect.vue";
import TableModal from "@/components/TableModal.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import {ref} from "vue";
import {useKontraktorStore} from "@/store/kontraktor";
import {useUserStore} from "@/store/user";

const store = useKontraktorStore();
const user = useUserStore();

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const headerList = [
  {
    classTag: "w-72 text-left pl-20",
    title: "nomor",
    id: "nomor",
  },
  {
    classTag: "text-left w-[30%]",
    title: "Nama Kontraktor",
    id: "nama",
  },
  {
    classTag: "text-left truncate",
    title: "Alamat",
    id: "address",
  },
  {
    classTag: "text-left truncate",
    title: "Email",
    id: "email",
  },
];

store.$subscribe((mutation, state) => {
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const userOptions = ref({});

user.$subscribe((mutation, state) => {
  if (!state.loading) {
    userOptions.value = user.getUsersForm;
  }
});

const addKontraktor = () => store.addKontraktor(formInput.value);
const updateKontraktor = () => store.updateKontraktor(formInput.value);
const delteKontraktor = (id) => store.deleteKontraktor(id);

// to show list of user contraktor
const usersModal = ref(false);
const userList = ref([]);
const userHeader = [
  {
    classTag: "text-left",
    title: "Username",
    id: "username",
  },
  {
    classTag: "text-left",
    title: "Email",
    id: "email",
  },
];

const createNewVendor = () => {
  user.fetchUsers();
  toggleAddModal()
}

const updateVendor = (vendor) => {
  user.fetchUsers();
  vendor.name = vendor.nama;
  vendor.user_id = vendor.id_user;
  toggleEditModal(vendor);
}

function openUsersModal(data) {
  usersModal.value = !usersModal.value;
  if (data) userList.value = [data];
}
</script>
