import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "../components/DefaultLayout.vue";
import AppDefault from "../components/AppDefault.vue";
import Login from "../views/LoginView.vue";
import DashboardAdmin from "../views/DashboardAdminView.vue";
import DashboardDetail from "../views/DashboardDetailView.vue";
import Costcode from "../views/CostcodeView.vue";
import Kategori from "../views/KategoriView.vue";
import KategoriUser from "../views/KategoriUserView.vue";
import Kontraktor from "../views/KontraktorView.vue";
import User from "../views/UserView.vue";
import ScopeMaster from "../views/ScopeProyekMasterView.vue";
import PengajuanPekerjaan from "../views/PengajuanPekerjaanView.vue";
import ScheduleUpload from "../views/ScheduleUploadExcelView.vue";
import SchedulePengajuan from "../views/SchedulePengajuanView.vue";
import Mingguan from "../views/MingguanView.vue";
import Harian from "../views/HarianView.vue";
import Material from "../views/MaterialView.vue";
import Laporan from "../views/LaporansView.vue";
import LaporanDetail from "../views/LaporanDetailView.vue";
import InpseksiPelaksanaanPekerjaan from "../views/InspeksiPelaksanaanPekerjaanView.vue";
import LaporanInspeksiMaterial from "../views/LaporanMaterialView.vue";
import LaporanInspeksiAkhirPekerjaan from "../views/LaporanInspeksiAkhirPekerjaanView.vue";
import LaporanPenilaianKontraktor from "../views/LaporanPenilaianKontraktorView.vue";
import ImageReport from "../views/ImageReportView.vue";
import { useAuthStore } from "@/store/auth";
import { getCookie } from "@/helper/cookies";
import {
  requireReportDetails,
  requireReportDetail,
  requireReports,
  requireVendors,
  requireUsersCategory, requiresAdmin, requireScheduleData, requireDailyData
} from "@/store/private";
import MaintenanceInspectionReportView from "@/views/MaintenanceInspectionReportView.vue";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    meta: {
      requireAuth: true,
    },
    name: "home",
    redirect: () => {
      return { name: "dashboard_admin" };
    },
    children: [
      {
        path: "/dashboard-admin",
        name: "dashboard_admin",
        component: DashboardAdmin,
        meta: { requireAdmin: true, root_name: "dashboard" },
      },
      {
        path: "/dashboard-detail/:id",
        name: "dashboard_detail",
        meta: { root_name: "dashboard" },
        component: DashboardDetail,
      },
      {
        path: "/data-master",
        component: AppDefault,
        meta: { root_name: "datamaster" },
        beforeEnter: requiresAdmin,
        children: [
          {
            path: "cost-code",
            name: "cost_code",
            component: Costcode,
          },
          {
            path: "kategori",
            name: "kategori",
            component: AppDefault,
            children: [
              {
                path: "",
                name: "kategori",
                component: Kategori,
              },
              {
                path: ":id/user",
                name: "kategori_user",
                component: KategoriUser,
                beforeEnter: requireUsersCategory
              },
            ],
          },
          {
            path: "kontraktor",
            name: "kontraktor",
            component: Kontraktor,
            beforeEnter: requireVendors,
          },
          {
            path: "user",
            name: "user",
            component: User,
          },
        ],
      },
      {
        path: "/proyek",
        component: AppDefault,
        meta: { requireAdmin: true, root_name: "pengajuan" },
        children: [
          {
            path: "",
            name: "proyek",
            component: PengajuanPekerjaan,
          },
          {
            path: ":id/upload",
            name: "schedule_upload",
            component: ScheduleUpload,
            meta: {
              requireKontraktor: true,
            },
          },
          {
            path: ":id/schedule",
            name: "schedule",
            component: SchedulePengajuan,
            beforeEnter: requireScheduleData
          },
          {
            path: ":id/scope",
            name: "scope",
            component: ScopeMaster,
          },
          {
            path: ":id/mingguan",
            name: "mingguan",
            component: Mingguan,
          },
          {
            path: ":id/harian",
            name: "harian",
            component: Harian,
            beforeEnter: requireDailyData
          },
          {
            path: ":id/material",
            name: "material",
            component: Material,
          },
        ],
      },
      {
        path: "/laporan",
        component: AppDefault,
        meta: { requireAdmin: true, root_name: "pengajuan" },
        children: [
          {
            path: "",
            name: "laporan",
            component: Laporan,
            beforeEnter: requireReports
          },
          {
            path: ":id/detail",
            name: "laporan_detail",
            component: LaporanDetail,
            beforeEnter: requireReportDetails
          },
          {
            path: ":id/images",
            name: "images",
            component: ImageReport,
            beforeEnter: requireReportDetail
          },
          {
            path: ":id/work_in_progress",
            name: "work_in_progress",
            component: InpseksiPelaksanaanPekerjaan,
            beforeEnter: requireReportDetail
          },
          {
            path: ":id/material_inspections",
            name: "material_inspections",
            component: LaporanInspeksiMaterial,
            beforeEnter: requireReportDetail
          },
          {
            path: ":id/work_result_inspections",
            name: "work_result_inspections",
            component: LaporanInspeksiAkhirPekerjaan,
            beforeEnter: requireReportDetail
          },
          {
            path: ":id/vendor_assessments",
            name: "vendor_assessments",
            component: LaporanPenilaianKontraktor,
            beforeEnter: requireReportDetail
          },
          {
            path: ":id/maintenance_inspection",
            name: "maintenance_inspection",
            component: MaintenanceInspectionReportView,
            beforeEnter: requireReportDetail
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    root_name: "login",
    meta: {
      root_name: "login",
    },
    component: Login,
  },
  {
    path: "/register",
    name: "register",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const auth = await useAuthStore();
  const token = getCookie("token");
  if (to.name !== "login" && !token) {
    return { name: "login" };
  } else if (to.name === "login" && token) {
    return { name: "home" };
  } else {
    if (!auth.user.name && to.name !== "login") auth.getUser();
    return;
  }
});

export default router;
