<template>
  <div class="pt-3 pb-2 space-y-3">
    <img
      :src="gambar[index]"
      alt="listrik"
      class="h-24 w-24 bg-green-500 rounded-full m-auto"
    />
    <div class="text-white font-bold text-center text-sm">
      <p class="uppercase">{{ title }}</p>
      <p>Rp {{ convert(nilai, true) }}</p>
    </div>
    <div class="grid justify-items-center bottom-3 left-4">
      <button @click="toDashboardDetail" type="button" class="simple-button">
        {{ kontrak }} KONTRAK
      </button>
    </div>
  </div>
</template>
<script setup>
import { useDashboardStore } from "@/store/dashboard";
import { useRouter } from "vue-router";

const store = useDashboardStore();

const props = defineProps({
  id_kategori: {
    type: [Number, String],
    default: 0,
  },
  title: {
    type: String,
    default: "MEKANIKAL PLUMBING",
  },
  nilai: {
    type: Number,
    default: 0,
  },
  progress: {
    type: Number,
    default: 0,
  },
  kontrak: {
    type: Number,
    default: 0,
  },
  index: {
    type: Number,
    default: 0,
  },
});

const convert = (value, currency) => {
  if (currency) {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    return value;
  }
};

const gambar = [
  require("@/assets/images/sipil.png"),
  require("@/assets/images/mekanikal.png"),
  require("@/assets/images/listrik.png"),
  require("@/assets/images/wtp.png"),
];

const router = useRouter();

// untuk masuk ke dashboard detail yang di pakai ialah  id kategori dan title
function toDashboardDetail() {
  if (props.kontrak > 0) {
    router.push({
      path: "/dashboard-detail/" + props.id_kategori,
      params: {
        title: props.title,
      },
    });
    store.project = {};
    store.openListModal(props.id_kategori);
  }
}
</script>
<style scoped>
.simple-button {
  @apply text-white bg-gray-300/80 border border-slate-300 hover:bg-gray-100  font-bold rounded-lg text-sm px-5 py-1 text-center;
}
</style>
