<template>
  <div>
    <ul>
      <li
        class="hover:bg-gray-300 py-2 uppercase cursor-pointer text-lg font-semibold text-center"
        @click="$emit('toAnotherPage', item)"
        v-for="(item, index) in listMenu"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref } from "vue";

defineEmits(["toAnotherPage"]);

const listMenu = ref(["scope", "schedule", "mingguan", "harian", "material"]);

</script>
