<template>
  <AppCard :shadow="false" class="">
    <template #title> Laporan > Detail Laporan > Inspeksi Material</template>
    <div v-if="store.loading" class="flex w-full justify-center my-5">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="header">
      <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
      <AppHoverTitle :title="store.detail.project_name"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
      <h3 class="title-card">
        {{ formatTanggal(store.detail.date) }}
      </h3>
    </div>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
        :menuList="headerList"
    >
      <div class="flex gap-x-4">
        <button
            class="bg-[#65B741] py-2 px-5 text-white rounded-xl shadow-sm focus:ring-[#cdf3c0] hover:bg-[#C1F2B0] focus:ring-4 focus:outline-none inline-flex uppercase font-poppins font-semibold space-x-3"
            @click="submitMaterialReport"
        >
          <AppIcon name="ChevronRight" classTag="m-auto h-6 w-6"/>
          <h5>Submit</h5>
        </button>
      </div>
    </AppTableHeader>
    <div v-if="store.loading" class="flex w-full justify-center my-20">
      <AppSpinKit :grid="true"/>
    </div>
    <AppTable
        v-else
        :key="store.detail.material_details"
        :headerList="headerList"
        :bodyList="store.detail.material_details"
        :action="true"
        :overflowX="true"
    >
      <template #action="{ data }">
        <button @click="toggleEditModal(data)" class="edit-button">
          <AppIcon name="Pencil" classTag="stroke-white p-1"/>
        </button>
      </template>
    </AppTable>
    <TableModal
        title="Inspeksi Material"
        :edit="editModal"
        :erase="eraseModal"
        @edit-submit="editReportMaterial"
        @add-close="toggleAddModal"
        @edit-close="toggleEditModal"
        @erase-close="toggleEraseModal"
    >
      <div class="gap-3">
        <AppInput type="hidden" v-model="formInput.detail_id"/>
        <AppInput
            title="Supply Jenis/Merk/Strength"
            v-model="formInput.supply_material_type_merk_strength"
            :isRequired="true"
        />
        <AppInput
            title="Suplly Dimensi/Warna"
            v-model="formInput.supply_material_dimension_color"
            :isRequired="true"
        />
        <AppInput title="Keterangan" v-model="formInput.description"/>
      </div>
    </TableModal>
    <!-- submit modal -->
    <AppModal :isOpen="submitModal">
      <template #title>
        <AppModalCloseButton
            title="Submit Inspeksi Pelaksanaan Pekerjaan"
            @close="submitModal = false"
        />
      </template>
      <form @submit.prevent="submitReportMaterial">
        <div class="gap-3">
          <AppInput title="Form" v-model="submitForm.form"/>
          <AppInput title="Nomor" v-model="submitForm.number"/>
          <AppInput title="Halaman" v-model="submitForm.page"/>
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppInput from "@/components/AppInput.vue";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import AppTable from "@/components/AppTable.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import TableModal from "@/components/TableModal.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import {formatTanggal} from "@/helper/tanggal";
import {useReportStore} from "@/store/report";
import {ref} from "vue";
import {useRouter} from "vue-router";

const store = useReportStore();
const router = useRouter();

const {
  filterActive,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  editModal,
  eraseModal,
  toggleAddModal,
  toggleEditModal,
  toggleEraseModal,
} = useModal();

const headerList = [
  {
    classTag: " min-w-[4rem] sticky left-0 ",
    title: "Nomor",
    id: "order_number",
  },
  {
    classTag:
        " text-left sticky min-w-[19rem] sticky left-[5.4rem] border-r-2 mr-8",
    title: "Material",
    id: "material_name",
  },
  {
    classTag: "text-center left-[28rem] w-20 ",
    title: "Vol",
    id: "material_size",
  },
  {
    classTag: "text-center w-22",
    title: "Satuan",
    id: "material_unit",
  },
  {
    classTag: "text-center w-48",
    title: "Spek Jenis/Merk/Strength",
    id: "spec_material_type_merk_strength",
  },
  {
    classTag: "text-center w-44",
    title: "Spek Dimensi/Warna",
    id: "spec_material_dimension_color",
  },
  {
    classTag: "text-center w-48",
    title: "Supply Jenis/Merk/Strength",
    id: "supply_material_type_merk_strength",
  },
  {
    classTag: "text-center w-44 ",
    title: "Suplly Dimensi/Warna",
    id: "supply_material_dimension_color",
  },
  {
    classTag: "text-center ",
    title: "Keterangan",
    id: "description",
  },
];

const submitModal = ref(false);
const submitForm = ref({
  form: "",
  number: "",
  page: "",
});

async function editReportMaterial() {
  store.$patch({
    detail: {
      ...store.detail,
      material_details: store.detail.material_details.map((item) => {
        if(item.id === formInput.value.id) {
          return {
            ...item,
            supply_material_type_merk_strength: formInput.value.supply_material_type_merk_strength,
            supply_material_dimension_color: formInput.value.supply_material_dimension_color,
            description: formInput.value.description,
          }
        }
        return item;
      }),
    }
  })
  formInput.value = {};
  editModal.open = false;
}

async function submitReportMaterial() {
  const data = {
    ...submitForm.value,
    details: store.detail.material_details.map((item) => ({
      detail_id: item.id,
      supply_material_type_merk_strength: item.supply_material_type_merk_strength,
      supply_material_dimension_color: item.supply_material_dimension_color,
      description: item.description,
    })),
  }
  await store.submitReport(store.detail.id, store.detail.report_type, data);
  router.back();
}

const submitMaterialReport = () => {
  submitForm.value.form = store.detail.form;
  submitForm.value.number = store.detail.number;
  submitForm.value.page = store.detail.page;
  submitModal.value = true;
}
</script>
<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

.header {
  @apply bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex z-10 border-b-2;
}

.edit-button {
  @apply bg-yellow-500 rounded-xl focus:ring-yellow-200 hover:bg-yellow-400 focus:ring-4 focus:outline-none;
}
</style>
