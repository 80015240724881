import { defineStore } from "pinia";
import axios from "@/helper/axios";
import { useToastStore } from "./toast";

export const useMaterialStore = defineStore("material", {
  state: () => {
    return {
      materials: [],
      loading: false,
      loadingData: false,
      error: null,
    };
  },
  getters: {
    getMaterials: (state) => {
      return state.materials;
    },
  },
  actions: {
    async fetchMaterials(id) {
      this.materials = [];
      this.loading = true;
      try {
        this.materials = (await axios.get(`proyek/${id}/materials`)).data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async addMaterial(id, data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post(`proyek/${id}/materials`, data))
          .data;
        this.loadingData = false;
        if (response.status) {
          toast.showToastShort(true, "Berhasil menambahkan data");
          this.fetchMaterials(id);
        } else {
          toast.showToastShort(false, "Gagal menambahkan data");
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async updateMaterial(id, data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (
          await axios.put(`proyek/${id}/materials/${data.id}`, data)
        ).data;
        this.loadingData = false;
        if (response.status) {
          toast.showToastShort(true, "Berhasil update material");
          this.fetchMaterials(id);
        } else {
          toast.showToastShort(false, "Gagal update material");
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteMaterial(id, idMaterial) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (
          await axios.delete(`proyek/${id}/materials/${idMaterial}`)
        ).data;
        this.loadingData = false;
        if (response.status) {
          toast.showToastShort(true, response.message);
          this.fetchMaterials(id);
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
  },
});
