import { defineStore } from "pinia";
import axios from "@/helper/axios";
import { useToastStore } from "./toast";
import { useMainStore } from "./main";

export const useCostCode = defineStore("costcode", {
  state: () => {
    return {
      costcodes: [],
      loading: false,
      loadingData: false,
      error: null,
    };
  },
  getters: {
    getCostcodeByTahun: (state) => {
      const main = useMainStore();
      return state.costcodes
        .filter((f) => f.tahun === main.getYear)
        .map(({ cost_code, ...newVal }, index) => {
          newVal["title"] = cost_code.title;
          newVal["nomor"] = index + 1;
          newVal["id"] = newVal.id_cost_code;
          return newVal;
        });
    },
    getCostcodeForm: (state) => {
      return state.costcodes.map((val) => {
        const newVal = {
          title: val.cost_code.title + " : " + val.tahun,
          value: val.id,
        };
        return newVal;
      });
    },
  },
  actions: {
    async fetchCostCodes() {
      this.costcodes = [];
      this.loading = true;
      try {
        this.costcodes = (await axios.get("cost-code-detail")).data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async addCostcode(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post("cost-code", data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchCostCodes();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async updateCostcode(data) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.post("cost-code/" + data.id, data)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchCostCodes();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
    async deleteCostcode(id) {
      const toast = useToastStore();
      this.loadingData = true;
      try {
        const response = (await axios.delete("cost-code/" + id)).data;
        this.loadingData = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          this.fetchCostCodes();
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loadingData = false;
      }
    },
  },
});
