<template>
  <AppCard :shadow="false" class="">
    <template #title>
      Laporan > Detail Laporan > Penilaian Kontraktor
    </template>
    <div v-if="store.loading" class="flex w-full justify-center my-5">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="flex items-center border-b-2 bg-[#F4F7FC]">
      <div class="w-full px-9 pt-4 pb-5 flex z-10">
        <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
        <AppHoverTitle :title="store.detail.project_name"/>
        <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
        <h3 class="title-card">
          {{ formatTanggal(store.detail.date) }}
        </h3>
      </div>
      <div>
        <button
            class="bg-[#65B741] py-2 px-5 text-white rounded-xl shadow-sm focus:ring-[#cdf3c0] hover:bg-[#C1F2B0] focus:ring-4 focus:outline-none inline-flex uppercase font-poppins font-semibold space-x-3"
            @click="submitVendorAssessment"
        >
          <AppIcon name="ChevronRight" classTag="m-auto h-6 w-6"/>
          <h5>Submit</h5>
        </button>
      </div>
    </div>
    <div v-if="store.loading" class="max-h-96 flex w-full justify-center my-20">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="table-wrp block max-h-96">
      <table class="border-separate border-spacing mb-10 w-full">
        <thead class="bg-[#F4F7FC] sticky top-0">
        <tr>
          <th class="header-table w-10" rowspan="2">No</th>
          <th class="header-table w-80" rowspan="2">Uraian</th>
          <th class="header-table" colspan="3">Penilaian</th>
          <th class="header-table w-72" rowspan="2">Pedoman Penilaian</th>
          <th class="header-table w-20" rowspan="2">Aksi</th>
        </tr>
        <tr>
          <th class="header-table w-20">Nilai</th>
          <th class="header-table w-20">Bobot</th>
          <th class="header-table w-20">Total</th>
        </tr>
        </thead>
        <tbody class="h-96 overflow-y-auto">
        <AppAsessment
            :key="vendor.id"
            v-for="vendor in store.detail.vendor_details"
            :data="vendor"
            @edit="toggleEditModal"
        />
        </tbody>
      </table>
    </div>
    <!-- update modal -->
    <AppModal :isOpen="editModal.open">
      <template #title>
        <AppModalCloseButton
            title="Update Penilaian Kontraktor"
            @close="toggleEditModal"
        />
      </template>
      <form @submit.prevent="updateAssessment">
        <AppInput type="hidden" v-model="formInput.id"/>
        <AppInput
            title="Penilaian"
            v-model="formInput.assessment_value"
            :isRequired="true"
        />
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Update</button>
        </div>
      </form>
    </AppModal>
    <!-- submit modal -->
    <AppModal :isOpen="submitModal">
      <template #title>
        <AppModalCloseButton
            title="Submit Inspeksi Pelaksanaan Pekerjaan"
            @close="submitModal = false"
        />
      </template>
      <form @submit.prevent="submitAssessment">
        <div class="gap-3">
          <AppInput title="Form" v-model="submitForm.form"/>
          <AppInput title="Nomor" v-model="submitForm.number"/>
          <AppInput title="Halaman" v-model="submitForm.page"/>
          <AppInput
              title="Tanggapan Kontraktor"
              v-model="submitForm.vendor_input"
          />
        </div>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppAsessment from "@/components/AppAsessment.vue";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import AppIcon from "@/components/AppIcon.vue";
import {formatTanggal} from "@/helper/tanggal";
import {useReportStore} from "@/store/report";
import {onMounted, ref} from "vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import AppModal from "@/components/AppModal.vue";
import useModal from "@/composable/useModal";
import AppInput from "@/components/AppInput.vue";
import {useRouter} from "vue-router";
import {useToastStore} from "@/store/toast";
import {useAuthStore} from "@/store/auth";

const {toggleEditModal, editModal, formInput} = useModal();

const store = useReportStore();
const auth = useAuthStore();
const router = useRouter();
const submitModal = ref(false);
const submitForm = ref({
  form: "",
  number: "",
  page: "",
  details: [],
});

async function updateAssessment() {
  if (formInput.value.assessment_value > 10 || formInput.value.assessment_value < 0) {
    const toast = useToastStore();
    toast.showToastShort(false, "Nilai Assessment harus sesuai dengan ketentuan")
    return;
  }

  const vendor_details = store.detail.vendor_details.map((parent) => {
    return {
      ...parent,
      details: parent.details.map(child => {
        if (child.id === formInput.value.id) {
          return {
            ...child,
            assessment_value: formInput.value.assessment_value,
          }
        }
        return child;
      })
    }
  });

  store.$patch({
    detail: {
      ...store.detail,
      vendor_details: vendor_details,
    }
  })
  formInput.value = {};
  editModal.open = false;
}

onMounted(() => {
  if (auth.user.role.name === 'Vendor') {
    const toast = useToastStore();
    toast.showToastShort(false, "User tidak memiliki akses ke menu penilaian kontraktor");
    router.back();
  }
});

async function submitAssessment() {
  const vendor_details = [];
  store.detail.vendor_details.forEach((parent) => {
    parent.details.forEach(child => {
      vendor_details.push({
        detail_id: child.id,
        assessment_value: child.assessment_value,
      });
    })
  });
  const data = {
    ...submitForm.value,
    details: vendor_details,
  }
  await store.submitReport(store.detail.id, store.detail.report_type, data);
  router.back();
}

const submitVendorAssessment = () => {
  submitForm.value.form = store.detail.form;
  submitForm.value.number = store.detail.number;
  submitForm.value.page = store.detail.page;
  submitForm.value.vendor_input = store.detail.vendor_input;
  submitModal.value = true;
}
</script>
<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

.header-table {
  @apply mx-3 border-b-2 px-3 py-2 uppercase text-[#606F89] bg-[#F4F7FC];
}

.data-table {
  @apply mx-3 px-3 py-2 font-poppins;
}
</style>
