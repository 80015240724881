<template>
  <AppCard :shadow="false" class="">
    <template #title> Laporan</template>
    <AppTableHeader
        @set-active="setFilterActive"
        @search="setFilterSearch"
        @direction="setFilterDirection"
        :active="filterActive"
    >
      <AppButton @click="openAddReportModal">
        <AppIcon name="PlusCircle" classTag="w-4 h-4 mr-2"/>
        <span class="m-auto">Tambah Laporan</span>
      </AppButton>
    </AppTableHeader>
    <div v-if="store.loading" class="flex justify-center my-24 w-full">
      <AppSpinKit :grid="true"/>
    </div>
    <AppTable
        v-else
        :key="store.getReports"
        :bodyList="store.getReports"
        :headerList="headerList"
        :currentSort="filterActive"
        :currentSortDir="filterDirection"
        :search="filterSearch"
        :action="true"
        :overflow-x="true"
    >
      <template #action="{ data }">
        <div class="inline-flex">
          <button
              @click="openReport(data)"
              class="bg-blue-300 hover:bg-blue-400 text-blue-800 font-bold py-2 px-4 rounded-l inline-flex items-center"
          >
            <AppIcon name="Eye" class="w-4 h-4 mr-2"/>
            <span>Detail</span>
          </button>
          <button
              @click="toggleEraseModal(data.id)"
              class="bg-red-300 hover:bg-red-400 text-red-800 font-bold py-2 px-4 rounded-r inline-flex items-center"
          >
            <AppIcon name="Trash" class="w-4 h-4 mr-2"/>
            <span>Delete</span>
          </button>
        </div>
      </template>
    </AppTable>
    <TableModal
        title="Laporan"
        :add="addModal"
        @add-submit="addReport"
        @add-close="toggleAddModal"
        :erase="eraseModal"
        @erase-submit="deleteReport"
        @erase-close="toggleEraseModal"
    >
      <div class="grid grid-cols-2 gap-1">
        <AppSelect
            class="col-span-3"
            title="Nama Proyek"
            v-model="formInput.project_id"
            :isRequired="true"
            :options="project.getProjectsForm"
        />
        <AppInput title="Title" v-model="formInput.title" :isRequired="true"/>
        <AppInput
            title="Lokasi"
            v-model="formInput.location"
            :isRequired="true"
        />
        <AppInput title="Keterangan" v-model="formInput.description"/>
        <AppSelect
            title="AVP"
            class="col-span-2"
            v-model="formInput.avp_id"
            :isRequired="true"
            :options="kategori.getUserKategori.avp"
        />
        <AppInput
            title="Jabatan"
            v-model="formInput.avp_approve_position"
            :is-required="true"
        />
        <AppSelect
            title="VP"
            class="col-span-2"
            v-model="formInput.vp_id"
            :isRequired="true"
            :options="kategori.getUserKategori.vp"
        />
        <AppInput
            title="Jabatan"
            v-model="formInput.vp_position"
            :is-required="true"
        />
        <AppSelect
            title="Pengawas"
            class="col-span-2"
            v-model="formInput.spv_id"
            :isRequired="true"
            :options="kategori.getUserKategori.pengawas"
        />
        <AppInput
            title="Jabatan"
            v-model="formInput.spv_position"
            :is-required="true"
        />
      </div>
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import useFilter from "@/composable/useFilter";
import {useReportStore} from "@/store/report";
import {useProjectStore} from "@/store/project";
import useModal from "@/composable/useModal";
import TableModal from "@/components/TableModal.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppInput from "@/components/AppInput.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import {onMounted, watch} from "vue";
import {useKategoriStore} from "@/store/kategori";
import {useRouter} from "vue-router";

const store = useReportStore();
const project = useProjectStore();
const kategori = useKategoriStore();
const router = useRouter();

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {formInput, addModal, toggleAddModal, eraseModal, toggleEraseModal} = useModal();

const headerList = [
  {
    classTag: "min-w-[4rem] sticky left-0 ",
    title: "Nomor",
    id: "nomor",
  },
  {
    classTag:
        "sticky min-w-[16rem] left-[5.4rem] pl-10 text-center border-r-2 mr-8",
    title: "Title",
    id: "title",
  },
  {
    classTag: "w-72 text-left",
    title: "Nama Proyek",
    id: "project_name",
  },
  {
    classTag: "text-center",
    title: "Nomor Kontrak",
    id: "work_order_number",
  },
  {
    classTag: "text-center",
    title: "Lokasi",
    id: "location",
  },
  {
    classTag: "text-center",
    title: "Nilai Proyek",
    id: "sik_value",
    depan: "Rp. ",
    currency: true,
  },
  {
    classTag: "text-center",
    title: "Tanggal Proyek ",
    id: "project_start_date",
  },
  {
    classTag: "text-center",
    title: "Tanggal Laporan",
    id: "date",
  },
];

onMounted(async () => {
  await store.fetchReports();
});

function openReport(data) {
  router.push({
    name: "laporan_detail",
    params: {
      id: data.id,
    },
  });
}

watch(
    () => formInput.value.project_id,
    async (value) => {
      const category_id = project.projects.filter((v) => v.id === parseInt(value, 10))[0].id_kategori;
      await kategori.fetchKategoriUser(category_id);
    }
);

async function addReport() {
  const data = {
    ...formInput.value,
    avp_position: formInput.value.avp_approve_position,
    vendor_id: project.projects.filter(v => v.id !== formInput.value.project_id)[0].vendor_id,
    date: new Date().toISOString().slice(0, 10),
  }
  delete data.avp_approve_position;
  await store.addReport(data);

  formInput.value = {};
}

store.$subscribe((_mutation, state) => {
  if (!state.isModalDeleteOpen) {
    eraseModal.open = false;
  }

  if(!state.isModalAddOpen) {
    addModal.open = false;
  }
})

const openAddReportModal = async () => {
  await project.fetchProjects();
  addModal.open = true;
}

const deleteReport = (id) => {
  store.setModalOpen("DELETE");
  store.deleteReport(id)
}
</script>
