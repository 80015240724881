<template>
  <AppCard :shadow="false">
    <template #title>Pengajuan Pekerjaan > Material </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Material</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loading"
      :bodyList="dataList"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :headerList="headerList"
      :action="true"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEditModal(data)"
          @erase-button="toggleEraseModal(data.id)"
        />
      </template>
    </AppTable>
    <TableModal
      title="Material"
      :add="addModal"
      @add-submit="addMaterial"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateMaterial"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteMaterial"
      @erase-close="toggleEraseModal"
    >
      <AppInput v-show="false" v-model="formInput.date" type="hidden" />
      <AppInput
        title="Material"
        v-model="formInput.description"
        :isRequired="true"
      />
      <AppInput
        title="Perkiraan Kuantitas"
        v-model="formInput.quantity"
        type="number"
      />
      <AppSelect
        title="Satuan"
        v-model="formInput.unit_id"
        :options="satuanOption"
      />
      <AppInput
        title="Jenis/Merk/Strength"
        v-model="formInput.material_brand"
      />
      <AppInput title="DImensi/Warna" v-model="formInput.dimension_color" />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppButton from "@/components/AppButton.vue";
import AppTable from "@/components/AppTable.vue";
import useFilter from "@/composable/useFilter";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import { onMounted, ref } from "vue";
import { useMaterialStore } from "@/store/material";
import { useRoute } from "vue-router";
import TableModal from "@/components/TableModal.vue";
import useModal from "@/composable/useModal";
import AppInput from "@/components/AppInput.vue";
import AppSelect from "@/components/AppSelect.vue";
import { useSatuanStore } from "@/store/satuan";
import moment from "moment";

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const store = useMaterialStore();
const satuan = useSatuanStore();
const route = useRoute();

const headerList = [
  {
    classTag: "text-center",
    title: "Tanggal",
    id: "date",
  },
  {
    classTag: "text-left",
    title: "Material",
    id: "description",
  },
  {
    classTag: "text-center",
    title: "Perkiraan Kuantitas",
    id: "quantity",
  },
  {
    classTag: "text-center",
    title: "satuan",
    id: "unit_name",
  },
  {
    classTag: "text-center",
    title: "Jenis/Merk/Strength",
    id: "material_brand",
  },
  {
    classTag: "text-center",
    title: "Dimensi/Warna",
    id: "dimension_color",
  },
];

const dataList = ref([]);

onMounted(() => {
  store.fetchMaterials(route.params.id);
  satuan.fetchSatuan();
});

store.$subscribe((mutation, state) => {
  if (!state.loading) {
    dataList.value = store.getMaterials;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const satuanOption = ref();

satuan.$subscribe((mutation, state) => {
  if (!state.loading) {
    satuanOption.value = satuan.getSatuanForm;
  }
});

const addMaterial = () => {
  formInput.value.unit_name = satuanOption.value.find(
    (val) => val.value == formInput.value.unit_id
  ).title;
  formInput.value.date = moment().format("YYYY-MM-DD");
  store.addMaterial(route.params.id, formInput.value);
};

const updateMaterial = () => {
  formInput.value.unit_name = satuanOption.value.find(
    (val) => val.value == formInput.value.unit_id
  ).title;
  store.updateMaterial(route.params.id, formInput.value);
};

const deleteMaterial = (id) => {
  store.deleteMaterial(route.params.id, id);
};
</script>
