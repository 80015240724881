<template>
  <AppCard v-if="!store.loadingProject">
    <template #title> Kurva S </template>
    <div class="flex">
      <div class="w-[75%] h-[25.5rem] relative xl:pl-5 xl:pt-10">
        <KurvaSChart
          :dataRealisasi="dataRealisasi"
          :dataSchedule="dataSchedule"
          :labels="labels"
        />
      </div>
      <div class="flex-auto">
        <KurvaSInfo
          :hari="project_time.hari"
          :contract="project_time.contract"
          :project_start="project_time.start"
          :project_end="project_time.end"
          :used="project_time.used"
          :deviasi="project_time.deviasi"
          :planed_progress="project_time.planed"
          :actual_progress="project_time.actual"
        />
      </div>
    </div>
  </AppCard>
  <AppCardSkeleton v-else />
</template>
<script setup>
import AppCard from "./AppCard.vue";
import KurvaSChart from "./KurvaSChart.vue";
import AppCardSkeleton from "./AppCardSkeleton.vue";
import KurvaSInfo from "./KurvaSInfo.vue";
import { useDashboardStore } from "@/store/dashboard";
import { reactive, ref } from "vue";

const store = useDashboardStore();

const labels = ref();
const dataRealisasi = ref();
const dataSchedule = ref();

const project_time = reactive({
  hari: 0,
  start: "",
  end: "",
  contract: 0,
  used: 0,
  planed: 0,
  actual: 0,
  deviasi: 0,
});

store.$subscribe((mutations, state) => {
  if (
    state.project &&
    Object.keys(state.project).length &&
    state.id != state.project.id
  ) {
    const { headers, realisasi, schedule, deviasi } = store.getProjectDetail;
    const project = store.getProjectKurvaS;

    labels.value = headers;
    labels.value.unshift("START");
    dataRealisasi.value = realisasi.map((v) => {
      return v.total;
    });
    dataRealisasi.value.unshift(0);
    dataSchedule.value = schedule.map((v) => {
      return v.total;
    });
    dataSchedule.value.unshift(0);

    project_time.start = project.mulai_moment.format("Do MMMM YYYY");
    project_time.end = project.selesai_moment.format("Do MMMM YYYY");
    project_time.hari = project.hari;
    project_time.contract = project.contract;
    project_time.used = project_time.contract - project_time.hari;
    project_time.planed = schedule.filter((v) => v.id_header === store.getProjectProgress.id)[0].total;
    project_time.actual = realisasi.filter((v) => v.id_header === store.getProjectProgress.id)[0].total;
    project_time.deviasi = deviasi.filter((v) => v.id_header === store.getProjectProgress.id)[0].devi
  }
});
</script>
