<template>
  <Menu as="div" class="ml-3 relative">
    <div>
      <MenuButton
        class="rounded-full flex pr-4 pl-1 hover:bg-gray-50 items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      >
        <span class="sr-only">Open user menu</span>
        <table :class="{ 'bg-green-300 rounded-md': auth.isImpersonating }">
          <tr>
            <th rowspan="2">
              <img
                class="h-9 w-9 mr-3 rounded-full bg-yellow-300"
                src="@/assets/images/avatar.png"
                alt=""
              />
            </th>
            <td rowspan="2" v-if="auth.loading">
              <AppSpinKit :chase="true" />
            </td>
            <td
              class="text-left font-poppins font-extralight"
              v-if="!auth.loading"
            >
              {{ (auth.isImpersonating ? "login as " : "") + user.name }}
            </td>
          </tr>
          <tr>
            <td
              v-if="!auth.loading"
              class="text-left font-poppins font-bold text-[#AEAEAE]"
            >
              {{ user.role }}
            </td>
          </tr>
        </table>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem v-slot="{active}" v-if="auth.isImpersonating">
          <button
              @click.prevent="stopImpersonateUser"
              :class="[
              active ? 'bg-gray-100' : '',
              'block w-full px-4 py-2 text-sm text-gray-700 font-extrabold',
            ]"
          >
            Switch Back
          </button>
        </MenuItem>
        <MenuItem v-slot="{active}" v-if="!auth.isImpersonating">
          <button
              @click.prevent="changePassword"
              :class="[
              active ? 'bg-gray-100' : '',
              'block w-full px-4 py-2 text-sm text-gray-700 font-extrabold',
            ]"
          >
            Change Password
          </button>
        </MenuItem>
        <MenuItem v-slot="{ active }">
          <button
            @click.prevent="auth.logout"
            :class="[
              active ? 'bg-gray-100' : '',
              'block w-full px-4 py-2 text-sm text-gray-700 font-extrabold',
            ]"
          >
            Sign Out
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
  <AppModal :isOpen="addModal.open">
    <template #title>
      <AppModalCloseButton
          title="Ubah Password"
          @close="toggleAddModal"
      />
    </template>
    <form @submit.prevent="confirmationChangePassword">
      <AppInput title="Password" v-model="formInput.password" type="password" />
      <AppInput title="Ulangi Password" v-model="formInput.password_confirmation" type="password" />
      <div class="mt-8 flex items-stretch">
        <button type="submit" class="form-button">Ubah</button>
      </div>
    </form>
  </AppModal>
  <ConfirmationDialog
      title="Ubah Password"
      :isOpen="confirmationModal"
      confirmation_text="Anda akan mengubah kata sandi. Akun akan logout otomatis setelah selesai. Lanjut?"
      @close-modal="toggleConfirmationModal"
      @approve-confirmation="submitChangePassword"
      @cancel-confirmation="toggleConfirmationModal"
  />
</template>
<script setup>
import { useAuthStore } from "@/store/auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ref } from "vue";
import AppSpinKit from "./AppSpinKit.vue";
import AppModal from "@/components/AppModal.vue";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import AppInput from "@/components/AppInput.vue";
import useModal from "@/composable/useModal";
import {useToastStore} from "@/store/toast";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

const {formInput, addModal, toggleAddModal} = useModal();

const confirmationModal = ref(false);

const toggleConfirmationModal = () => {
  confirmationModal.value = !confirmationModal.value;
}

const auth = useAuthStore();

const user = ref({
  name: "-",
  role: "-",
});

user.value.name = auth.user.name;
user.value.role = auth.user.role.name;

auth.$subscribe((mutation, state) => {
  user.value.name = state.user.name;
  user.value.role = state.user.role.name;
});

const changePassword = () => {
  formInput.value = {};
  addModal.open = true;
}

const confirmationChangePassword = () => {
  if (formInput.value.password !== formInput.value.password_confirmation) {
    const toast = useToastStore();
    toast.showToastShort(false, "Passwords don't match");
    return;
  }

  addModal.open = false;
  confirmationModal.value = true;
}

const submitChangePassword = async () => {
  const data = {
    password: formInput.value.password,
    password_confirmation: formInput.value.password_confirmation,
  }
  auth.changePassword(data);
  confirmationModal.value = false;
}

const stopImpersonateUser = async () => {
  auth.stopImpersonating();
}

</script>

<style scoped>
.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}
</style>
