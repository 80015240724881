<template>
  <div class="flex-grow flex flex-col pb-4 w-full overflow-y-auto">
    <div class="h-full flex">
      <div class="xl:w-2/3 flex space-y-2 flex-col mb-4">
        <ProyekDetail :title="title" />
        <ProyekRangkuman />
      </div>
      <!-- untuk weather component -->
      <div class="flex-1">
        <div class="h-full rounded-lg mx-2 flex items-center justify-center">
          <PhotoWidget />
        </div>
      </div>
    </div>
    <KurvaS class="pb-2" />
  </div>
</template>
<script setup>
// sementara
import ProyekDetail from "@/components/ProyekDetail.vue";

import { onMounted, ref } from "vue";
import ProyekRangkuman from "../components/ProyekRangkuman.vue";
import PhotoWidget from "../components/PhotoWidget.vue";
import KurvaS from "@/components/KurvaS.vue";

// sementara
import { useDashboardStore } from "@/store/dashboard";
import { useRoute } from "vue-router";

const title = ref("sementara");
const route = useRoute();

// sementara
const store = useDashboardStore();

onMounted(() => {
  store.setKategoriId(route.params.id);
  title.value = route.params.title;
});
</script>
