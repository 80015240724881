<template>
  <router-view v-slot="{ Component }">
    <transition
      mode="out-in"
      leave-active-class="animate__animated animate__fadeOut"
      enter-active-class="animate__animated animate__fadeIn"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script setup></script>
