<template>
  <AppCard v-if="!store.loadingProjects" :shadow="true">
    <template #title>
      <div class="flex justify-between">
        <h3 class="text-lg text-white font-bold font-poppins mt-1">
          {{ title }}
        </h3>
        <div class="flex px-1">
          <button class="m-1 p-1 rounded-full bg-white" @click="list_project()">
            <AppIcon
              name="ViewGrid"
              classTag="h-6 w-6 hover:text-white text-black"
            />
          </button>
        </div>
      </div>
    </template>
    <div
      class="flex w-full py-2"
      v-if="store.getProject && Object.keys(store.getProject).length"
    >
      <div class="mx-5 ml-10 flex rounded-xl w-24 h-24 bg-gray-400 m-auto">
        <img
          src="@/assets/images/image-13.png"
          class="rounded-xl w-24 h-24 m-auto"
          alt=""
        />
      </div>
      <div class="flex flex-col flex-1 pr-10">
        <p class="truncate font-semibold">
          {{ store.getProject.nama }}
        </p>
        <div class="flex">
          <p class="font-bold mr-1">Kategori :</p>
          <p>{{ store.getProject.kategori_name }}</p>
        </div>
        <div class="flex">
          <p class="font-bold mr-1">Kontraktor :</p>
          <p>{{ store.getProject.kontraktor_name }}</p>
        </div>
        <div class="flex justify-between">
          <p class="">
            {{ store.getProject.mulai_format }} -
            {{ store.getProject.selesai_format }}
          </p>
          <p class="truncate text-right">
            {{ store.getProject.nilai_kontrak_format }}
          </p>
        </div>
      </div>
    </div>
    <div class="w-full py-2 h-52 flex items-center justify-center" v-else>
      <p>Tidak ada proyek yang</p>
    </div>
  </AppCard>
  <ListProjectModal />
</template>
<script setup>
import { onMounted } from "vue";
import AppCard from "./AppCard.vue";
import AppIcon from "./AppIcon.vue";
import ListProjectModal from "./ListProjectModal.vue";
// sementara
// import AppCardSkeleton from "./AppCardSkeleton.vue";
// import { ref } from "vue";
import { useDashboardStore } from "@/store/dashboard";
// import { formatNilai } from "@/helper/rupiah";
// import { formatTanggal } from "@/helper/tanggal";
import { useKategoriStore } from "@/store/kategori";
import { useRoute } from "vue-router";

const route = useRoute();

defineProps({
  title: {
    type: String,
    default: "List Proyek",
  },
});

const store = useDashboardStore();
const kategoriStore = useKategoriStore();

onMounted(() => {
  kategoriStore.fetchKategori();
});

function list_project() {
  store.openListModal(route.params.id == "all" ? "" : route.params.id);
}

// sementara
// const scrollAmount = ref(0);

// function scroll_left() {
//   let content = document.querySelector("#list");
//   if (scrollAmount.value <= 0) return;
//   content.scrollTo({
//     left: (scrollAmount.value -= 200),
//     behavior: "smooth",
//   });
// }
// function scroll_right() {
//   let content = document.querySelector("#list");
//   content.scrollTo({
//     left: (scrollAmount.value += 300),
//     behavior: "smooth",
//   });
//   scrollAmount.value = content.scrollLeft;
// }

// sementara
// function selectedProject(id) {
//   store.fetchProjectById(id);
// }
</script>
