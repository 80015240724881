<template>
  <AppSelect v-model="year" :options="listYear" class="w-24 mt-3" />
</template>
<script setup>
import AppSelect from "@/components/AppSelect.vue";
import { useMainStore } from "@/store/main";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";

const store = useMainStore();

const { year } = storeToRefs(store);

const listYear = ref([
  { title: "2021", value: "2021" },
  { title: "2022", value: "2022" },
  { title: "2023", value: "2023" },
]);

onMounted(() => {
  const yearNow = new Date().getFullYear().toString();
  if (listYear.value.filter((item) => item.value === yearNow).length === 0) {
    listYear.value.push({ title: yearNow, value: yearNow });
  }
});
</script>
