<template>
  <AppCard :shadow="false">
    <template #title> Laporan > Detail Laporan</template>
    <div v-if="store.loading" class="header">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="header">
      <h3 class="text-lg p-2 pr-2 font-poppins">Title :</h3>
      <AppHoverTitle :title="store.getReport.title"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Proyek :</h3>
      <AppHoverTitle :title="store.getReport.project_name"/>
      <h3 class="text-lg p-2 pr-2 font-poppins">Tanggal Laporan :</h3>
      <h3 class="title-card">{{ formatTanggal(store.getReport.date) }}</h3>
    </div>
    <div
        v-if="store.loading"
        class="w-full h-40 flex justify-center items-center"
    >
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else>
      <AppTable
          :key="store.getReportDetail"
          :bodyList="store.getReportDetail"
          :headerList="headerList"
          :action="true"
          :status="true"
          :withFoot="false"
      >
        <template #status="{ data }">
          <div class="flex justify-center">
            <AppButton
                @click="downloadReport(data)"
                class="p-3 m-2"
                v-if="data?.status === 'R03'"
            >
              <AppIcon name="Download" classTag="w-5 h-5"/>
            </AppButton>
            <div>
              <h5 class="status-default" v-if="data.status === 'R01'">
                DRAFT
              </h5>
              <h5 class="status-submited" v-else>submited</h5>
            </div>
          </div>
        </template>
        <template #action="{ data }">
          <button
              @click="openReport(data)"
              class="bg-blue-300 hover:bg-blue-400 text-blue-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <AppIcon v-bind:name="data.report_type === 'T06' || data.report_type === 'T08' ? 'ChevronRight' : 'Eye'" classTag="w-4 h-4 mr-2"/>
            <span>{{data.report_type === 'T06' || data.report_type === 'T08' ? 'Submit' : 'Detail' }}</span>
          </button>
        </template>
      </AppTable>
    </div>

    <div v-if="store.loading" class="footer flex justify-center items-center">
      <AppSpinKit :grid="true"/>
    </div>
    <div v-else class="footer">
      <div class="flex gap-6">
        <div class="space-y-3">
          <h5>Nilai Kontrak</h5>
          <h5>Lokasi</h5>
          <h5>Tanggal Mulai Proyek</h5>
          <h5>Tanggal Selesai Proyek</h5>
        </div>
        <div class="space-y-3">
          <h5>:</h5>
          <h5>:</h5>
          <h5>:</h5>
          <h5>:</h5>
        </div>
        <div class="space-y-3">
          <h5 class="detail">
            {{ formatNilai(store.getReport.sik_value) }}
          </h5>
          <h5 class="detail">{{ store.getReport.location }}</h5>
          <h5 class="detail">
            {{ formatTanggal(store.getReport.project_start_date) }}
          </h5>
          <h5 class="detail">
            {{ formatTanggal(store.getReport.project_end_date) }}
          </h5>
        </div>
      </div>
    </div>
    <AppModal :isOpen="addModal.open">
      <template #title>
        <AppModalCloseButton
            title="Submit Kurva Tagihan Preventive"
            @close="toggleAddModal"
        />
      </template>
      <form @submit.prevent="submitSubDetailReport">
        <AppInput
            title="Tanggal"
            v-show="submitData.report_type === 'T08'"
            v-model="formInput.date"
            v-bind:isRequired="submitData.report_type === 'T08'"
            type="date"
        />
        <AppInput title="Form" v-model="formInput.form"/>
        <div class="mt-8 flex items-stretch">
          <button type="submit" class="form-button">Submit</button>
        </div>
      </form>
    </AppModal>
  </AppCard>
</template>
<script setup>
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import AppCard from "@/components/AppCard.vue";
import AppHoverTitle from "@/components/AppHoverTitle.vue";
import AppSpinKit from "@/components/AppSpinKit.vue";
import {formatNilai} from "@/helper/rupiah";
import {formatTanggal} from "@/helper/tanggal";
import {useReportStore} from "@/store/report";
import {ref} from "vue";
import AppModal from "@/components/AppModal.vue";
import useModal from "@/composable/useModal";
import AppModalCloseButton from "@/components/AppModalCloseButton.vue";
import AppInput from "@/components/AppInput.vue";
import {useRouter} from "vue-router";
import reportType from "@/enum/reportType";

const store = useReportStore();
const router = useRouter();

const {formInput, addModal, toggleAddModal} = useModal();

const headerList = [
  {
    classTag: " w-20  pr-10 ",
    title: "Nomor",
    id: "nomor",
  },
  {
    classTag: "w-72 text-left",
    title: "Laporan",
    id: "title",
  },
  {
    classTag: "text-center",
    title: "Kontraktor",
    id: "vendor_name",
  },
];

const submitData = ref();

function downloadReport(data) {
  store.downloadReport(data);
}

async function submitSubDetailReport() {
  const data = {
    form: formInput.value.form,
  }

  if (submitData.value.report_type === 'T08') {
    data.date = formInput.value.date;
    data.details = [];
  }

  await store.submitReport(submitData.value.id, submitData.value.report_type, data);
  addModal.open = false;
  await store.fetchReportDetails(submitData.value.report_parent_id);
}

function openReport(data) {
  if (data.report_type === "T06" || data.report_type === "T08") {
    submitData.value = data;
    formInput.value.form = data.form;
    if (data.report_type === 'T08') {
      formInput.value.date = data.date;
    }
    addModal.open = true;
  } else {
    router.push({
      name: reportType[data.report_type],
      params: {
        id: data.id,
      },
    });
  }
}
</script>
<style scoped>
.title-card {
  @apply bg-[#DAE1EC] text-lg p-2 font-poppins rounded-md text-center;
}

.form-button {
  @apply bg-blue-500 hover:bg-blue-500/50 hover:shadow-lg w-full p-1.5 rounded-lg text-sm text-white uppercase focus:ring-4 focus:ring-blue-200;
}

.status-default {
  @apply py-2 px-5 m-2 text-black rounded-2xl inline-flex uppercase font-poppins border-2;
}

.status-submited {
  @apply py-2 px-5 m-2 text-white bg-[#A1EEBD] rounded-2xl inline-flex uppercase font-poppins border-2;
}

.detail {
  @apply bg-white border-2 rounded-xl px-2;
}

.header {
  @apply bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex z-10 justify-center;
}

.footer {
  @apply mt-7 bg-[#F4F7FC] w-full h-56 rounded-bl-2xl rounded-br-2xl mb-1 p-5 font-poppins text-lg uppercase;
}
</style>
