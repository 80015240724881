import { defineStore } from "pinia";
import { useAuthStore } from "./auth";

export const useAccessStore = defineStore("access", {
  state: () => {
    return {
      dashboard: [
        {
          id: "65715742-c801-40f2-9eb0-e168e75da41e",
          base: true,
          dasboardAdmin: true,
          dasboardDetail: true,
        },
        {
          id: "eb66e2f7-a9a2-461e-af17-86af859770cf",
          base: true,
          dasboardAdmin: true,
          dasboardDetail: true,
        },
        {
          id: "2c589db5-5e15-4d03-ae0a-b5d5420633a4",
          base: true,
          dasboardDetail: true,
        },
        {
          id: "fd282856-eb0f-4c50-9d5b-6d02cf2698e1",
          base: true,
          dasboardDetail: true,
        },
      ],
      datamaster: [
        {
          id: "65715742-c801-40f2-9eb0-e168e75da41e",
          base: true,
          cost_code: true,
          kategori: true,
          kontraktor: true,
          user: true,
        },
        {
          id: "cde0ebb0-5946-467f-901a-f8911a8408d8",
          base: true,
          cost_code: true,
          kategori: true,
          kontraktor: true,
          user: true,
        },
      ],
      pengajuan: [
        {
          id: "65715742-c801-40f2-9eb0-e168e75da41e",
          base: true,
          proyek: true,
          laporan: true,
        },
        {
          id: "fd282856-eb0f-4c50-9d5b-6d02cf2698e1",
          base: true,
          proyek: true,
          laporan: true,
        },
        {
          id: "2c589db5-5e15-4d03-ae0a-b5d5420633a4",
          base: true,
          proyek: true,
        },
      ],
      login: [
        {
          id: "65715742-c801-40f2-9eb0-e168e75da41e",
          access: true,
        },
      ],
    };
  },
  getters: {
    getAccess: (state) => {
      return (route, child = "base") => {
        {
          const auth = useAuthStore();
          const access = state[route]
            ?.filter((v) => v.id === auth.user.role.id)
            ?.map((v) => v[child])[0];
          return access;
        }
      };
    },
  },
});
