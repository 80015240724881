import {defineStore} from "pinia";
import axios from "@/helper/axios";
import {useToastStore} from "./toast";
import moment from "moment";
import {addNewMasterDailyService} from "@/services";

export const useDaily = defineStore("daily", {
    state: () => {
        return {
            dailies: [],
            daily: [],
            loading: false,
            error: null,
            view: "master",
            data: [],
            master: {},
            headerList: [{classTag: "w-2/5 text-left pl-14 px-1", title: "Tanggal", id: "date"}],
            headerMaster: [{classTag: "w-2/5 text-left pl-14 px-1", title: "Tanggal", id: "date"}],
            headerDetail: [
                {
                    classTag: " py-3",
                    title: "Tanggal",
                    id: "date",
                },
                {
                    classTag: " py-3",
                    title: "Aktivitas Pekerjaan",
                    id: "uraian",
                },
                {
                    classTag: "",
                    title: "Man Power",
                    id: "man_power",
                },
                {
                    classTag: "",
                    title: "Cuaca",
                    id: "weather_condition",
                },
                {
                    classTag: "",
                    title: "Kendala",
                    id: "constraint",
                },
                {
                    classTag: "",
                    title: "Catatan",
                    id: "note",
                },
            ],
            files: []
        };
    },
    actions: {
        async fetchMaterDailies(id) {
            this.loading = !this.loading;
            this.dailies = [];
            const store = useToastStore();
            try {
                const response = (await axios.get(`proyek/${id}/dailies/`)).data;
                this.view = "master";
                this.headerList = this.headerMaster;
                this.data = response.data.map(master => ({...master, date: moment(master.date).format("DD-MM-YYYY")}));
                this.dailies = this.data;
            } catch (error) {
                store.showToastShort(false, error.message);
            } finally {
                this.loading = !this.loading;
            }
        },
        async fetchDaily(id, idDaily) {
            this.loading = true;
            const toast = useToastStore();
            try {
                const response = (await axios.get(`proyek/${id}/dailies/${idDaily}`)).data;
                this.master = response.data;
                this.daily = response.data;
                this.files = Object.values(
                    response.data.files.map((val, index) => {
                        return {
                            index: index,
                            type: "image",
                            erase_id: val.id,
                            image: `${process.env.VUE_APP_API_URL}/storage/${val.file_url}`,
                            title: val.file_name,
                            upload: true,
                        };
                    })
                );
                this.headerList = this.headerDetail;
                this.data = response.data.details;
                this.view = "detail";
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async addMasterDaily(id, data) {
            const toast = useToastStore();
            this.loading = true;
            this.loadingData = true;
            try {
                const response = await addNewMasterDailyService(id, data);
                toast.showToastShort(response.status, response.message);
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async addDaily(id, idDaily, data) {
            const toast = useToastStore();
            this.loading = true;
            try {
                const response = (await axios.post(`proyek/${id}/dailies/${idDaily}/details`, data)).data;
                toast.showToastShort(response.status, response.message);
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async updateDaily(id, idDaily, idDetail, data) {
            const toast = useToastStore();
            this.loading = true;
            try {
                const response = (
                    await axios.put(
                        `proyek/${id}/dailies/${idDaily}/details/${idDetail}`,
                        data
                    )
                ).data;

                toast.showToastShort(response.status, response.message);
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async eraseDaily(id, idDaily, idDetailDaily) {
            const toast = useToastStore();
            this.loading = true;
            try {
                const response = (
                    await axios.delete(
                        `proyek/${id}/dailies/${idDaily}/details/${idDetailDaily}`
                    )
                ).data;

               toast.showToastShort(response.status, response.message);
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async uploadImage(id, idDaily, files) {
            const toast = useToastStore();
            this.loading = true;
            try {
                for (const file of files
                    .filter((val) => !val.upload)) {
                        const formData = new FormData();
                        formData.append("file", file.file);
                        await axios.post(
                            `proyek/${id}/dailies/${idDaily}/file`,
                            formData
                        );
                    }
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
        async removeImage(id, id_daily, erase_id) {
            const toast = useToastStore();
            this.loading = true;
            try {
                const response = (await axios.delete(`proyek/${id}/dailies/${id_daily}/file/${erase_id}`)).data;
                toast.showToastShort(response.status, response.message);
            } catch (error) {
                toast.showToastShort(false, error.message);
            } finally {
                this.loading = false;
            }
        },
    },
});
