import { defineStore } from "pinia";
import axios from "axios";
import { useToastStore } from "./toast";
import reportType from "@/enum/reportType";
import {
  getReportsService,
  getReportDetailService,
  getReportSubDetailService,
  deleteReportService,
  addNewReportService, submitSubDetailReportService, updateSubDetailReportService, fetchImagesByIdProjectService
} from "@/services";

export const useReportStore = defineStore("report", {
  state: () => {
    return {
      originalData: null,
      reports: [],
      report: {},
      detail: {},
      loading: false,
      loadingDownload: false,
      loadingUpdate: false,
      error: null,
      isModalDeleteOpen: false,
      isModalAddOpen: false,
      images: []
    };
  },
  getters: {
    getReports: (state) => {
      state.reports?.forEach((report, index) => (report.nomor = index + 1));
      return state.reports;
    },
    getReport: (state) => {
      return state.report;
    },
    getReportDetail: (state) => {
      return state.report.details
        ?.map((val, index) => {
          val["nomor"] = index + 1;
          return val;
        });
    }
  },
  actions: {
    async fetchReports() {
      this.loading = !this.loading;
      try {
        const { data: reports} = await getReportsService();
        this.updateReports(reports)
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = !this.loading;
      }
    },
    async fetchReportDetails(id) {
      this.loading = !this.loading;
      try {
        const { data: report } = await getReportDetailService(id);
        this.updateReportDetail(report);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = !this.loading;
      }
    },
    async fetchReportSubDetail(id, type) {
      this.loading = !this.loading;
      try {
        const { data: detail } = await getReportSubDetailService(id, type);
        if (type === reportType.T02) {
          detail.wip_details?.map((val, index) => val["nomor"] = index + 1);
        }

        if (type === reportType.T04) {
          // 1. Find parent elements
          const parents = detail.vendor_details?.filter((v) => v.parent_id === null);

          // 2. Add details to each parent
          parents?.forEach((parent) => {
            parent.details = [];

            // 3. Find child elements for each parent
            detail.vendor_details.forEach((child) => {
              if (child.parent_id === parent.id) {
                parent.details.push(child);

                // 4. Process rules for each child
                child.rules = child.assessment_rules.split(";");
                child.rules = child.rules.map((rule) => {
                  const parts = rule.split("|");
                  return { key: parts[0], value: parts[1] };
                });
              }
            });
          });

          detail.vendor_details = parents;
        }

        if (type === reportType.T01) {
          detail.image_details = detail.image_details.map((image) => {
            return {
              ...image,
              image_url: `${process.env.VUE_APP_API_URL}/storage/${image.image_url}`,
              condition: image.image_condition,
              location: image.image_location,
              description: image.image_description,
            }
          });
        }

        this.updateReportSubDetail(detail)
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = !this.loading;
      }
    },
    async addReport(data) {
      const toast = useToastStore();
      this.loading = !this.loading;
      try {
        const response = await addNewReportService(data);
        toast.showToastShort(response.status, response.message)
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
        this.fetchReports();
      }
    },
    async submitReport(id, type, data) {
      const toast = useToastStore();
      this.loading = !this.loading;
      try {
        const response_update = await updateSubDetailReportService(id, type, data);
        let response = {
          status: false,
          message: "Gagal Menyimpan data, silahkan cek kembali data anda"
        };
        if (!response_update.errors) {
          response = await submitSubDetailReportService(id, type);
        }
        this.isSubmitSuccess = response.status;
        toast.showToastShort(response.status, response.message);
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = !this.loading;
      }
    },
    async downloadReport(data) {
      try {
        const response = await axios.get(
          `v2/reports/${data.id}/${reportType[data.report_type]}/download`,
          { responseType: "blob" }
        );
        var downloadLink = window.document.createElement("a");
        var contentTypeHeader = response.headers;
        downloadLink.href = window.URL.createObjectURL(
          new Blob([response.data], { type: contentTypeHeader })
        );
        downloadLink.download = data.report_filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        this.error = error;
      }
    },
    async deleteReport(id) {
      const toast = useToastStore();
      this.loading = !this.loading;
      try {
        const response = await deleteReportService(id);
        toast.showToastShort(response.message, response.message);
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.loading = false;
        this.isModalDeleteOpen = false;
        this.fetchReports()
      }
    },
    async fetchImagByIdProject(except_detail_id) {
      this.loading = !this.loading;
      try {
        const response = await fetchImagesByIdProjectService(this.detail.project_id);
        const ids = this.detail.image_details.map(det => det.image_id).filter((id) => id !== except_detail_id);
        this.images = response.filter((item) => {
          return !ids.includes(item.id);
        }).map(item => {
          return {
            ...item,
            image_id: item.id,
            file_url: `${process.env.VUE_APP_API_URL}/storage/${item.file_url}`
          }
        });
      } finally {
        this.loading = !this.loading;
      }
    },
    updateReports(payload) {
      this.reports = payload;
    },
    updateReportDetail(payload) {
      this.report = payload;
    },
    updateReportSubDetail(payload) {
      this.detail = payload;
    },
    setModalOpen(type) {
      if (type === 'DELETE') {
        this.isModalDeleteOpen = true;
      }
    }
  },
});
