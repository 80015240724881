<template>
  <div
    :class="[
      overflowX ? 'overflow-x-auto rounded-b-md' : 'w-full',
      overflowY ? 'overflow-y-auto ' : '',
    ]"
  >
    <div v-if="!loading">
      <!-- table jika overflow false maka lebar table akan menyesuaikan lebar parent 
         sebaliknya jika overflow true maka lebar table akan menyesuaikan dengan isi table tersebut
    -->
      <table
        class="border-separate border-spacing text-center relative"
        :class="[
          auto ? 'table-auto' : 'table-fixed',
          overflowX ? 'whitespace-nowrap' : 'w-full ',
        ]"
      >
        <!-- bagain header dari table -->
        <thead
          class="bg-[#F4F7FC]"
          :class="[overflowY ? 'sticky top-0 z-10' : '']"
        >
          <tr>
            <th
              class="mx-3 border-b-2 px-3 py-2 uppercase text-[#606F89] bg-[#F4F7FC]"
              :class="[item.classTag]"
              v-for="(item, index) in headerList"
              :key="index"
              v-html="item.title"
            ></th>
            <th v-if="status" class="pr-8">Status</th>
            <th
              v-if="action"
              class="w-40 px-4 bg-[#F4F7FC]"
              :class="[overflowX ? 'border-l-2 sticky right-0' : '']"
            >
              Aksi
            </th>
          </tr>
        </thead>
        <!-- bagian body dari table -->
        <!-- <Transition
          mode="out-in"
          enter-active-class="animate__animated  animate__slideInRight"
          leave-active-class="animate__animated  animate__slideOutRight"
        > -->
        <tbody :key="sortedDataListPaginate">
          <tr
            v-for="(items, indexs) in sortedDataListPaginate"
            :key="items"
            class="odd:bg-white even:bg-[#F4F7FC]"
          >
            <td
              class="mx-3 px-3 py-2 font-poppins"
              :class="[
                item.classTag,
                indexs % 2 === 0 ? 'bg-white' : 'bg-[#F4F7FC]',
              ]"
              v-for="(item, index) in headerList"
              :key="index"
            >
              {{ item.depan }}
              {{ item.isImage ? '' : convert(items[item.id], item.currency) }}
              {{ item.belakang }}
              <div v-if="item.isImage" class="flex items-center justify-center">
                <img :src="items[item.id]"
                     alt="image"
                     class="h-24 w-24 flex-shrink-0"
                />
              </div>
            </td>
            <!-- bagain status -->
            <td v-if="status" class="w-20 pr-8">
              <slot name="status" :data="items" />
            </td>
            <!-- bagian action -->
            <td
              v-if="action"
              class="px-4 py-2 gap-3"
              :class="[
                indexs % 2 === 0 ? 'bg-white' : 'bg-[#F4F7FC]',
                overflowX ? 'border-l-2 sticky right-0' : '',
              ]"
            >
              <slot name="action" :data="items" />
            </td>

            <slot name="additional" :data="items" />
          </tr>
          <!-- jika data tidak ada -->
          <tr v-if="!sortedDataListPaginate.length">
            <td :colspan="headerList.length" class="py-8 font-semibold">
              Tidak Ada Data
            </td>
          </tr>
        </tbody>
        <!-- </Transition> -->
      </table>
    </div>
    <div v-else class="py-14 flex justify-center bg-slate-500/20">
      <AppSpinKit :grid="true" />
    </div>
  </div>
  <!-- bagian footer  -->
  <div class="relative">
    <div
      v-if="withFoot"
      class="w-2/4 mt-4 absolute right-0 mb-6 text-[#606F89] flex justify-around font-poppins"
    >
      <section v-if="showPageSize">
        Rows per page:
        <select
          class="bg-transparent border-0 focus:ring-0 cursor-pointer"
          v-model="pageSize"
        >
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="30">30</option>
          <option :value="40">40</option>
          <option :value="50">50</option>
        </select>
      </section>
      <section class="my-auto blue">
        {{ rangeData.start }}-{{ rangeData.end }}
        of
        {{ sortedDataList.length }}
      </section>
      <section class="my-auto inline-flex space-x-2">
        <button @click="prevPage">
          <AppIcon
            name="ChevronLeft"
            :classTag="rangeData.start > 1 ? 'text-blue-400' : ''"
          ></AppIcon>
        </button>
        <button @click="nextPage">
          <AppIcon
            name="ChevronRight"
            :classTag="
              rangeData.end < sortedDataList.length ? 'text-blue-400' : ''
            "
          ></AppIcon>
        </button>
      </section>
    </div>
  </div>
</template>
<script setup>
import { computed } from "@vue/reactivity";
import { onMounted, ref } from "vue";
import AppIcon from "./AppIcon.vue";
import AppSpinKit from "./AppSpinKit.vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default() {
      return false;
    },
  },
  action: {
    type: Boolean,
    default() {
      return false;
    },
  },
  overflowX: {
    type: Boolean,
    default() {
      return false;
    },
  },
  overflowY: {
    type: Boolean,
    default() {
      return false;
    },
  },
  classTag: String,
  status: {
    type: Boolean,
    default() {
      return false;
    },
  },
  auto: {
    type: Boolean,
    default() {
      return false;
    },
  },
  withFoot: {
    type: Boolean,
    default() {
      return true;
    },
  },
  showPageSize: {
    type: Boolean,
    default() {
      return true;
    },
  },
  currentSort: {
    type: String,
    default() {
      return "";
    },
  },
  currentSortDir: {
    type: String,
    default() {
      return "asc";
    },
  },
  search: {
    type: String,
    default() {
      return "";
    },
  },
  headerList: {
    type: Array,
    default() {
      return [];
    },
  },
  bodyList: {
    type: Array,
    default() {
      return [];
    },
  },
});

const dataList = ref(props.bodyList);

const convert = (value, currency) => {
  if (currency) {
    return new Intl.NumberFormat("en-US").format(value);
  } else {
    return value;
  }
};

// sorting
const sortedDataList = computed(() =>
  dataList.value
    .sort((a, b) => {
      let modifier = 1;
      if (props.currentSortDir === "desc") modifier = -1;
      if (a[props.currentSort] < b[props.currentSort]) return -1 * modifier;
      if (a[props.currentSort] > b[props.currentSort]) return 1 * modifier;
      return 0;
    })
    .filter((v) => {
      return (
        Object.values(v).filter((o) => {
          if (o) return o.toString().includes(props.search);
          return true;
        }).length > 0
      );
    })
);

const pageSize = ref(5);
const first = computed(() => (currentPage.value - 1) * pageSize.value);
const end = computed(() => currentPage.value * pageSize.value);
const currentPage = ref(1);
const rangeData = ref({
  start: computed(() => first.value + 1),
  end: computed(() => first.value + sortedDataListPaginate.value.length),
});

const sortedDataListPaginate = computed(() =>
  sortedDataList.value.filter((row, index) => {
    if (index >= first.value && index < end.value) return true;
  })
);

function nextPage() {
  if (currentPage.value * pageSize.value < sortedDataList.value.length)
    currentPage.value++;
}

function prevPage() {
  if (currentPage.value > 1) currentPage.value--;
}

onMounted(() => {
  if (!props.withFoot) pageSize.value = props.bodyList.length;
});
</script>
