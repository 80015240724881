<template>
  <div class="flex flex-grow flex-col gap-7 pb-4">
    <AppCard :grow="false" :shadow="false">
      <template #title> Pengajuan Pekerjaan > Upload Item </template>
      <div class="bg-[#F4F7FC] w-full px-9 pt-4 pb-5 flex justify-between">
        <ProyekInfoCard :kontraktor="kontraktor" :proyek="proyek" />
        <div class="my-auto">
          <button
            @click="openSchedule"
            type="button"
            class="button-upload rounded-xl px-2.5 py-1 text-white bg-[#EE7B1D] hover:bg-[#e8a46c] uppercase"
          >
            Scope Pengajuan
          </button>
        </div>
      </div>
    </AppCard>
    <AppCard>
      <template #title> Upload Pengajuan Schedule </template>
      <div class="p-8 text-lg font-bold">
        <h3>Ketentuan:</h3>
        <ol class="ml-8">
          <li class="flex">
            1.
            <p class="ml-2">
              Format upload harus sesuai template, perhatikan penjelasan pada
              header template.
            </p>
          </li>
          <li class="my-3 ml-10">
            <button
              @click="unduhTemplate()"
              type="button"
              class="rounded-4xl text-base px-3 text-white bg-[#EE7B1D] hover:bg-[#EE7B1D]/70"
            >
              UNDUH TEMPLATE
            </button>
          </li>
          <li class="flex">
            2.
            <p class="ml-2">Sistem hanya akan memproses sheet pertama</p>
          </li>
          <li class="flex">
            3.
            <p class="ml-2">
              Sistem hanya akan memproses item dengan nomor SPK dan item scope
              yang telah diset Dep. Manajemen Aset untuk item scope ybs (lihat
              di data scope)
            </p>
          </li>
          <li class="flex">
            4.
            <p class="ml-2">
              Mengupload akan menambah item, bukan menghapus yg sudah ada. Untuk
              menghapus semua item bobot schedule dapat dilakukan di menu Delete
              All jika status masih <br />
              DRAFT.
            </p>
          </li>
          <li class="mt-5 flex gap-5">
            <label
              for="file-upload"
              class="relative cursor-pointer bg-[#DAE1EC] px-4 py-2 rounded-lg font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>{{ scheduleFileName }}</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                @change="uploadExcel"
                class="sr-only"
              />
            </label>
            <div
              v-if="store.loadingExcel"
              class="flex justify-center items-center"
            >
              <AppSpinKit :chase="true" />
            </div>
          </li>
        </ol>
      </div>
    </AppCard>
    <AppCard class="flex-grow flex">
      <template #title> Upload File Pendukung</template>
      <div class="flex justify-end mt-2 mr-3">
        <AppButton @click="uploadFiles"> Upload File Pendukung </AppButton>
      </div>
      <div v-if="true" class="flex-grow mx-8 mb-2 flex gap-3">
        <div
          v-for="(item, index) in filePendukung"
          :key="index"
          class="h-full items-center justify-center px-2 flex"
        >
          <div class="flex-grow w-32 relative">
            <div
              v-if="item.image"
              class="relative h-32 bg-slate-100 w-32 object-contain flex justify-center items-center rounded-2xl shadow-lg"
            >
              <div
                class="absolute pt-1"
                v-if="!item.upload && store.loadingFiles"
              >
                <AppSpinKit :chase="true" />
              </div>
              <div class="absolute pt-1" v-else-if="item.upload">
                <AppIcon
                  name="Check"
                  class="bg-green-500/90 h-6 w-6 rounded-full"
                />
              </div>
              <img
                :src="item.image"
                class="h-32 bg-slate-100 w-32 object-contain rounded-2xl shadow-lg"
              />
              <button
                @click="eraseFile(index, item)"
                class="absolute top-0 right-0 rounded-full w-4 h-4"
              >
                <AppIcon name="XCircle" class-tag="w-6 h-6 text-red-500" />
              </button>
            </div>
            <div
              v-else
              class="h-32 bg-slate-100 w-32 object-contain flex justify-center items-center rounded-2xl shadow-lg relative"
            >
              <div class="relative">
                <div
                  class="absolute pt-1"
                  v-if="!item.upload && store.loadingFiles"
                >
                  <AppSpinKit :chase="true" />
                </div>
                <div class="absolute pt-1" v-else-if="item.upload">
                  <AppIcon
                    name="Check"
                    class="bg-green-500/90 h-6 w-6 rounded-full"
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-16 w-16"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <button
                @click="eraseFile(index, item)"
                class="absolute top-0 right-0 rounded-full w-6 h-6"
              >
                <AppIcon name="XCircle" class-tag="w-8 h-8 text-red-500" />
              </button>
            </div>
            <h4 class="text-center truncate mt-1">{{ item.title }}</h4>
          </div>
        </div>
        <div class="flex items-center">
          <label
            class="relative cursor-pointer bg-[#DAE1EC] px-4 py-2 rounded-lg font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
          >
            <span>Pilih File Pendukung</span>
            <input type="file" @change="addFile" class="sr-only" multiple />
          </label>
        </div>
      </div>
      <div class="flex-grow m-4" v-else>
        <AppCardSkeleton />
      </div>
    </AppCard>
  </div>
</template>
<script setup>
import AppCard from "@/components/AppCard";
import AppCardSkeleton from "@/components/AppCardSkeleton.vue";
import ProyekInfoCard from "@/components/ProyekInfoCard.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useProjectStore } from "@/store/project";
import AppSpinKit from "@/components/AppSpinKit.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";

const router = useRouter();
const route = useRoute();
const store = useProjectStore();

const id = ref(route.params.id);
const scheduleFileName = ref("Pilih File");
const filePendukung = ref([]);

const kontraktor = ref(route.params.kontraktor);
const proyek = ref(route.params.proyek);

function openSchedule() {
  router.push({
    name: "schedule",
    params: {
      id: id.value,
    },
  });
}

const unduhTemplate = () => {
  store.exportProject(id.value, proyek.value);
};

const uploadExcel = (data) => {
  scheduleFileName.value = data.target.files[0].name;
  store.uploadExcel(id.value, data.target.files[0], function () {
    openSchedule();
  });
};

const addFile = (data) => {
  const file = data.target.files[0];
  if (file.type.includes("image")) {
    const preview = URL.createObjectURL(file);
    filePendukung.value.push({
      index: filePendukung.value.length,
      type: "image",
      image: preview,
      title: file.name,
      upload: false,
      file: file,
    });
  } else {
    filePendukung.value.push({
      index: filePendukung.value.length,
      title: file.name,
      type: "file",
      upload: false,
      file: file,
    });
  }
};

const uploadFiles = () => {
  store.uploadFiles(id.value, filePendukung.value);
};

const eraseFile = async (index, item) => {
  if (item.upload) {
    await store.deleteFile(id.value, item.erase_id);
    filePendukung.value.splice(index, 1);
  } else {
    filePendukung.value.splice(index, 1);
  }
};

store.$subscribe((mutation, state) => {
  if (!state.loadingFiles) {
    filePendukung.value
      .filter((val) => !val.upload)
      .forEach((val) => {
        if (val.index in state.indexUploadFiles) {
          val.upload = true;
          val.erase_id = state.indexUploadFiles[val.index];
        }
      });
  }
});
</script>
