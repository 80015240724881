import { defineStore } from "pinia";
import axios from "@/helper/axios";
import {getCookie, setCookie} from "@/helper/cookies";
import { useToastStore } from "./toast";
import router from "@/router";
import { useNProgress } from "@vueuse/integrations/useNProgress";
import {getLocalStorage, storeLocalStorage} from "@/helper/localStorage";
import {changePasswordService, impersonatedUserService, stopImpersonateService} from "@/services/master";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      authLoading: false,
      token: null,
      loading: false,
      user: {
        name: null,
        role: {
          id: null,
          name: null,
        },
        categories: [],
      },
      isImpersonating: false,
      impersonatedUser: null,
      impersonateId: null
    };
  },
  getters: {
    getKategoriUser: (state) => {
      const kategoriUser = state.user.categories.filter(
        (kategori, index, self) => {
          return index === self.findIndex((t) => t.id === kategori.id);
        }
      );
      return kategoriUser;
    },
  },
  actions: {
    async login(username, password) {
      const toast = useToastStore();
      this.loading = true;
      try {
        const response = (
          await axios.post("login", {
            username: username,
            password: password,
          })
        ).data;
        this.loading = false;
        if (response.success) {
          toast.showToastShort(true, response.message);
          setCookie("token", response.data.access_token, 1);
          storeLocalStorage(
            "149c8afd-bf38-4b44-b392-bb6eb1dd67ed",
            response.data.categories
          );
          this.user.name = response.data.name;
          this.user.role.id = response.data.role.id;
          this.user.role.name = response.data.role.name;
          this.user.categories = response.data.categories;

          router.push({ name: "home" });
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async getUser() {
      this.loading = !this.loading;
      try {
        const response = (await axios.get("user")).data;
        this.user.name = response.username;
        this.user.role.id = response.role_id;
        this.user.role.name = response.role_name;
        this.user.categories = getLocalStorage(
          "149c8afd-bf38-4b44-b392-bb6eb1dd67ed"
        );
        this.loading = false;
        this.isImpersonating = getCookie('is_impersonating');
        this.impersonateId = getCookie('impersonate_id');
      } catch (error) {
        this.loading = false;
      }
    },
    async logout() {
      const { isLoading } = useNProgress();
      isLoading.value = true;
      try {
        const response = await (await axios.get("logout")).data;
        if (response.success) {
          setCookie("token", "", 1);
          router.push({ name: "login" });
        }
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    },
    async changePassword(data) {
      this.authLoading = !this.authLoading;
      const toast = useToastStore();
      try {
        const response = await changePasswordService(data);
        toast.showToastShort(response.status, response.message)
        if (response.status) {
          setCookie("token", "", 1);
          router.push({ name: "login" });
        }
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.authLoading = !this.authLoading;
      }
    },
    async startImpersonateUser(userId) {
      this.authLoading = true;
      const toast = useToastStore();
      try {
        const response = await impersonatedUserService(userId);
        if (response.success) {
          setCookie("token", response.data.access_token, 1);
          storeLocalStorage(
              "149c8afd-bf38-4b44-b392-bb6eb1dd67ed",
              response.data.categories
          );
          setCookie('is_impersonating', true, 1);
          setCookie('impersonate_id', response.data.impersonate_id, 1);
          this.user.name = response.data.name;
          this.user.role.id = response.data.role.id;
          this.user.role.name = response.data.role.name;
          this.user.categories = response.data.categories;
          this.isImpersonating = true;
          this.impersonateId = response.data.impersonate_id;

          router.push({ name: "home" });
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.authLoading = !this.authLoading;
      }
    },
    async stopImpersonating() {
      const toast = useToastStore();
      this.authLoading = true;
      try {
        const response = await stopImpersonateService({impersonate_id: this.impersonateId});
        this.loading = false;
        if (response.success) {
          setCookie("token", response.data.access_token, 1);
          storeLocalStorage(
              "149c8afd-bf38-4b44-b392-bb6eb1dd67ed",
              response.data.categories
          );
          this.user.name = response.data.name;
          this.user.role.id = response.data.role.id;
          this.user.role.name = response.data.role.name;
          this.user.categories = response.data.categories;

          this.isImpersonating = false;
          this.impersonateId = null;

          setCookie('is_impersonating', false, 1);
          setCookie('impersonate_id', null, 1);

          router.push({ name: "home" });
        } else {
          toast.showToastShort(false, response.message);
        }
      } catch (error) {
        toast.showToastShort(false, error.message);
      } finally {
        this.authLoading = !this.authLoading;
      }
    }
  },
});
