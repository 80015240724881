<template>
    <div v-if="imageList.length > 0">
      <VueperSlides
        fade
        :touchable="false"
        :bullets="false"
        :slideRatio="5 / 6"
        class="w-[31rem] rounded-3xl no-shadow"
      >
        <VueperSlide
          class="rounded-3xl"
          v-for="(slide, index) in imageList"
          :key="index"
          :image="slide"
        />
      </VueperSlides>
    </div>
    <div
      v-else
      class="h-[91%] flex items-center bg-blue-200 w-full justify-center mx-5 my-5 rounded-xl"
    >
      tidak ada gambar
    </div>
  </template>
  <script setup>
  import { useDashboardStore } from "@/store/dashboard";
  import {  ref } from "vue";
  import { VueperSlides, VueperSlide } from "vueperslides";
  import "vueperslides/dist/vueperslides.css";
  
  const imageList = ref([]);
  
  const store = useDashboardStore();
  

  store.$subscribe((mutation, state) => {
    if (state.project) {
      if (!state.loadingImage) {
        imageList.value = store.getImages;
      }
    }
  });
  </script>