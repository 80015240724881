import {useReportStore} from "@/store/report";
import {useKontraktorStore} from "@/store/kontraktor";
import {useKategoriStore} from "@/store/kategori";
import {useAuthStore} from "@/store/auth";
import {useProjectStore} from "@/store/project";
import {useDaily} from "@/store/daily";

export const requireReports = (to, from, next) => {
    const store = useReportStore();
    store.fetchReports();
    next();
};

export const requireReportDetails = (to, from, next) => {
    const store = useReportStore();
    const id = to.params.id;
    store.fetchReportDetails(id);
    next();
}

export const requireReportDetail = (to, from, next) => {
    const store = useReportStore();
    const type = to.path.split('/').pop();
    const id = to.params.id;
    store.fetchReportSubDetail(id, type);
    next();
}

export const requireVendors = (to, from, next) => {
    const store = useKontraktorStore();
    store.fetchKontraktors();
    next();
}

export const requireUsersCategory = (to, from, next) => {
    const store = useKategoriStore();
    const id = to.params.id;
    store.getUsersByCategory(id);
    next();
}

export const requiresAdmin = (to, from, next) => {
    const auth = useAuthStore();
    const role = auth.user.role.name;
    if (role !== 'Admin' && role !== 'Superadmin') {
        next({
            path: '/dashboard-detail/all'
        });
    } else {
        next();
    }
};

export const requireScheduleData = (to, from, next) => {
    const store = useProjectStore();
    const id = to.params.id;
    store.fetchProjectWithScope(id);
    next();
}

export const requireDailyData = (to, from, next) => {
    const id = to.params.id;
    const store = useDaily();
    const project = useProjectStore();
    project.fetchProjectById(id).then(() => {
        store.fetchMaterDailies(id);
    });
    next();
}