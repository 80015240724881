<template>
  <AppCard :shadow="false">
    <template #title>
      Data Master >
      <a class="cursor-pointer"> Data Proyek </a>
      > Scope
    </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto w7">Tambah Scope</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loading"
      :headerList="headerList"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :action="true"
      :bodyList="dataList"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEdit(data)"
          @erase-button="toggleEraseModal(data.id)"
        />
      </template>
    </AppTable>
    <TableModal
      title="Scope"
      :add="addModal"
      @add-submit="addScope"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateScope"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteScope"
      @erase-close="toggleEraseModal"
    >
      <div class="grid grid-cols-2 gap-4">
        <AppInput title="Uraian" v-model="formInput.uraian" />
        <AppInput v-show="false" type="hidden" v-model="formInput.id_proyek" />
        <AppSelect
          title="Satuan"
          v-model="formInput.id_satuan"
          :options="satuanOptions"
        />
        <AppCurrencyInput
          title="Harga Satuan"
          v-model="formInput.harga_satuan"
          :options="{
            currency: 'IDR',
            currencyDisplay: 'hidden',
          }"
        />
        <AppInput
          title="Perkiraan Kuantitas"
          type="tel"
          v-model="formInput.perkiraan_kuantitas"
        />
        <AppCurrencyInput
          title="Harga Pekerjaan"
          v-model="formInput.harga_pekerjaan"
          :options="{
            currency: 'IDR',
            currencyDisplay: 'hidden',
          }"
          :externalValue="true"
          :isReadOnly="true"
        />
        <AppInput
          title="Bobot Pekerjaan"
          v-model="formInput.bobot_pekerjaan"
          :isReadonly="true"
          back="%"
        />
      </div>
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import TableModal from "@/components/TableModal.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useScopeStore } from "@/store/scope";
import { useSatuanStore } from "@/store/satuan";
import AppInput from "@/components/AppInput.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppCurrencyInput from "@/components/AppCurrencyInput.vue";

const router = useRouter();
const route = useRoute();
const store = useScopeStore();
const satuan = useSatuanStore();

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const headerList = [
  {
    classTag: "w-2/5 text-left pl-14 px-1",
    title: "Uraian",
    id: "uraian",
  },
  {
    classTag: "text-center",
    title: "Satuan",
    id: "satuan_name",
  },
  {
    classTag: "text-center",
    title: "Perkiraan Kuantitas",
    id: "perkiraan_kuantitas",
  },
  {
    classTag: "text-center",
    title: "Harga Satuan(RP)",
    id: "harga_satuan",
    depan: "Rp.",
    currency: true,
  },
  {
    classTag: "text-center",
    title: "Harga Pekerjaan(RP)",
    id: "harga_pekerjaan",
    depan: "Rp.",
    currency: true,
  },
  {
    classTag: "text-center ",
    title: "Bobot Pekerjaan % ",
    id: "bobot_pekerjaan",
    belakang: "%",
  },
];

const nilai_kontrak = ref(route.params.nilai_kontrak);

formInput.value.id_proyek = route.params.id;

function calculate() {
  formInput.value.harga_pekerjaan = computed(() => {
    if (formInput.value.harga_satuan && formInput.value.perkiraan_kuantitas) {
      return formInput.value.harga_satuan * formInput.value.perkiraan_kuantitas;
    } else {
      return 0;
    }
  });

  formInput.value.bobot_pekerjaan = computed(() =>
    ((100 * formInput.value.harga_pekerjaan) / nilai_kontrak.value).toFixed(2)
  );
}

const dataList = ref([]);

onMounted(() => {
  store.idScope = route.params.id;
  store.fetchScopes();
  satuan.fetchSatuan();
  if (!nilai_kontrak.value) {
    router.push({ name: "pengajuan" });
  }
});

store.$subscribe((mutation, state) => {
  if (!state.loading) {
    dataList.value = store.getScopes;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const satuanOptions = ref();

satuan.$subscribe((mutation, state) => {
  if (!state.loading) {
    satuanOptions.value = satuan.getSatuanForm;
  }
});

calculate();

const toggleEdit = (data) => {
  toggleEditModal(data);
  calculate();
};
const addScope = () => store.addScope(formInput.value);
const updateScope = () => {
  formInput.value.id_proyek = parseInt(formInput.value.id_proyek);
  store.updateScope(formInput.value);
};
const deleteScope = (id) => store.deleteScope(id);
</script>
